import React, { useEffect, useState } from "react";
import HttpError404 from "@app/components/http-errors/http-error-404";
import Loading from "@app/components/loading/loading";

interface Props {
  delayedRender?: boolean;
}

function Page404(props: Props) {
  const { delayedRender = true } = props;

  const [show, setShow] = useState(!delayedRender);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (delayedRender) {
      timeout = setTimeout(setShow, 1000, true);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [delayedRender]);

  return (
    <>
      {!show && <Loading size="1x" appearance="screen" />}
      {show && <HttpError404 />}
    </>
  );
}

export { Page404 as default };
