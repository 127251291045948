/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect, useRef, useContext } from "react";
import {
  Switch, Route, useLocation, useHistory,
} from "react-router-dom";
import PrivateRoute from "@app/components/routes/private-route";
import ScrollPageTop from "@app/components/scroll-page-top";
import constants from "@app/constants";
import { useRelogin } from "@app/hooks/relogin";
import { AuthContext, IAuthContext } from "@app/contexts/index";
import BlockUI from "./components/loading/block-ui";
// import { AuthContext, IAuthContext } from "./contexts";
import { lazyWithRetry } from "./helpers";
import Loader from "./loader";
import AccountVerifyViaLink from "./pages/auth-modals/account-verify-via-link";
import BrowseServices from "./pages/browse-services/browse";
// import Page404 from "./pages/http-errors/page-404";
import "./assets/styles/main.scss";

function AppRoutes() {
  const [continueFlow] = useRelogin();

  // This piece of state is set when one of the
  // gallery links is clicked. The `background` state
  // is the location that we were at when one of
  // the gallery links was clicked. If it's there,
  // use it as the location for the <Switch> so
  // we show the gallery in the background, behind
  // the modal.
  const location = useLocation<any>();
  const background = location.state && location.state.background;

  const history = useHistory();

  const {
    state: { LoggedIn },
  } = useContext<IAuthContext>(AuthContext);

  useEffect(() => {
    if (constants.debug.console) { console.log("[APP] location:", location, "background:", background); }
  }, [background, location]);

  useEffect(() => {
    if ((location.pathname === "/login" || location.pathname === "/register") && LoggedIn) { history.push("/"); }
  }, [LoggedIn, history, location.pathname]);

  const blockRef = useRef<HTMLDivElement>(null);

  if (process.env.SSR !== "1") {
    if (!continueFlow) {
      if (constants.debug.console) { console.log("Relogin process..."); }
      return (
        <>
          <BlockUI forwardRef={blockRef} active />
          <div ref={blockRef}>...</div>
        </>
      );
    }
  }

  const Callback = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/callback")) });
  const MyServices = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/my-services/my-services")) });
  const ManageBookings = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/manage-bookings/manage-bookings")) });
  const BookingDetail = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/booking-detail/booking-detail")) });
  const MyBookings = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/my-bookings/my-bookings")) });
  const CreateService = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/service-create/index")) });
  // We are not lazy loading this component because it had an issue causing it to unmount and mount again every time we update the query string.
  // const BrowseServices = Loader.Lazy({ component: lazyWithRetry(() => import("./pages/browse-services/browse")) });
  const CategoryYoga = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/categories/category-yoga")) });
  const CategoryCoachingTherapy = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/categories/category-coaching-therapy")) });
  const ServiceDetail = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/service-detail")) });
  const PublicProfile = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/public-profile/public-profile")) });
  const Dashboard = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/dashboard/dashboard")) });
  const GetStarted = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/get-started/get-started")) });
  const ScheduleCalendar = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/schedule/index")), componentProps: { viewMode: "MONTH" } });
  const ScheduleList = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/schedule/index")), componentProps: { viewMode: "LIST" } });
  const Availability = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/availability/availability")) });
  const AccountIndex = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/account-settings/index")) });
  // const CheckoutClassSession = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/checkout/index-for-class")) });
  const CheckoutIndex = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/checkout")) });
  const StyleTest = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/style-test/index")) });
  const ForExperts = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/for-experts/for-experts")) });
  const KeepMeUpdated = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/campaigns/keep-me-updated")) });

  const Experiences = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/experiences/index")) });
  const ExperienceDetail = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/experience-detail/index")) });

  const HomeIndex = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/home/index")) });
  const Page404 = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/http-errors/page-404")) });

  // const AuthSignupOrLogin = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/signup-or-login")) });
  const AuthSignupMemberOrLogin = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/signup-or-login")), componentProps: { forMember: true } });
  const AuthLogin = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/login")) });
  const AuthLogout = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/logout")) });

  const ResetPassword = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/reset-password")) });
  const ResetPasswordCodeSent = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/reset-password-code-sent")) });

  const AuthRegister = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/register")), componentProps: { profileRole: "CUSTOMER" } });
  const AuthRegisterExpert = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/register")), componentProps: { profileRole: "EXPERT" } });
  const AccountVerificationCode = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/register-verification-code")) });

  const LegalTermsOfService = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/legal-pages/terms-of-service")) });
  const LegalPrivacy = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/legal-pages/privacy")) });
  const LegalImprint = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/legal-pages/imprint")) });
  const LegalMangoPayTerms = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/legal-pages/mangopay-terms")) });

  const CheckoutResult = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/checkout/checkout-result")) });

  const SelectRole = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/select-role")) });
  const ExpertWelcome = Loader.Lazy({ component: lazyWithRetry(() => import("@app/pages/auth-modals/expert-welcome")) });

  return (
    <ScrollPageTop>
      <Switch location={background || location}>
        {/* Routes with no layouts */}
        <Route exact strict path="/auth/callback"><Callback /></Route>

        {/* Routes */}
        <PrivateRoute strict role="EXPERT" exact path="/profile"><PublicProfile /></PrivateRoute>
        <Route strict path="/profile/:slug"><PublicProfile /></Route>

        <PrivateRoute strict path="/dashboard/account" component={AccountIndex} />

        <PrivateRoute strict exact path="/dashboard/bookings"><MyBookings /></PrivateRoute>
        <PrivateRoute strict path="/dashboard/booking/:bookingId"><BookingDetail /></PrivateRoute>

        <PrivateRoute strict role="EXPERT" exact path="/dashboard" component={Dashboard} />
        <PrivateRoute strict role="EXPERT" path="/dashboard/bookings/manage"><ManageBookings /></PrivateRoute>
        <PrivateRoute strict role="EXPERT" path="/dashboard/schedule/list"><ScheduleList /></PrivateRoute>
        <PrivateRoute strict role="EXPERT" path="/dashboard/schedule"><ScheduleCalendar /></PrivateRoute>
        <PrivateRoute strict role="EXPERT" path="/dashboard/services/create" component={CreateService} />
        <PrivateRoute strict role="EXPERT" path="/dashboard/services/edit/:listingId" component={CreateService} />
        <PrivateRoute strict role="EXPERT" path="/dashboard/services"><MyServices /></PrivateRoute>
        <PrivateRoute strict role="EXPERT" path="/dashboard/availability"><Availability /></PrivateRoute>
        <PrivateRoute strict role="EXPERT" path="/dashboard/get-started"><GetStarted /></PrivateRoute>

        <Route exact strict path="/browse"><BrowseServices /></Route>
        <Route exact strict path="/browse/:slug"><BrowseServices /></Route>
        <Route exact strict path="/browse/:parentSlug/:slug"><BrowseServices /></Route>
        <Route exact strict path="/categories/yoga"><CategoryYoga /></Route>
        <Route exact strict path="/categories/coaching-therapy"><CategoryCoachingTherapy /></Route>
        <Route strict exact path="/checkout-result"><CheckoutResult /></Route>
        <Route exact strict path="/services/:slug/checkout/:item/:dt"><CheckoutIndex /></Route>
        <Route exact strict path="/services/:slug/checkout/:item"><CheckoutIndex /></Route>
        <Route exact strict path="/services/:slug/checkout"><CheckoutIndex /></Route>
        <Route exact strict path="/services/:slug"><ServiceDetail /></Route>
        <Route exact strict path="/for-experts"><ForExperts /></Route>

        <Route exact strict path="/experiences"><Experiences /></Route>
        <Route exact strict path="/experiences/:slug"><ExperienceDetail /></Route>

        <Route exact strict path="/keepmeupdated"><KeepMeUpdated /></Route>

        {/* Other routes */}
        <Route strict exact path="/terms-of-service"><LegalTermsOfService /></Route>
        <Route strict exact path="/privacy"><LegalPrivacy /></Route>
        <Route strict exact path="/imprint"><LegalImprint /></Route>
        <Route strict exact path="/mangopay-terms"><LegalMangoPayTerms /></Route>

        <Route strict exact path="/404"><Page404 /></Route>
        <Route strict exact path="/t"><StyleTest /></Route>
        <Route strict exact path="/auth/confirm"><AccountVerifyViaLink /></Route>

        {/*
            altta tanımlı olan "/signup" gibi modal route'lara doğrudan
            giriş yapıldığında ana sayfa render edilebilsin diye
            "exact" ve "strict" bu route'dan kaldırıldı.
          */}
        <Route path="/"><HomeIndex /></Route>
      </Switch>

      {/* Render modals */}
      <Switch>
        {/* Auth routes (modals) */}
        {/* <Route strict exact path="/signup"><AuthSignupOrLogin /></Route> */}
        <Route strict exact path="/member"><AuthSignupMemberOrLogin /></Route>
        {/* <Route strict exact path="/auth-flow"><AuthSelectMemberRole /></Route> */}

        <Route strict exact path="/login"><AuthLogin /></Route>
        <Route strict exact path="/logout"><AuthLogout /></Route>

        <Route strict exact path="/reset-password"><ResetPassword /></Route>
        <Route strict exact path="/reset-password/code-sent"><ResetPasswordCodeSent /></Route>

        <Route strict exact path="/register"><SelectRole /></Route>
        <Route strict exact path="/member-register"><AuthRegister /></Route>
        <Route strict exact path="/expert-register"><AuthRegisterExpert /></Route>

        <Route strict exact path="/register/confirm"><AccountVerificationCode /></Route>

        <Route strict exact path="/expert-welcome"><ExpertWelcome /></Route>

        {/* Returns 404 if no routes match */}
        {/* <Route><Page404 /></Route> */}
      </Switch>

    </ScrollPageTop>
  );
}

export { AppRoutes as default };
