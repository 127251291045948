import React from "react";
import clsx from "clsx";

interface Props {
  thumbnailUrl: string;
  onDelete: () => void;
  className?: string;
  isNew?: boolean;
  progress?: number;
}

function ListingPhotoThumbnailBox(props: Props) {
  const {
    thumbnailUrl, onDelete, className, isNew = false, progress,
  } = props;

  function onClickDelete(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    onDelete();
  }

  return (
    <>
      <div className={className}>
        <div className={clsx("media-container-item aspect-ratio", { uploading: isNew })}>
          {!isNew && (
            <a href="" onClick={onClickDelete} className="media-delete">
              <i className="icon-trash" />
            </a>
          )}
          <img src={thumbnailUrl} alt="Preview" className="cover" />

          {isNew && progress && (
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                {progress?.toFixed(0)}
                %
              </div>
            </div>
          )}

        </div>
      </div>
    </>
  );
}

export { ListingPhotoThumbnailBox as default };
