import type { IProfileInfo, IState } from "./types";

// ---
// State defaults
// ----------------------------------------------------------------------------
const profileInfoDefaults: IProfileInfo = {
  loaded: false,
  addresses: [],
};

const contextDefaults: IState = {
  profileInfo: profileInfoDefaults,
};

export { contextDefaults, profileInfoDefaults };
