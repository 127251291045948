/* eslint-disable jsx-a11y/aria-role */
import React from "react";
import {
  ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { IntercomProvider } from "react-use-intercom";
import { setContext } from "@apollo/client/link/context";

import { ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { de } from "yup-locales";
import { setLocale } from "yup";

import { AuthContextProvider, ProfileContextProvider } from "@app/contexts/index";
import h from "@app/helpers/index";
import constants from "@app/constants";
import AppRoutes from "./routes";

import "./assets/styles/main.scss";

const { PUBLIC_API_URI } = process.env;

setLocale(de);

/*
let csrftoken: string;

async function getCsrfToken() {
  if (!csrftoken) {
    // csrftoken = Cookies.get("csrftoken") as string;
    const response = await fetch(`${PUBLIC_API_URI}/csrf`);
    const data = await response.json();

    csrftoken = data.csrfToken;
  }

  return csrftoken;
}
*/

async function customFetch(uri: RequestInfo, options: RequestInit) {
  // const tokenValue = await getCsrfToken();

  // return fetch(uri, {
  //   ...options,
  //   headers: {
  //     ...(options.headers || {}),
  //     "X-CSRFToken": tokenValue,
  //   },
  // });
  return fetch(uri, options);
}

const httpLink = createHttpLink({
  uri: `${PUBLIC_API_URI}/graphql`,
  fetch: customFetch,
  credentials: "same-origin",
});

const errorLink = onError((err) => {
  const { graphQLErrors, networkError } = err;

  // console.log({ err });

  graphQLErrors?.forEach((e: any) => {
    const filtered = e.params?.trace
      ? e.params.trace.filter((x: string) => x.indexOf("site-packages") === -1)
      : [];

    if (filtered.length > 0) {
      console.log(
        "[GraphQL error]: ",
        err.operation.operationName,
        { variables: err.operation.variables },
        e.params?.message ?? e.message,
        "\n\t",
        filtered.length > 1 ? filtered : filtered[0],
      );
    } else {
      console.log(
        "[GraphQL error]: ",
        err.operation.operationName,
        { variables: err.operation.variables },
        e.params?.message ?? e.message,
        e,
      );
    }
  });

  if (networkError) {
    console.log("[Network error]: ", networkError);

    // @ts-ignore
    if (networkError.statusCode === 401) {
      // @ts-ignore
      if (networkError.result?.codekey === "SECURITY_HEADER_JWT_TOKEN_INVALID") {
        if (constants.debug.console) {
          console.log(">>>>>>>>>>>>>>>>> clear browser storage <<<<<<<<<<<<<<<<<<<<<<<");
          console.dir(window.location);
        }

        h.storage.remove(h.storage.constants.AUTH_OBJ);
        h.storage.remove(h.storage.constants.LISTING_CREATE_CTX);

        const { origin, pathname } = window.location;
        window.location.href = `${origin}/login?path=${pathname}`;
      }
    }
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = h.jwt.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <>
      <AuthContextProvider>
        <ProfileContextProvider>
          <ApolloProvider client={client}>
            <IntercomProvider appId={constants.intercom.appId}>
              <AppRoutes />
            </IntercomProvider>
          </ApolloProvider>
        </ProfileContextProvider>
      </AuthContextProvider>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        transition={Bounce}
        theme="dark"
      />
    </>
  );
}

export { App as default };
