import { use } from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { setDefaultLocale } from "react-datepicker";
import h from "@app/helpers";

// english
import homepageEn from "./en/homepage.json";
import categoriesEn from "./en/categories.json";

// deutsch
import homepageDe from "./de/homepage.json";
import categoriesDe from "./de/categories.json";

let lang = h.storage.get(h.storage.constants.LANGUAGE, true) || "de";

if (!["en", "de"].includes(lang)) {
  lang = "de";
}

// console.info(lang);

use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: lang,
    fallbackLng: "en",
    ns: [
      "homepage",
      "categories",
    ],
    resources: {
      en: {
        homepage: homepageEn,
        categories: categoriesEn,
      },
      de: {
        homepage: homepageDe,
        categories: categoriesDe,
      },
    },

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    // react-i18next options
    react: {
      useSuspense: true,
      // wait: true,
    },
  });

// --- react-datepicker
setDefaultLocale(lang);

// --- date-fns
// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
window.__localeId__ = lang;
