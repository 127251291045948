import React, { useRef } from "react";
import clsx from "clsx";
import type { ThemeColor } from "../types";

interface AlertProps {
  children: React.ReactNode;
  color?: ThemeColor;
  dismiss?: boolean;
  header?: string | React.ReactNode;
}

function Alert(props: AlertProps) {
  const {
    children, color = "primary", dismiss, header,
  } = props;

  const ref = useRef<any>();
  function onDismiss() {
    ref.current?.classList.remove("show");
    setTimeout(() => {
      ref.current?.classList.add("d-none");
    }, 250);
  }

  return (
    <div ref={ref} className={clsx("alert", `alert-${color}`, { "alert-dismissible fade show": dismiss })} role="alert">
      {header && <h4 className="alert-heading">{header}</h4>}
      {children}
      {dismiss && (
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={onDismiss}
        />
      )}
    </div>
  );
}

export { Alert as default };
