// function toNodes<T>(x: { edges: [{ node: T }] }): T[] {
//   return x.edges.map((edge) => edge.node);
// }

// function toNodes<T>(x: any): T[] {
//   return x && x.edges ? x.edges.map((edge: any) => edge.node) : [];
// }

function toTyped<T>(x: any): T[] {
  if (!x) {
    return [];
  }

  return x.edges
    ? x.edges.map((edge: any) => edge.node)
    : x.map((item: any) => item);
}

const methods = {
  // toNodes,
  toTyped,
};

export { methods as default };
