import { getLanguage } from "@app/i18n/config-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useGetCategoryName<T extends { name: string, nameDe: string }>() {
  const { t } = useTranslation();
  const appLanguage = getLanguage();

  const getCategoryName = useCallback(
    (category: T): string => (appLanguage === "de"
      ? category.nameDe || t(`category_${category.name}`)
      : category.name),
    [appLanguage, t],
  );

  return {
    getCategoryName,
  };
}
