import React, { useEffect, useState } from "react";
import { connectHierarchicalMenu } from "react-instantsearch-dom";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";
import h from "@app/helpers";
import clsx from "clsx";
import { G_ListingCategoryNode, useListingCategoriesAllQuery } from "@app/gql/graphql-types";
import { getApolloError } from "@app/components/validation-summary/validation-summary";
import { useGetCategoryName } from "@app/hooks/tools";
import FilterDropdown from "../filter-dropdown";

interface Props {
  defaultRefinement: string | undefined;
  currentRefinement: string | undefined;
  refine: (value: string | undefined) => void;
  searchable?: boolean;
  // isFromSearch: boolean;
  //  searchForItems: function,
  //  createURL: function,
}

type PickedCategories = Pick<G_ListingCategoryNode, "id" | "slug" | "name" | "nameDe" | "parent">;

function RefinementList(props: Props) {
  const {
    defaultRefinement, currentRefinement, refine, searchable,
  } = props;

  const { t } = useTranslation(["homepage"]);
  const [state, setState] = useState<string | undefined>(currentRefinement);
  const [title, setTitle] = useState<string>("");

  const [allData, setAllData] = useState<PickedCategories[]>();
  const [filteredData, setFilteredData] = useState<PickedCategories[]>();
  const { loading, data, error } = useListingCategoriesAllQuery();
  const { getCategoryName } = useGetCategoryName();

  /*
  useEffect(() => {
    console.log("cat items", items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  */

  function getTitle(val: string | undefined) {
    const v = val && val.split(">").pop()?.trim();
    // console.log({ val, v });
    const d = allData?.find((x) => x.slug === v);
    return d
      ? getCategoryName(d)
      : t("browse__filters__categories");
  }

  useEffect(() => {
    // console.log({ defaultRefinement });
    refine(defaultRefinement);
    setTitle(getTitle(defaultRefinement));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRefinement, allData]);

  useEffect(() => {
    // console.log({ state });
    refine(state);
    setTitle(getTitle(state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setTitle(getTitle(state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    // console.log("currentRefinement", currentRefinement);
    const c = JSON.stringify(currentRefinement);
    const s = JSON.stringify(state);
    if (c !== s) {
      setState(currentRefinement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefinement]);

  useEffect(() => {
    if (data) {
      const arr = h.gql.toTyped<PickedCategories>(data.results);
      setAllData(arr);
      setFilteredData(arr.filter((x) => !x.parent));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function handleRefine(e: React.ChangeEvent<HTMLInputElement>) {
    // console.log({ e, state });
    if (e.target.checked) {
      setState(e.target.value);
    } else {
      setState(undefined);
    }
  }

  return (
    <FilterDropdown
      id="filterCategories"
      className={clsx("filter-categories", { refined: (state ?? "") !== "" })}
      title={title}
    >
      {loading && (<>loading...</>)}

      {error && (<>{getApolloError(error)}</>)}

      {filteredData && (
        <>
          {searchable && (
            <li className="filter-text">
              <input
                className="form-control"
                onChange={(e) => {
                  // console.log(e.target.value);
                  const v = e.target.value.toLowerCase();
                  if (v && v.length > 0) {
                    const arr = allData!.filter(
                      (x) => x.name.toLowerCase().includes(e.target.value.toLowerCase()),
                    );
                    setFilteredData(arr);
                  } else {
                    setFilteredData(allData!.filter((x) => !x.parent));
                  }
                }}
                type="text"
              />
            </li>
          )}

          {filteredData.map((x, i) => {
            const {
              id, slug, parent,
            } = x;
            const cRef = parent ? `${parent.slug} > ${slug}` : slug;
            const isChecked = state === cRef;
            // const current = selectedLanguages.find((l) => l.trackId === langId);
            const categoryName = getCategoryName(x);
            // const count = items.find((item: any) => item.label === slug)?.count;

            return (
              <li key={id} className="dropdown-item">
                <div className="form-check">
                  <input
                    className="form-check-input form-check-input-lg"
                    checked={isChecked}
                    id={id}
                    name={`languages[${i}]`}
                    placeholder={categoryName}
                    onChange={handleRefine}
                    type="checkbox"
                    value={cRef}
                  />
                  <label className="form-check-label" htmlFor={id}>
                    {categoryName}
                    {/* {count && (<strong>{` (${count})`}</strong>)} */}
                  </label>
                </div>
              </li>
            );
          })}
        </>
      )}
    </FilterDropdown>
  );
}

const CustomRefinementList = connectHierarchicalMenu(RefinementList);

interface CategoryRefinementProps {
  slug: string | undefined;
  parentCategory?: string;
}

function CategoryRefinement(props: CategoryRefinementProps) {
  const { slug, parentCategory } = props;

  const dr = parentCategory ? `${parentCategory} > ${slug}` : slug;

  return (
    <CustomRefinementList
      defaultRefinement={dr}
      attributes={[
        "hierarchicalCategories.lvl0",
        "hierarchicalCategories.lvl1",
      ]}
      searchable
    />
  );
}

export { CategoryRefinement };
