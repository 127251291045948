function scrollToElement(elementId: string) {
  const element: any = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ block: "start", behavior: "smooth" });
  }
}

const methods = {
  scrollToElement,
};

export { methods as default, scrollToElement };
