import { useContext, useEffect, useState } from "react";
import { IAuthContext, AuthContext } from "@app/contexts/index";
import jwt from "@app/helpers/jwt";
import { useLoginInfoLazyQuery } from "@app/gql/graphql-types";
import { toast } from "react-toastify";
import { getApolloError } from "@app/components/validation-summary/validation-summary";
import type { IAuth } from "@app/contexts/auth/types";
import constants from "@app/constants";
import { useHistory } from "react-router-dom";

function useRelogin() {
  const [continueFlow, setContinueFlow] = useState(false);

  const history = useHistory<any>();

  const {
    state: { LoggedIn },
    actions: { login, logout },
  } = useContext<IAuthContext>(AuthContext);

  const [
    getLoginInfo, { loading, data, error },
  ] = useLoginInfoLazyQuery({ fetchPolicy: "no-cache" });

  useEffect(() => {
    if (!data?.profilesMine) {
      return;
    }

    if (data.profilesMine.length === 0) {
      console.log("logout required");
      logout();
      history.replace("/");
      return;
    }

    if (data.profilesMine) {
      const p = data.profilesMine.length > 0 ? data.profilesMine[0] : undefined;
      if (!p) {
        return;
      }

      const token = jwt.getToken();
      const jwtObj = jwt.parse(token!);

      const authObj: IAuth = {
        accessToken: token!,
        // refreshToken: string;
        expiresIn: jwtObj.exp,
        tokenType: "Bearer",
        profileId: p.id,
        slug: p.slug,
        role: p.role,
        view: p.role,
        email: p.email,
        firstName: p.firstName,
        lastName: p.lastName,
        timezone: p.timezone,
        profilePictureUri: p.profilePictureUri ?? undefined,
        defaultCalendar: p.calendars.edges.length > 0
          ? { id: p.calendars.edges[0]!.node!.id, title: p.calendars.edges[0]!.node!.title }
          : undefined,
        serviceFeeRatio: p.serviceFeeRatio,
      };

      login(authObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(getApolloError(error));
      setContinueFlow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (continueFlow) {
      return;
    }

    if (LoggedIn) {
      setContinueFlow(true);
      return;
    }

    const token = jwt.getToken();
    if (!token) {
      setContinueFlow(true);
      return;
    }

    if (constants.debug.console) { console.log("-- relogin --"); }
    getLoginInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LoggedIn, continueFlow]);

  return [LoggedIn || continueFlow];
}

export { useRelogin };
