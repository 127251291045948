import React, { useEffect } from "react";
import { useLocation } from "react-router";

interface ScrollPageTopProps {
  children?: React.ReactNode;
  scrollElement?: string;
}

function ScrollPageTop(props: ScrollPageTopProps) {
  const { children, scrollElement = "root" } = props;
  const location = useLocation();

  useEffect(() => {
    if (scrollElement) {
      const element = document.getElementById(scrollElement);
      if (element) {
        element.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  }, [location.pathname, scrollElement]);

  return (children as any) ?? null;
}

export { ScrollPageTop as default };
