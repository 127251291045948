import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import constants from "@app/constants";

import "./modal.scss";

interface ModalProps {
  title?: string;
  className?: string;
  children: React.ReactNode;
  footerChildren?: React.ReactNode;
  showBack?: boolean;
  showClose?: boolean;
  fullScreen?: boolean;
  scrollable?: boolean;
  size?: "sm" | "lg" | "xl";
  doClose?: boolean;
  onClose?: () => void;
  onBack?: () => void;
}

function Modal(props: ModalProps) {
  const {
    title, className, children, footerChildren, showBack, showClose = true, fullScreen,
    onClose, size, scrollable = false, doClose, onBack,
  } = props;

  const modalSize = size ? `modal-${size}` : "";

  const scrollabelCssClass = scrollable? "modal-dialog-scrollable" : "";

  const history = useHistory<any>();

  useEffect(() => {
    if (constants.debug.console) { console.log("modal -> history.location.state:", history.location.state); }
  }, [history]);

  const closeModal = () => {
    if (onClose) {
      onClose();
    } else {
      history.push(history.location.state?.background?.pathname || "/");
    }
  };

  const closeModalHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    closeModal();
  };

  const goBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  useEffect(() => {
    if (doClose) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doClose]);


  return (
    <div className={clsx("modal", className)} tabIndex={-1} style={{ display: "block" }}>
      <div className={clsx("modal-dialog modal-dialog-centered", `${scrollabelCssClass}`, `${modalSize}`, { "modal-fullscreen-sm-down": !fullScreen }, { "modal-fullscreen": fullScreen })}>
        <div className="modal-content">
          {title?.length &&
              <div className="modal-header">
                {showBack &&
                    <a className="btn-back" aria-label="Back" onClick={goBack}><i className="icon-arrow-left"/></a>}
                {title && <h4 className="modal-title">{title}</h4>}
                {showClose &&
                    <a className="btn-closed" aria-label="Close" onClick={closeModalHandler}><i className="icon-cross"/></a>}
              </div>
          }
          <div className="modal-body">
            {children}
          </div>
          {footerChildren
            && (
              <div className="modal-footer">
                {footerChildren}
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export { Modal as default };
