/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext, IAuthContext } from "@app/contexts/index";
import Page404 from "@app/pages/http-errors/page-404";

const PrivateRoute = ({ component: Component, role, ...rest }: any) => {
  const {
    state: { LoggedIn, Auth: { role: userRole } },
  } = useContext<IAuthContext>(AuthContext);

  // if (constants.debug.console) console.log("LoggedIn PrivateRoute", LoggedIn, "requiredPermissions", requiredPermissions, "...rest", { ...rest });
  // if (constants.debug.console) console.log("from: ", `${rest.location.pathname}${rest.location.search}`);

  if (!LoggedIn) {
    // if (constants.debug.console) console.log("redirect PrivateRoute", LoggedIn);
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: `${rest.location.pathname}${rest.location.search}` },
        }}
      />
    );
  }

  if (role && (userRole !== role)) {
    return <Route component={Page404} />;
    // return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Route
      {...rest}
      render={(props: any) => (Component ? <Component {...props} /> : rest.render(props))}
    />
  );
};

export { PrivateRoute as default };
