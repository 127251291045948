import React from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";
import {
  Configure,
  InstantSearch,
  Pagination,
} from "react-instantsearch-dom";
import { customSearchClient } from "@app/helpers/algolia";
import qs from "qs";
import constants from "@app/constants";
import { lazyWithRetry } from "@app/helpers";
import Filters from "./partials/refinements/filters";
import ContentMapView from "./partials/map/content-map-view";
import ContentListView from "./partials/list/content-list-view";
import "instantsearch.css/themes/algolia.css";
import "./assets/styles/browse.scss";

const AppLayout = lazyWithRetry(() => import("@app/layouts/app-layout"));

interface SlugProps {
  slug: string | undefined;
  parentSlug?: string;
}

interface SearchState {
  refinementList?: {
    "languages.edges.node.isoCode"?: string[];
    type?: string[];
  };
  page?: number;
  configure?: {
    aroundLatLngViaIP?: boolean;
    hitsPerPage?: number;
  };
  hierarchicalMenu?: {
    "hierarchicalCategories.lvl0"?: string;
    // "hierarchicalCategories.lvl1"?: string;
  };
  toggle?: {
    "items.edges.node.isAvailableOnline"?: boolean;
  };
}

const searchStateToQueryString = (state: SearchState) => `?${qs.stringify(state)}`;

// slice is used to remove the `?` from the query string.
const queryStringToSearchState = (location: any) => qs.parse(location.search.slice(1));

function Browse() {
  const { slug, parentSlug } = useParams<SlugProps>();
  const { t } = useTranslation(["homepage"]);

  const location = useLocation();
  const history = useHistory();
  const [searchState, setSearchState] = React.useState<SearchState>(
    queryStringToSearchState(location),
  );
  const debouncedSetStateRef = React.useRef<ReturnType<
    typeof setTimeout
  > | null>(null);

  React.useEffect(() => {
    if (parentSlug) {
      history.replace({
        search: searchStateToQueryString({
          ...searchState,
          hierarchicalMenu: {
            "hierarchicalCategories.lvl0": parentSlug,
            // "hierarchicalCategories.lvl1": `${parentSlug} > ${slug}`,
          },
        }),
      });
    } else if (slug) {
      switch (slug) {
        case "appointments":
          history.replace({
            search: searchStateToQueryString({
              ...searchState,
              refinementList: {
                "languages.edges.node.isoCode":
                  searchState.refinementList?.["languages.edges.node.isoCode"],
                "type": ["APPOINTMENT"],
              },
            }),
          });
          break;

        case "classes":
          history.replace({
            search: searchStateToQueryString({
              ...searchState,
              refinementList: {
                "languages.edges.node.isoCode":
                  searchState.refinementList?.["languages.edges.node.isoCode"],
                "type": ["CLASS"],
              },
            }),
          });
          break;

        case "virtual-sessions":
          history.replace({
            search: searchStateToQueryString({
              ...searchState,
              toggle: {
                "items.edges.node.isAvailableOnline": true,
              },
            }),
          });
          break;

        default:
          history.replace({
            search: searchStateToQueryString({
              ...searchState,
              hierarchicalMenu: {
                "hierarchicalCategories.lvl0": slug,
              },
            }),
          });
          break;
      }
    }
  }, [slug, parentSlug]);

  React.useEffect(() => {
    const nextSearchState = queryStringToSearchState(location);

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function onSearchStateChange(nextSearchState: any) {
    if (debouncedSetStateRef.current !== null) {
      clearTimeout(debouncedSetStateRef.current);
    }

    debouncedSetStateRef.current = setTimeout(() => {
      const currentPage = searchState.page;
      const nextPage = nextSearchState.page;

      const pageChanged = currentPage !== undefined && currentPage !== nextPage;

      const func = pageChanged ? history.push : history.replace;

      func({
        search: searchStateToQueryString(nextSearchState),
      });
    }, 700);

    setSearchState(nextSearchState);
  }

  // function removeMarkerDetails() {
  //   if (activeMarkerId) {
  //     setActiveMarkerId(undefined);
  //     toggleHover(activeMarkerId, false);
  //   }
  // }

  return (
    <AppLayout searchBox browseMenu={false} bodyClassName="body-browse">
      <InstantSearch
        indexName={constants.algolia.indexListings}
        searchClient={customSearchClient}
        onSearchStateChange={onSearchStateChange}
        searchState={searchState}
        createURL={searchStateToQueryString}
      >
        <Configure aroundLatLngViaIP hitsPerPage={20} />
        <main className="browse">
          <section className="section-page-title">
            <div className="container-xxxl cu-container-px">
              <h1 className="page-title">{t("browse__browse_sessions")}</h1>
              {/* deploy oncesi disable edildi
                  <Stats />
                  */}

              {/* <SortBy
                    defaultRefinement={constants.algolia.indexName}
                    items={[
                      { value: constants.algolia.indexName, label: "Most relevant" },
                      { value: `${constants.algolia.indexName}_price_desc`, label: "Lowest price" },
                      { value: `${constants.algolia.indexName}_price_asc`, label: "Highest price" },
                    ]}
                  /> */}
            </div>
          </section>
          <section className="section-filters">
            <div className="container-xxxl cu-container-px">
              {slug && parentSlug
                ? (
                  <Filters filterBar carousel={false} slug={slug} parentSlug={parentSlug} />
                )

                : <Filters filterBar carousel={false} />}
            </div>
          </section>
          <section className="section-results">
            <div className="container-xxxl cu-container-px">
              <div className="results-wrapper row">
                {/* left side */}
                <div className="results-column col-12 col-lg-7">
                  <ContentListView />
                  <div className="pagination-wrapper">
                    {/* https://www.algolia.com/doc/api-reference/widgets/pagination/react/#widget-param-padding */}
                    <Pagination
                      padding={2}
                      showFirst={false}
                      showLast={false}
                    />
                  </div>
                </div>

                {/* right side */}
                <div className="map-column col-lg-5 d-none d-lg-block">
                  <ContentMapView />
                </div>
              </div>
            </div>
          </section>
        </main>
      </InstantSearch>
    </AppLayout>
  );
}

export { Browse as default };
