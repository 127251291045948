import storage from "@app/helpers/local-storage";
import type { IAuth } from "./types";

function decryptAuthObject(encryptedAuthObject: string): IAuth {
  return JSON.parse(encryptedAuthObject);
}

function fillStorageWith(authObj: IAuth) {
  // const obj = { accessToken: authObj.accessToken };
  // const encryptedAuthObject = JSON.stringify(obj);
  const encryptedAuthObject = JSON.stringify(authObj);
  storage.set(storage.constants.AUTH_OBJ, encryptedAuthObject);
}

function getAddress(): string {
  return `${window.location.protocol}//${window.location.host}`;
}

function createQueryString(parameters: Record<string, string | null | undefined>): string {
  return Object.entries(parameters)
    .reduce(
      (acc: string, cur: [string, string | null | undefined]) => {
        if (cur[1] === null || cur[1] === undefined) {
          return acc;
        }

        return `${acc}${(acc.length > 0) ? "&" : ""}${cur[0]}=${encodeURIComponent(cur[1])}`;
      },
      "",
    );
}

function getAuthenticationUrl(pathname: string, provider?: string): string {
  const domain = process.env.AWS_COGNITO_USER_POOL_DOMAIN;
  const clientId = process.env.AWS_COGNITO_CLIENT_ID;
  const type = "token";
  const scope = "openid profile";
  const state = {
    url: pathname,
  };

  const callback = `${getAddress()}/auth/callback`;

  const queryString = createQueryString({
    identity_provider: provider,
    response_type: type,
    client_id: clientId,
    redirect_uri: callback,
    state: JSON.stringify(state),
    scope: scope,
  });

  if (provider !== null || provider !== undefined) {
    // Go straight to the provider, skipping the hosted UI
    return `https://${domain}/authorize?${queryString}`;
  }

  // Use the hosted UI
  return `https://${domain}/login?${queryString}`;
}

const helpers = {
  decryptAuthObject,
  fillStorageWith,
  getAuthenticationUrl,
};

export { helpers as default };
