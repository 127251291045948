import constants from "@app/constants";
import { v4 as uuidv4 } from "uuid";
// import jwt from "@app/helpers/jwt";

/*
function buildRequestHeader(): HeadersInit {
  let headersObject: any = {};
  const token = jwt.current(true);
  if (token !== null) {
    headersObject = {
      Authorization: `Bearer ${token}`,
    };
  }

  return {
    "Content-Type": "application/json; charset=utf-8",
    "Cache-Control": "no-cache",
    // 'Accept-Language': 'en',
    ...headersObject,
  };
}
*/

function extract(prefix: string, str: string, suffix: string, trimBoth: string)
  : string | undefined {
  // ---
  const matched = str.match(new RegExp(`${prefix}(.*?)${suffix}`, "g"));
  if (!matched) {
    return undefined;
  }

  const result = matched.map((val) => val
    .replace(prefix, "")
    .replace(suffix, "")
    .trim()
    .replaceAll(trimBoth, ""));

  return result && result.length > 0 ? result[0] : undefined;
}

/* TODO: cloudinary preset ile degistir */

const { cloudName, resourceUrlPrefix } = constants.cloudinary;
// const resourceUrlPrefix = `https://res.cloudinary.com/${cloudName}/image/upload/`;

function getThumbnailUrl(url: string | undefined, w = 800, h = 600, crop: string | undefined = undefined, extension: string | undefined = undefined) {
  // console.log({ resourceUrlPrefix, cloudName, url, extension });
  if (!url) {
    return undefined;
  }
  if (!url.includes(cloudName)) {
    // console.log("external image:", url);
    return url;
  }
  /*
    Original url
    > https://res.cloudinary.com/mycurayacom/image/upload/v1611791199/listings/photo/sgttjd8pzvtyurrquciq.jpg

    Resized
    > https://res.cloudinary.com/demo/image/upload/w_70,h_70,c_limit/cashew_chicken.jpg
  */
  // const suffix = url.substring(resourceUrlPrefix.length, url.length);

  // const suffix = url.substring(resourceUrlPrefix.length, url.length).replace(".png", ".jpg");
  let suffix = url.substring(resourceUrlPrefix.length, url.length);
  // console.log(1, { url, suffix, extension });

  // get extension
  const ext = suffix.split(".").pop();

  if (ext && extension) {
    suffix = suffix.replace(`.${ext}`, `.${extension}`);
  }

  if (suffix === "") {
    // console.log(2, { url, suffix, extension });
    return undefined;
  }

  return `${resourceUrlPrefix}${w ? `w_${w}` : ""}${h ? `,h_${h}` : ""}${crop ? `,${crop}` : ",c_limit"}/${suffix}`;
}

// requestHeader: buildRequestHeader,
const methods = {
  getThumbnailUrl,
};

export { methods as default };
