const constants = {
  AUTH_OBJ: "_data_",
  // USER_ID: "USER_ID",
  // USER_FULLNAME: "USER_FULLNAME",
  // USER_EMAIL: "USER_EMAIL",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  // REFRESH_TOKEN: "REFRESH_TOKEN",
  LANGUAGE: "i18nextLng", // i18n library defaults
  // EMAIL_CONFIRMED: "EMAIL_CONFIRMED",
  LISTING_CREATE_CTX: "listing-create-ctx",
};

function encodeStr(s: string) {
  // TODO
  return s;
}

function decodeStr(s: string) {
  // TODO
  return s;
}

function decodeAll(arr: string[]) {
  // TODO
  return arr.map((x) => decodeStr(x));
}

const getItem = (key: string, doNotParseJson = false): any => {
  if (typeof window === "undefined") {
    return undefined;
  }

  const item: any = window.localStorage.getItem(encodeStr(key));

  if (item !== "undefined") {
    const s = decodeStr(item);
    return doNotParseJson ? s : JSON.parse(s);
  }

  return undefined;
};

const setItem = (key: string, value: any, isJson = true) => {
  if (typeof window === "undefined") {
    return;
  }

  window.localStorage.setItem(encodeStr(key), encodeStr(isJson ? JSON.stringify(value) : value));
};

const getKeys = (options: { key: string, method: "equals" | "startsWith" }): string[] => {
  if (typeof window === "undefined") {
    return [];
  }

  const encodedKeys = Object.keys(window.localStorage);

  const keys = decodeAll(encodedKeys);

  switch (options.method) {
    case "equals":
      return keys.filter((x) => x === options.key);
    case "startsWith":
      return keys.filter((x) => x.substr(0, options.key.length) === options.key);
    default:
      return keys;
  }
};

const removeItem = (key: string) => {
  if (typeof window === "undefined") {
    return;
  }

  window.localStorage.removeItem(encodeStr(key));
};

const clear = () => {
  if (typeof window === "undefined") {
    return;
  }

  window.localStorage.clear();
};

const localStorage = {
  constants: constants,
  get: getItem,
  set: setItem,
  remove: removeItem,
  keys: getKeys,
  clear: clear,
};

export { localStorage as default };
