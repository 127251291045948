import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
  PointScalar: any;
  Time: any;
}

export interface G_AccountLinkResponse {
  oneTimeLink?: Maybe<Scalars['String']>;
}

/** An enumeration. */
export type G_AccountTypes =
  | 'COMPANY'
  | 'INDIVIDUAL';

/** An enumeration. */
export type G_BookMessageTypes =
  | 'BOOKING'
  | 'CANCEL_BOOKING'
  | 'RESCHEDULE_BOOKING';

export interface G_BookResponse {
  booking?: Maybe<G_BookingNode>;
  status?: Maybe<Scalars['String']>;
  threedsEnabled?: Maybe<Scalars['Boolean']>;
  threedsUrl?: Maybe<Scalars['String']>;
  transaction?: Maybe<G_TransactionNode>;
  transactionId?: Maybe<Scalars['String']>;
}

export interface G_BookingCalculatePriceResponse {
  appliedCouponCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  details?: Maybe<Array<Maybe<G_CalculationDetail>>>;
  discountsTotal?: Maybe<Array<Maybe<Scalars['String']>>>;
  messages?: Maybe<Array<Maybe<G_CalculationMessage>>>;
  paymentRequired?: Maybe<Scalars['Boolean']>;
  priceFinal?: Maybe<Array<Maybe<Scalars['String']>>>;
  priceTotal?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface G_BookingMessageNode extends G_Node {
  body: Scalars['String'];
  booking: G_BookingNode;
  date: Scalars['DateTime'];
  fromProfile: G_ProfileNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  toProfile: G_ProfileNode;
  type: G_BookingMessageType;
}

export interface G_BookingMessageNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_BookingMessageNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `BookingMessageNode` and its cursor. */
export interface G_BookingMessageNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_BookingMessageNode>;
}

/** An enumeration. */
export type G_BookingMessageType =
  /** Booking */
  | 'BOOKING'
  /** Cancel Booking */
  | 'CANCEL_BOOKING'
  /** Reschedule Booking */
  | 'RESCHEDULE_BOOKING';

export interface G_BookingNode extends G_Node {
  attendeeProfile: G_ProfileNode;
  bookedAddressAddressLine1: Scalars['String'];
  bookedAddressAddressLine2: Scalars['String'];
  bookedAddressCity?: Maybe<G_CityNode>;
  bookedAddressCountry?: Maybe<G_CountryNode>;
  bookedAddressGeolocation?: Maybe<Scalars['PointScalar']>;
  bookedAddressOnlineMeetingIntroduction?: Maybe<Scalars['String']>;
  bookedAddressOnlineMeetingLink?: Maybe<Scalars['String']>;
  bookedAddressOnlineMeetingPin?: Maybe<Scalars['String']>;
  bookedAddressPostalCode: Scalars['String'];
  bookedAddressTitle: Scalars['String'];
  bookedDate: Scalars['DateTime'];
  bookingCreationDate: Scalars['DateTime'];
  calendarOccurrence?: Maybe<G_CalendarOccurrenceNode>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  discountDetails?: Maybe<Array<Maybe<G_DiscountDetails>>>;
  duration: Scalars['Int'];
  expertEarning?: Maybe<Scalars['Decimal']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  listingDescription: Scalars['String'];
  listingItemOption: G_ListingItemOptionNode;
  listingItemSession?: Maybe<G_ListingItemSessionNode>;
  listingTitle: Scalars['String'];
  lockedUntil?: Maybe<Scalars['DateTime']>;
  messages: G_BookingMessageNodeConnection;
  occurrences: G_CalendarOccurrenceNodeConnection;
  participationType: G_BookingParticipationType;
  participationTypeNew: G_BookingParticipationTypeNew;
  priceFinal: Scalars['Decimal'];
  priceFinalCurrency: G_BookingPriceFinalCurrency;
  priceFinalCurrencyNew: G_BookingPriceFinalCurrencyNew;
  priceTotal: Scalars['Decimal'];
  priceTotalCurrency: G_BookingPriceTotalCurrency;
  priceTotalCurrencyNew: G_BookingPriceTotalCurrencyNew;
  referenceCode?: Maybe<Scalars['String']>;
  status: G_BookingStatus;
  statusNew: G_BookingStatusNew;
  transactions: G_TransactionNodeConnection;
  type: G_BookingType;
  typeNew: G_BookingTypeNew;
  updatedAt?: Maybe<Scalars['DateTime']>;
}


export interface G_BookingNodeMessagesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_BookingNodeOccurrencesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}


export interface G_BookingNodeTransactionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  mangopayPayinId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
}

export interface G_BookingNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_BookingNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `BookingNode` and its cursor. */
export interface G_BookingNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_BookingNode>;
}

/** An enumeration. */
export type G_BookingParticipationType =
  /** In-Person */
  | 'IN_PERSON'
  /** Virtual */
  | 'VIRTUAL';

/** An enumeration. */
export type G_BookingParticipationTypeNew =
  /** Virtual */
  | 'A_1'
  /** In-Person */
  | 'A_2';

/** An enumeration. */
export type G_BookingPriceFinalCurrency =
  /** Dollar */
  | 'DOLLAR'
  /** Euro */
  | 'EURO';

/** An enumeration. */
export type G_BookingPriceFinalCurrencyNew =
  /** Euro */
  | 'A_1'
  /** Dollar */
  | 'A_2';

/** An enumeration. */
export type G_BookingPriceTotalCurrency =
  /** Dollar */
  | 'DOLLAR'
  /** Euro */
  | 'EURO';

/** An enumeration. */
export type G_BookingPriceTotalCurrencyNew =
  /** Euro */
  | 'A_1'
  /** Dollar */
  | 'A_2';

/** An enumeration. */
export type G_BookingStatus =
  /** Awaiting Payment */
  | 'AWAITING_PAYMENT'
  /** Canceled */
  | 'CANCELED'
  /** Completed */
  | 'COMPLETED'
  /** Failed */
  | 'FAILED'
  /** Locked */
  | 'LOCKED'
  /** Queued */
  | 'QUEUED';

/** An enumeration. */
export type G_BookingStatusNew =
  /** Locked */
  | 'A_101'
  /** Queued */
  | 'A_102'
  /** Awaiting Payment */
  | 'A_201'
  /** Completed */
  | 'A_501'
  /** Failed */
  | 'A_601'
  /** Canceled */
  | 'A_801';

/** An enumeration. */
export type G_BookingType =
  /** Appointment */
  | 'APPOINTMENT'
  /** Class */
  | 'CLASS';

/** An enumeration. */
export type G_BookingTypeNew =
  /** Appointment */
  | 'A_1'
  /** Class */
  | 'A_2';

export interface G_CalculationDetail {
  amount?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  effect?: Maybe<Array<Maybe<Scalars['String']>>>;
  effectMax?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

export interface G_CalculationMessage {
  message?: Maybe<Scalars['String']>;
}

export interface G_CalendarAvailableHoursNode extends G_Node {
  endTime: Scalars['Time'];
  /** The ID of the object. */
  id: Scalars['ID'];
  startTime: Scalars['Time'];
  weekday: Scalars['Int'];
}

export interface G_CalendarAvailableHoursNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_CalendarAvailableHoursNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `CalendarAvailableHoursNode` and its cursor. */
export interface G_CalendarAvailableHoursNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_CalendarAvailableHoursNode>;
}

export interface G_CalendarEventNode extends G_Node {
  description: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  occurrences: G_CalendarOccurrenceNodeConnection;
  title: Scalars['String'];
  type: G_CalendarEventType;
}


export interface G_CalendarEventNodeOccurrencesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}

export interface G_CalendarEventNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_CalendarEventNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `CalendarEventNode` and its cursor. */
export interface G_CalendarEventNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_CalendarEventNode>;
}

/** An enumeration. */
export type G_CalendarEventType =
  /** Appointment */
  | 'APPOINTMENT'
  /** Class */
  | 'CLASS'
  /** Custom */
  | 'CUSTOM';

export interface G_CalendarNode extends G_Node {
  availableHours: G_CalendarAvailableHoursNodeConnection;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  events: G_CalendarEventNodeConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
  occurrences: G_CalendarOccurrenceNodeConnection;
  profile: G_ProfileNode;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
}


export interface G_CalendarNodeAvailableHoursArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_CalendarNodeEventsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_CalendarNodeOccurrencesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}

export interface G_CalendarNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_CalendarNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `CalendarNode` and its cursor. */
export interface G_CalendarNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_CalendarNode>;
}

export interface G_CalendarOccurrenceNode extends G_Node {
  booking?: Maybe<G_BookingNode>;
  bookings: G_BookingNodeConnection;
  calendar: G_CalendarNode;
  endTs?: Maybe<Scalars['String']>;
  event: G_CalendarEventNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  listingItemOption?: Maybe<G_ListingItemOptionNode>;
  listingItemSessions: G_ListingItemSessionNodeConnection;
  rrulestr?: Maybe<Scalars['String']>;
  startTs?: Maybe<Scalars['String']>;
}


export interface G_CalendarOccurrenceNodeBookingsArgs {
  after?: InputMaybe<Scalars['String']>;
  attendeeProfile?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_CalendarOccurrenceNodeListingItemSessionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}

export interface G_CalendarOccurrenceNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_CalendarOccurrenceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `CalendarOccurrenceNode` and its cursor. */
export interface G_CalendarOccurrenceNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_CalendarOccurrenceNode>;
}

export interface G_CalendarSetAvailabilityInput {
  endTime?: InputMaybe<Scalars['Time']>;
  id?: InputMaybe<Scalars['ID']>;
  startTime?: InputMaybe<Scalars['Time']>;
  weekday?: InputMaybe<Scalars['Int']>;
}

export interface G_CategoryData {
  lvl0?: Maybe<Scalars['String']>;
  lvl1?: Maybe<Scalars['String']>;
  lvl2?: Maybe<Scalars['String']>;
}

export interface G_ChangePasswordResponse {
  username?: Maybe<Scalars['String']>;
}

export interface G_CheckExperienceResponse {
  userRegistered?: Maybe<Scalars['Boolean']>;
}

export interface G_CheckUserResponse {
  status?: Maybe<Scalars['String']>;
  userExists?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
}

export interface G_CheckoutSessionResponse {
  clientSecret?: Maybe<Scalars['String']>;
}

export interface G_CityNode extends G_Node {
  bookingSet: G_BookingNodeConnection;
  companyAddressCityUuid: G_ProfileNodeConnection;
  companyRepresentativeAddressCityUuid: G_ProfileNodeConnection;
  country: G_CountryNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  locationSet: G_LocationNodeConnection;
  name: Scalars['String'];
  profileSet: G_ProfileNodeConnection;
}


export interface G_CityNodeBookingSetArgs {
  after?: InputMaybe<Scalars['String']>;
  attendeeProfile?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_CityNodeCompanyAddressCityUuidArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
}


export interface G_CityNodeCompanyRepresentativeAddressCityUuidArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
}


export interface G_CityNodeLocationSetArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_CityNodeProfileSetArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
}

export interface G_CityNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_CityNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `CityNode` and its cursor. */
export interface G_CityNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_CityNode>;
}

export interface G_CompanyInput {
  companyAddressAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressCity: Scalars['ID'];
  companyAddressGeolocation?: InputMaybe<G_GeolocationInput>;
  companyAddressPostalCode?: InputMaybe<Scalars['String']>;
  companyAddressTitle: Scalars['String'];
  companyName: Scalars['String'];
  companyRegistrationNumber?: InputMaybe<Scalars['String']>;
}

export interface G_CountryNode extends G_Node {
  bookingSet: G_BookingNodeConnection;
  cities: G_CityNodeConnection;
  companyAddressCountryUuid: G_ProfileNodeConnection;
  companyRepresentativeAddressCountryUuid: G_ProfileNodeConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
  isoCode: Scalars['String'];
  locationSet: G_LocationNodeConnection;
  name: Scalars['String'];
  profileSet: G_ProfileNodeConnection;
}


export interface G_CountryNodeBookingSetArgs {
  after?: InputMaybe<Scalars['String']>;
  attendeeProfile?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_CountryNodeCitiesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  name_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_CountryNodeCompanyAddressCountryUuidArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
}


export interface G_CountryNodeCompanyRepresentativeAddressCountryUuidArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
}


export interface G_CountryNodeLocationSetArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_CountryNodeProfileSetArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
}

export interface G_CountryNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_CountryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `CountryNode` and its cursor. */
export interface G_CountryNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_CountryNode>;
}

/** An enumeration. */
export type G_CurrencyTypes =
  | 'DOLLAR'
  | 'EURO';

export interface G_DiscountDetails {
  couponCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

/** Debugging information for the current query. */
export interface G_DjangoDebug {
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<G_DjangoDebugSql>>>;
}

/** Represents a single database query made to a Django managed DB. */
export interface G_DjangoDebugSql {
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
}

export interface G_DocumentInput {
  documentType: G_KycDocumentTypes;
  documentUri: Scalars['String'];
}

export interface G_ExperienceBySlugNode extends G_Node {
  coverUri?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount?: Maybe<Scalars['Decimal']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  hostedBySingleExperts?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isPast?: Maybe<Scalars['Boolean']>;
  isVisible: Scalars['Boolean'];
  paid: Scalars['Boolean'];
  price: Scalars['Decimal'];
  published: Scalars['Boolean'];
  referenceCode: Scalars['String'];
  sessions: G_ExperienceSessionNodeConnection;
  slug: Scalars['String'];
  startsAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  webflowCmsId?: Maybe<Scalars['String']>;
}


export interface G_ExperienceBySlugNodeSessionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  experienceId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface G_ExperienceBySlugNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ExperienceBySlugNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ExperienceBySlugNode` and its cursor. */
export interface G_ExperienceBySlugNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ExperienceBySlugNode>;
}

export interface G_ExperienceNode extends G_Node {
  coverUri?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount?: Maybe<Scalars['Decimal']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  hostedBySingleExperts?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isPast?: Maybe<Scalars['Boolean']>;
  isVisible: Scalars['Boolean'];
  paid: Scalars['Boolean'];
  price: Scalars['Decimal'];
  published: Scalars['Boolean'];
  referenceCode: Scalars['String'];
  sessions: G_ExperienceSessionNodeConnection;
  slug: Scalars['String'];
  startsAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  webflowCmsId?: Maybe<Scalars['String']>;
}


export interface G_ExperienceNodeSessionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  experienceId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface G_ExperienceNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ExperienceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ExperienceNode` and its cursor. */
export interface G_ExperienceNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ExperienceNode>;
}

/** An enumeration. */
export type G_ExperienceScheduleTypes =
  | 'ALL'
  | 'FUTURE'
  | 'ON_DEMAND'
  | 'PAST';

export interface G_ExperienceSessionNode extends G_Node {
  category: G_ListingCategoryNode;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  endsAt?: Maybe<Scalars['DateTime']>;
  experience: G_ExperienceBySlugNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Designates whether the event is available online or not. */
  isAvailableOnline: Scalars['Boolean'];
  onlineMeetingIntroduction?: Maybe<Scalars['String']>;
  onlineMeetingLink?: Maybe<Scalars['String']>;
  onlineMeetingPin?: Maybe<Scalars['String']>;
  profile: G_ProfileNode;
  startsAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  webflowCmsId?: Maybe<Scalars['String']>;
}

export interface G_ExperienceSessionNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ExperienceSessionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ExperienceSessionNode` and its cursor. */
export interface G_ExperienceSessionNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ExperienceSessionNode>;
}

export interface G_ExperiencesRegistrationNode extends G_Node {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  lastName: Scalars['String'];
  profile?: Maybe<G_ProfileNode>;
  referenceCode: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
}

export interface G_ExperiencesRegistrationNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ExperiencesRegistrationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ExperiencesRegistrationNode` and its cursor. */
export interface G_ExperiencesRegistrationNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ExperiencesRegistrationNode>;
}

export interface G_GeolocationInput {
  coordinates: Array<InputMaybe<Scalars['Float']>>;
  type: Scalars['String'];
}

export interface G_GeolocationPoint {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
}

/** An enumeration. */
export type G_KycDocumentTypes =
  | 'ADDRESS_PROOF'
  | 'ARTICLES_OF_ASSOCIATION'
  | 'IDENTITY_PROOF'
  | 'REGISTRATION_PROOF'
  | 'SHAREHOLDER_DECLARATION';

export interface G_LanguageNode extends G_Node {
  /** The ID of the object. */
  id: Scalars['ID'];
  isoCode: Scalars['String'];
  languagesOthersForListings: G_ListingNodeConnection;
  languagesPreferredForProfiles: G_ProfileNodeConnection;
  languagesPrimaryForListings: G_ListingNodeConnection;
  name: Scalars['String'];
  profiles: G_ProfileNodeConnection;
}


export interface G_LanguageNodeLanguagesOthersForListingsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_Icontains?: InputMaybe<Scalars['String']>;
  title_Istartswith?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
}


export interface G_LanguageNodeLanguagesPreferredForProfilesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
}


export interface G_LanguageNodeLanguagesPrimaryForListingsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_Icontains?: InputMaybe<Scalars['String']>;
  title_Istartswith?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
}


export interface G_LanguageNodeProfilesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
}

export interface G_LanguageNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_LanguageNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `LanguageNode` and its cursor. */
export interface G_LanguageNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_LanguageNode>;
}

export interface G_ListingCategoryNode extends G_Node {
  children: G_ListingCategoryNodeConnection;
  experienceSessionCategories: G_ExperienceSessionNodeConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
  imageUri: Scalars['String'];
  level: Scalars['Int'];
  lft: Scalars['Int'];
  listings: G_ListingNodeConnection;
  name: Scalars['String'];
  nameDe: Scalars['String'];
  parent?: Maybe<G_ListingCategoryNode>;
  rght: Scalars['Int'];
  slug: Scalars['String'];
  treeId: Scalars['Int'];
  webflowCmsId?: Maybe<Scalars['String']>;
}


export interface G_ListingCategoryNodeChildrenArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<Scalars['ID']>;
}


export interface G_ListingCategoryNodeExperienceSessionCategoriesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  experienceId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingCategoryNodeListingsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_Icontains?: InputMaybe<Scalars['String']>;
  title_Istartswith?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
}

export interface G_ListingCategoryNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ListingCategoryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ListingCategoryNode` and its cursor. */
export interface G_ListingCategoryNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ListingCategoryNode>;
}

export interface G_ListingItemInput {
  availableSeats?: InputMaybe<Scalars['Int']>;
  isAvailableOnline: Scalars['Boolean'];
  locations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  onlineMeetingIntroduction?: InputMaybe<Scalars['String']>;
  onlineMeetingLink?: InputMaybe<Scalars['String']>;
  onlineMeetingPin?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<G_ListingItemOptionInput>>>;
  order?: InputMaybe<Scalars['Int']>;
}

export interface G_ListingItemNode extends G_Node {
  availableSeats: Scalars['Int'];
  calendarEvent?: Maybe<G_CalendarEventNode>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Designates whether the event is available online or not. */
  isAvailableOnline: Scalars['Boolean'];
  listing: G_ListingNode;
  locations: G_LocationNodeConnection;
  onlineMeetingIntroduction?: Maybe<Scalars['String']>;
  onlineMeetingLink?: Maybe<Scalars['String']>;
  onlineMeetingPin?: Maybe<Scalars['String']>;
  options: G_ListingItemOptionNodeConnection;
  order: Scalars['Int'];
  sessions: G_ListingItemSessionNodeConnection;
  transactions: G_TransactionNodeConnection;
  updatedAt?: Maybe<Scalars['DateTime']>;
}


export interface G_ListingItemNodeLocationsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingItemNodeOptionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingItemNodeSessionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingItemNodeTransactionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  mangopayPayinId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
}

export interface G_ListingItemNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ListingItemNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ListingItemNode` and its cursor. */
export interface G_ListingItemNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ListingItemNode>;
}

export interface G_ListingItemOptionInput {
  duration: Scalars['Int'];
  order?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  priceCurrency: G_CurrencyTypes;
  rrule?: InputMaybe<Scalars['String']>;
}

export interface G_ListingItemOptionNode extends G_Node {
  bookings: G_BookingNodeConnection;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration: Scalars['Int'];
  /** The ID of the object. */
  id: Scalars['ID'];
  listingItem: G_ListingItemNode;
  occurrences: G_CalendarOccurrenceNodeConnection;
  order: Scalars['Int'];
  price: Scalars['Decimal'];
  priceCurrency: G_ListingItemOptionPriceCurrency;
  priceCurrencyNew: G_ListingItemOptionPriceCurrencyNew;
  stripePriceId?: Maybe<Scalars['String']>;
  transactions: G_TransactionNodeConnection;
  updatedAt?: Maybe<Scalars['DateTime']>;
}


export interface G_ListingItemOptionNodeBookingsArgs {
  after?: InputMaybe<Scalars['String']>;
  attendeeProfile?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingItemOptionNodeOccurrencesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingItemOptionNodeTransactionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  mangopayPayinId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
}

export interface G_ListingItemOptionNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ListingItemOptionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ListingItemOptionNode` and its cursor. */
export interface G_ListingItemOptionNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ListingItemOptionNode>;
}

/** An enumeration. */
export type G_ListingItemOptionPriceCurrency =
  /** Dollar */
  | 'DOLLAR'
  /** Euro */
  | 'EURO';

/** An enumeration. */
export type G_ListingItemOptionPriceCurrencyNew =
  /** Euro */
  | 'A_1'
  /** Dollar */
  | 'A_2';

export interface G_ListingItemSessionNode extends G_Node {
  bookedSeats?: Maybe<Scalars['Int']>;
  bookings: G_BookingNodeConnection;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  endTs?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  listingItem: G_ListingItemNode;
  occurrence?: Maybe<G_CalendarOccurrenceNode>;
  startTs?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
}


export interface G_ListingItemSessionNodeBookingsArgs {
  after?: InputMaybe<Scalars['String']>;
  attendeeProfile?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface G_ListingItemSessionNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ListingItemSessionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ListingItemSessionNode` and its cursor. */
export interface G_ListingItemSessionNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ListingItemSessionNode>;
}

/** An enumeration. */
export type G_ListingLocationFlags =
  | 'IN_PERSON'
  | 'VIRTUAL'
  | 'VIRTUAL_AND_IN_PERSON';

export interface G_ListingMediaInput {
  cloudinaryAssetId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  type: G_ListingMediaTypes;
  uri: Scalars['String'];
  uriThumbnail: Scalars['String'];
}

export interface G_ListingMediaNode extends G_Node {
  cloudinaryAssetId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  listing: G_ListingNode;
  order: Scalars['Int'];
  title: Scalars['String'];
  type: G_ListingMediaType;
  typeNew: G_ListingMediaTypeNew;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri: Scalars['String'];
  uriThumbnail: Scalars['String'];
}

export interface G_ListingMediaNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ListingMediaNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ListingMediaNode` and its cursor. */
export interface G_ListingMediaNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ListingMediaNode>;
}

/** An enumeration. */
export type G_ListingMediaType =
  /** Image */
  | 'IMAGE'
  /** Video */
  | 'VIDEO';

/** An enumeration. */
export type G_ListingMediaTypeNew =
  /** Image */
  | 'A_1'
  /** Video */
  | 'A_2';

/** An enumeration. */
export type G_ListingMediaTypes =
  | 'IMAGE'
  | 'VIDEO';

export interface G_ListingMediaUpdateInput {
  Status: G_UpdateTypes;
  cloudinaryAssetId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<G_ListingMediaTypes>;
  uri?: InputMaybe<Scalars['String']>;
  uriThumbnail?: InputMaybe<Scalars['String']>;
}

export interface G_ListingNode extends G_Node {
  _geoloc?: Maybe<Array<Maybe<G_GeolocationPoint>>>;
  category: G_ListingCategoryNode;
  coverImageUri?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  hasUpcomingSession?: Maybe<Scalars['Boolean']>;
  hierarchicalCategories?: Maybe<G_CategoryData>;
  /** The ID of the object. */
  id: Scalars['ID'];
  items: G_ListingItemNodeConnection;
  languages: G_LanguageNodeConnection;
  media: G_ListingMediaNodeConnection;
  primaryLanguage: G_LanguageNode;
  profile: G_ProfileNode;
  rating?: Maybe<Scalars['Float']>;
  reviews: G_ListingReviewsByProfileNodeConnection;
  slug: Scalars['String'];
  status: G_ListingStatus;
  statusNew: G_ListingStatusNew;
  stripeProductId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  transactions: G_TransactionNodeConnection;
  type: G_ListingType;
  typeNew: G_ListingTypeNew;
  upcomingSessions?: Maybe<G_ListingItemSessionNodeConnection>;
  upcomingVirtualSessions?: Maybe<G_ListingItemSessionNodeConnection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
}


export interface G_ListingNodeItemsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingNodeLanguagesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isoCode?: InputMaybe<Scalars['String']>;
  isoCode_Icontains?: InputMaybe<Scalars['String']>;
  isoCode_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  name_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingNodeMediaArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingNodeReviewsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingNodeTransactionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  mangopayPayinId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
}


export interface G_ListingNodeUpcomingSessionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}


export interface G_ListingNodeUpcomingVirtualSessionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}

export interface G_ListingNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ListingNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ListingNode` and its cursor. */
export interface G_ListingNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ListingNode>;
}

export interface G_ListingReviewNode extends G_Node {
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  listing: G_ListingNode;
  order: Scalars['Int'];
  profile: G_ProfileNode;
  rating: Scalars['Int'];
  released: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
}

export interface G_ListingReviewNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ListingReviewNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ListingReviewNode` and its cursor. */
export interface G_ListingReviewNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ListingReviewNode>;
}

export interface G_ListingReviewsByProfileNode extends G_Node {
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  listing: G_ListingNode;
  order: Scalars['Int'];
  profile: G_ProfileNode;
  rating: Scalars['Int'];
  released: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
}

export interface G_ListingReviewsByProfileNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ListingReviewsByProfileNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ListingReviewsByProfileNode` and its cursor. */
export interface G_ListingReviewsByProfileNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ListingReviewsByProfileNode>;
}

/** An enumeration. */
export type G_ListingStatus =
  /** Active */
  | 'ACTIVE'
  /** Archived */
  | 'ARCHIVED'
  /** Draft */
  | 'DRAFT'
  /** Passive */
  | 'PASSIVE';

/** An enumeration. */
export type G_ListingStatusNew =
  /** Passive */
  | 'A_0'
  /** Active */
  | 'A_1'
  /** Draft */
  | 'A_2'
  /** Archived */
  | 'A_3';

/** An enumeration. */
export type G_ListingStatuses =
  | 'ACTIVE'
  | 'ARCHIVED'
  | 'DRAFT'
  | 'PASSIVE';

/** An enumeration. */
export type G_ListingType =
  /** Appointment */
  | 'APPOINTMENT'
  /** Class */
  | 'CLASS';

/** An enumeration. */
export type G_ListingTypeNew =
  /** Appointment */
  | 'A_1'
  /** Class */
  | 'A_2';

/** An enumeration. */
export type G_ListingTypes =
  | 'APPOINTMENT'
  | 'CLASS';

export interface G_ListingUpdateItemInput {
  Status: G_UpdateTypes;
  availableSeats?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isAvailableOnline?: InputMaybe<Scalars['Boolean']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  onlineMeetingIntroduction?: InputMaybe<Scalars['String']>;
  onlineMeetingLink?: InputMaybe<Scalars['String']>;
  onlineMeetingPin?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<G_ListingUpdateItemOptionInput>>>;
  order?: InputMaybe<Scalars['Int']>;
}

export interface G_ListingUpdateItemOptionInput {
  Status: G_UpdateTypes;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  order?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priceCurrency?: InputMaybe<G_CurrencyTypes>;
  rrule?: InputMaybe<Scalars['String']>;
}

export interface G_ListingUpdateLanguageInput {
  Status: G_UpdateTypes;
  id?: InputMaybe<Scalars['ID']>;
}

export interface G_LocationNode extends G_Node {
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city?: Maybe<G_CityNode>;
  country?: Maybe<G_CountryNode>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  geolocation?: Maybe<Scalars['PointScalar']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  listingItems: G_ListingItemNodeConnection;
  order: Scalars['Int'];
  postalCode: Scalars['String'];
  profile: G_ProfileNode;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
}


export interface G_LocationNodeListingItemsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface G_LocationNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_LocationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `LocationNode` and its cursor. */
export interface G_LocationNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_LocationNode>;
}

export interface G_Mutation {
  authRefreshToken?: Maybe<G_Refresh>;
  authRevokeToken?: Maybe<G_Revoke>;
  authToken?: Maybe<G_ObtainJsonWebToken>;
  authVerifyToken?: Maybe<G_Verify>;
  book?: Maybe<G_BookResponse>;
  bookingCalculatePrice?: Maybe<G_BookingCalculatePriceResponse>;
  bookingMessageAdd?: Maybe<G_BookingMessageNode>;
  calendarCustomEventAdd?: Maybe<G_CalendarEventNode>;
  calendarCustomEventRemove?: Maybe<G_CalendarEventNode>;
  calendarCustomEventUpdate?: Maybe<G_CalendarEventNode>;
  calendarOccurrenceRemove?: Maybe<G_CalendarOccurrenceNode>;
  calendarOccurrenceRemoveSeries?: Maybe<G_CalendarOccurrenceNode>;
  calendarSetAvailability?: Maybe<Array<Maybe<G_CalendarAvailableHoursNode>>>;
  changePassword?: Maybe<G_ChangePasswordResponse>;
  changePayoutSettings?: Maybe<G_ProfileNode>;
  checkoutSession?: Maybe<G_CheckoutSessionResponse>;
  classSessions_Add?: Maybe<G_ListingNode>;
  createAccountLink?: Maybe<G_AccountLinkResponse>;
  experiencesRegister?: Maybe<G_ExperiencesRegistrationNode>;
  listingAdd?: Maybe<G_ListingNode>;
  listingAddAsClass?: Maybe<G_ListingNode>;
  listingAddReview?: Maybe<G_ListingReviewNode>;
  listingRemove?: Maybe<G_ListingNode>;
  listingRemoveAsClass?: Maybe<G_ListingNode>;
  listingRemoveItemsForClass?: Maybe<G_ListingNode>;
  listingRemoveReview?: Maybe<G_ListingReviewNode>;
  listingUpdate?: Maybe<G_ListingNode>;
  listingUpdateAsClass?: Maybe<G_ListingNode>;
  listingUpdateItemsForClass?: Maybe<G_ListingNode>;
  listingUpdateReview?: Maybe<G_ListingReviewNode>;
  profileAddressAdd?: Maybe<G_LocationNode>;
  profileAddressEdit?: Maybe<G_LocationNode>;
  profileAddressRemove?: Maybe<G_LocationNode>;
  profileBecomeExpert?: Maybe<G_ProfileNode>;
  profileCalendarRemove?: Maybe<G_CalendarNode>;
  profileUpdate?: Maybe<G_ProfileNode>;
  profileUpdateByKycDocument?: Maybe<G_ProfileVerificationDocumentsNode>;
  resetPassword?: Maybe<G_ResetPasswordResponse>;
  resetPasswordConfirm?: Maybe<G_ResetPasswordConfirmResponse>;
  signup?: Maybe<G_SignupResponse>;
  signupConfirm?: Maybe<G_SignupConfirmResponse>;
  signupResend?: Maybe<G_SignupResendResponse>;
}


export interface G_MutationAuthRefreshTokenArgs {
  token?: InputMaybe<Scalars['String']>;
}


export interface G_MutationAuthRevokeTokenArgs {
  refreshToken?: InputMaybe<Scalars['String']>;
}


export interface G_MutationAuthTokenArgs {
  password: Scalars['String'];
  username: Scalars['String'];
}


export interface G_MutationAuthVerifyTokenArgs {
  token?: InputMaybe<Scalars['String']>;
}


export interface G_MutationBookArgs {
  acceptHeader?: InputMaybe<Scalars['String']>;
  bookedTime?: InputMaybe<Scalars['DateTime']>;
  cardCvx?: InputMaybe<Scalars['String']>;
  cardExpirationDate?: InputMaybe<Scalars['String']>;
  cardNumber?: InputMaybe<Scalars['String']>;
  cardType?: InputMaybe<Scalars['String']>;
  cardholderName?: InputMaybe<Scalars['String']>;
  colorDepth?: InputMaybe<Scalars['Int']>;
  couponCode?: InputMaybe<Scalars['String']>;
  javaEnabled?: InputMaybe<Scalars['Boolean']>;
  javascriptEnabled?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  listingItemOption: Scalars['ID'];
  listingItemSession?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  profile: Scalars['ID'];
  returnUrl?: InputMaybe<Scalars['String']>;
  screenHeight?: InputMaybe<Scalars['Int']>;
  screenWidth?: InputMaybe<Scalars['Int']>;
  timezoneOffset?: InputMaybe<Scalars['String']>;
  userAgent?: InputMaybe<Scalars['String']>;
}


export interface G_MutationBookingCalculatePriceArgs {
  couponCode?: InputMaybe<Scalars['String']>;
  listingItemOption: Scalars['ID'];
}


export interface G_MutationBookingMessageAddArgs {
  body: Scalars['String'];
  booking: Scalars['ID'];
  fromProfile: Scalars['ID'];
  type: G_BookMessageTypes;
}


export interface G_MutationCalendarCustomEventAddArgs {
  description: Scalars['String'];
  endTime: Scalars['DateTime'];
  profile: Scalars['ID'];
  startTime: Scalars['DateTime'];
  title: Scalars['String'];
}


export interface G_MutationCalendarCustomEventRemoveArgs {
  calendarEvent: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationCalendarCustomEventUpdateArgs {
  calendarEvent: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  profile: Scalars['ID'];
  startTime?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
}


export interface G_MutationCalendarOccurrenceRemoveArgs {
  calendarOccurrence: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationCalendarOccurrenceRemoveSeriesArgs {
  calendarOccurrence: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationCalendarSetAvailabilityArgs {
  availableHours?: InputMaybe<Array<InputMaybe<G_CalendarSetAvailabilityInput>>>;
  calendar: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationChangePasswordArgs {
  newPassword: Scalars['String'];
  previousPassword: Scalars['String'];
}


export interface G_MutationChangePayoutSettingsArgs {
  companyInfo?: InputMaybe<G_CompanyInput>;
  companyRepresentativeInfo?: InputMaybe<G_RepresentativeInput>;
  documents?: InputMaybe<Array<InputMaybe<G_DocumentInput>>>;
  iban?: InputMaybe<Scalars['String']>;
  paymentPersonType?: InputMaybe<G_AccountTypes>;
  profile: Scalars['ID'];
}


export interface G_MutationCheckoutSessionArgs {
  bookedTime?: InputMaybe<Scalars['DateTime']>;
  browserInfo?: InputMaybe<Scalars['String']>;
  cancelUrl: Scalars['String'];
  couponCode?: InputMaybe<Scalars['String']>;
  listingItemOptionId: Scalars['ID'];
  listingItemSessionId?: InputMaybe<Scalars['ID']>;
  locationId?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  profileId: Scalars['ID'];
  successUrl: Scalars['String'];
}


export interface G_MutationClassSessions_AddArgs {
  items?: InputMaybe<Array<InputMaybe<G_ListingItemInput>>>;
  listing: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationCreateAccountLinkArgs {
  profileId: Scalars['ID'];
}


export interface G_MutationExperiencesRegisterArgs {
  referenceCode: Scalars['String'];
}


export interface G_MutationListingAddArgs {
  category: Scalars['ID'];
  description: Scalars['String'];
  items?: InputMaybe<Array<InputMaybe<G_ListingItemInput>>>;
  languageOthers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  languagePrimary: Scalars['ID'];
  media?: InputMaybe<Array<InputMaybe<G_ListingMediaInput>>>;
  profile: Scalars['ID'];
  slug?: InputMaybe<Scalars['String']>;
  status: G_ListingStatuses;
  title: Scalars['String'];
  type: G_ListingTypes;
}


export interface G_MutationListingAddAsClassArgs {
  category: Scalars['ID'];
  description: Scalars['String'];
  languageOthers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  languagePrimary: Scalars['ID'];
  media?: InputMaybe<Array<InputMaybe<G_ListingMediaInput>>>;
  profile: Scalars['ID'];
  slug?: InputMaybe<Scalars['String']>;
  status: G_ListingStatuses;
  title: Scalars['String'];
  type: G_ListingTypes;
}


export interface G_MutationListingAddReviewArgs {
  booking: Scalars['ID'];
  content?: InputMaybe<Scalars['String']>;
  listing: Scalars['ID'];
  order?: InputMaybe<Scalars['Int']>;
  profile: Scalars['ID'];
  rating?: InputMaybe<Scalars['Int']>;
}


export interface G_MutationListingRemoveArgs {
  listing: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationListingRemoveAsClassArgs {
  listing: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationListingRemoveItemsForClassArgs {
  items?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  listing: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationListingRemoveReviewArgs {
  listing: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationListingUpdateArgs {
  category?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<InputMaybe<G_ListingUpdateItemInput>>>;
  languageOthers?: InputMaybe<Array<InputMaybe<G_ListingUpdateLanguageInput>>>;
  languagePrimary?: InputMaybe<Scalars['ID']>;
  listing: Scalars['ID'];
  media?: InputMaybe<Array<InputMaybe<G_ListingMediaUpdateInput>>>;
  profile: Scalars['ID'];
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<G_ListingStatuses>;
  title?: InputMaybe<Scalars['String']>;
}


export interface G_MutationListingUpdateAsClassArgs {
  category?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  languageOthers?: InputMaybe<Array<InputMaybe<G_ListingUpdateLanguageInput>>>;
  languagePrimary?: InputMaybe<Scalars['ID']>;
  listing: Scalars['ID'];
  media?: InputMaybe<Array<InputMaybe<G_ListingMediaUpdateInput>>>;
  profile: Scalars['ID'];
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<G_ListingStatuses>;
  title?: InputMaybe<Scalars['String']>;
}


export interface G_MutationListingUpdateItemsForClassArgs {
  items?: InputMaybe<Array<InputMaybe<G_ListingUpdateItemInput>>>;
  listing: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationListingUpdateReviewArgs {
  content: Scalars['String'];
  listing: Scalars['ID'];
  order?: InputMaybe<Scalars['Int']>;
  profile: Scalars['ID'];
  rating?: InputMaybe<Scalars['Int']>;
}


export interface G_MutationProfileAddressAddArgs {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['ID'];
  geolocation?: InputMaybe<G_GeolocationInput>;
  order?: InputMaybe<Scalars['Int']>;
  postalCode?: InputMaybe<Scalars['String']>;
  profile: Scalars['ID'];
  title: Scalars['String'];
}


export interface G_MutationProfileAddressEditArgs {
  address: Scalars['ID'];
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['ID']>;
  geolocation?: InputMaybe<G_GeolocationInput>;
  order?: InputMaybe<Scalars['Int']>;
  postalCode?: InputMaybe<Scalars['String']>;
  profile: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
}


export interface G_MutationProfileAddressRemoveArgs {
  address: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationProfileBecomeExpertArgs {
  profile: Scalars['ID'];
}


export interface G_MutationProfileCalendarRemoveArgs {
  calendar: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_MutationProfileUpdateArgs {
  bio?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['String']>;
  coverPictureAssetId?: InputMaybe<Scalars['String']>;
  coverPictureUri?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  locationCity?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<Scalars['ID']>;
  profile: Scalars['ID'];
  profilePictureAssetId?: InputMaybe<Scalars['String']>;
  profilePictureUri?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
}


export interface G_MutationProfileUpdateByKycDocumentArgs {
  eventType: Scalars['String'];
  resourceId: Scalars['String'];
}


export interface G_MutationResetPasswordArgs {
  username: Scalars['String'];
}


export interface G_MutationResetPasswordConfirmArgs {
  confirmationCode: Scalars['String'];
  newPassword: Scalars['String'];
  username: Scalars['String'];
}


export interface G_MutationSignupArgs {
  birthdate?: InputMaybe<Scalars['Date']>;
  coverPictureAssetId?: InputMaybe<Scalars['String']>;
  coverPictureUri?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  profilePictureAssetId?: InputMaybe<Scalars['String']>;
  profilePictureUri?: InputMaybe<Scalars['String']>;
  promotionMailChoice?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<G_ProfileRoles>;
}


export interface G_MutationSignupConfirmArgs {
  confirmationCode: Scalars['String'];
  username: Scalars['String'];
}


export interface G_MutationSignupResendArgs {
  username: Scalars['String'];
}

/** An object with an ID */
export interface G_Node {
  /** The ID of the object. */
  id: Scalars['ID'];
}

export interface G_ObtainJsonWebToken {
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  user?: Maybe<G_UserNode>;
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export interface G_PageInfo {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
}

/** An enumeration. */
export type G_ProfileGender =
  /** Diverse */
  | 'DIVERSE'
  /** Female */
  | 'FEMALE'
  /** Male */
  | 'MALE'
  /** Not Specified */
  | 'NOT_SPECIFIED';

/** An enumeration. */
export type G_ProfileGenderNew =
  /** Female */
  | 'A_1'
  /** Male */
  | 'A_2'
  /** Diverse */
  | 'A_3'
  /** Not Specified */
  | 'A_4';

/** An enumeration. */
export type G_ProfileKycStatus =
  /** Applied */
  | 'APPLIED'
  /** Not Verified */
  | 'NOT_VERIFIED'
  /** Rejected */
  | 'REJECTED'
  /** Unnecessary */
  | 'UNNECESSARY'
  /** Verified */
  | 'VERIFIED';

/** An enumeration. */
export type G_ProfileKycStatusNew =
  /** Unnecessary */
  | 'A_1'
  /** Not Verified */
  | 'A_2'
  /** Applied */
  | 'A_3'
  /** Verified */
  | 'A_4'
  /** Rejected */
  | 'A_5';

export interface G_ProfileNode extends G_Node {
  addresses: G_LocationNodeConnection;
  bio: Scalars['String'];
  birthdate?: Maybe<Scalars['Date']>;
  bookings: G_BookingNodeConnection;
  calendars: G_CalendarNodeConnection;
  companyAddressAddressLine1?: Maybe<Scalars['String']>;
  companyAddressAddressLine2?: Maybe<Scalars['String']>;
  companyAddressCity?: Maybe<G_CityNode>;
  companyAddressCountry?: Maybe<G_CountryNode>;
  companyAddressGeolocation?: Maybe<Scalars['String']>;
  companyAddressPostalCode?: Maybe<Scalars['String']>;
  companyAddressTitle?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  companyRepresentativeAddressAddressLine1?: Maybe<Scalars['String']>;
  companyRepresentativeAddressAddressLine2?: Maybe<Scalars['String']>;
  companyRepresentativeAddressCity?: Maybe<G_CityNode>;
  companyRepresentativeAddressCountry?: Maybe<G_CountryNode>;
  companyRepresentativeAddressGeolocation?: Maybe<Scalars['String']>;
  companyRepresentativeAddressPostalCode?: Maybe<Scalars['String']>;
  companyRepresentativeAddressTitle?: Maybe<Scalars['String']>;
  companyRepresentativeBirthdate?: Maybe<Scalars['Date']>;
  companyRepresentativeEmail?: Maybe<Scalars['String']>;
  companyRepresentativeFirstName?: Maybe<Scalars['String']>;
  companyRepresentativeLastName?: Maybe<Scalars['String']>;
  coverPictureUri?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  documents: G_ProfileVerificationDocumentsNodeConnection;
  email: Scalars['String'];
  experienceSessionProfiles: G_ExperienceSessionNodeConnection;
  experiencesRegistrations: G_ExperiencesRegistrationNodeConnection;
  firstName: Scalars['String'];
  gender: G_ProfileGender;
  genderNew: G_ProfileGenderNew;
  hasAvailability?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isStripeExpertAccountInitialized?: Maybe<Scalars['Boolean']>;
  kycStatus: G_ProfileKycStatus;
  kycStatusNew: G_ProfileKycStatusNew;
  languages: G_LanguageNodeConnection;
  lastName: Scalars['String'];
  listings: G_ListingNodeConnection;
  locationCity?: Maybe<G_CityNode>;
  locationCountry?: Maybe<G_CountryNode>;
  messagesReceived: G_BookingMessageNodeConnection;
  messagesSent: G_BookingMessageNodeConnection;
  notificationBookingRequests: Scalars['Boolean'];
  notificationMessages: Scalars['Boolean'];
  notificationPolicyAndCommunity: Scalars['Boolean'];
  notificationPromotionsAndTips: Scalars['Boolean'];
  notificationReviews: Scalars['Boolean'];
  payoutAccountType: G_ProfilePayoutAccountType;
  payoutAccountTypeNew: G_ProfilePayoutAccountTypeNew;
  payoutSellerBankAccountBic?: Maybe<Scalars['String']>;
  payoutSellerBankAccountIban?: Maybe<Scalars['String']>;
  payoutSellerBankAccountOwnerName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<G_LanguageNode>;
  profilePictureUri?: Maybe<Scalars['String']>;
  /** Is user allowed the promotion mails? */
  promotionMailChoice: Scalars['Boolean'];
  rating?: Maybe<Scalars['Float']>;
  receiverTransactions: G_TransactionNodeConnection;
  reviews: G_ListingReviewsByProfileNodeConnection;
  role: G_ProfileRole;
  roleNew: G_ProfileRoleNew;
  senderTransactions: G_TransactionNodeConnection;
  serviceFeeRatio: Scalars['Decimal'];
  slug: Scalars['String'];
  stats?: Maybe<G_ProfileStats>;
  status: G_ProfileStatus;
  statusNew: G_ProfileStatusNew;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeExpertId?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  users: G_UserNodeConnection;
  webflowCmsId?: Maybe<Scalars['String']>;
}


export interface G_ProfileNodeAddressesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ProfileNodeBookingsArgs {
  after?: InputMaybe<Scalars['String']>;
  attendeeProfile?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ProfileNodeCalendarsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  title_Icontains?: InputMaybe<Scalars['String']>;
  title_Istartswith?: InputMaybe<Scalars['String']>;
}


export interface G_ProfileNodeDocumentsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  mangopayKycDocumentId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ProfileNodeExperienceSessionProfilesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  experienceId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ProfileNodeExperiencesRegistrationsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ProfileNodeLanguagesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isoCode?: InputMaybe<Scalars['String']>;
  isoCode_Icontains?: InputMaybe<Scalars['String']>;
  isoCode_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  name_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ProfileNodeListingsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_Icontains?: InputMaybe<Scalars['String']>;
  title_Istartswith?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
}


export interface G_ProfileNodeMessagesReceivedArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ProfileNodeMessagesSentArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_ProfileNodeReceiverTransactionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  mangopayPayinId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
}


export interface G_ProfileNodeReviewsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
}


export interface G_ProfileNodeSenderTransactionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  mangopayPayinId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
}


export interface G_ProfileNodeUsersArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface G_ProfileNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ProfileNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ProfileNode` and its cursor. */
export interface G_ProfileNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ProfileNode>;
}

/** An enumeration. */
export type G_ProfilePayoutAccountType =
  /** Company */
  | 'COMPANY'
  /** Individual */
  | 'INDIVIDUAL';

/** An enumeration. */
export type G_ProfilePayoutAccountTypeNew =
  /** Individual */
  | 'A_1'
  /** Company */
  | 'A_2';

/** An enumeration. */
export type G_ProfileRole =
  /** Customer */
  | 'CUSTOMER'
  /** Expert */
  | 'EXPERT';

/** An enumeration. */
export type G_ProfileRoleNew =
  /** Customer */
  | 'A_1'
  /** Expert */
  | 'A_2';

/** An enumeration. */
export type G_ProfileRoles =
  | 'CUSTOMER'
  | 'EXPERT';

export interface G_ProfileStats {
  balances?: Maybe<Scalars['Decimal']>;
  totalBookings?: Maybe<Scalars['Int']>;
  totalClients?: Maybe<Scalars['Int']>;
  totalRevenue?: Maybe<Scalars['Decimal']>;
}

/** An enumeration. */
export type G_ProfileStatus =
  /** Active */
  | 'ACTIVE'
  /** Disabled */
  | 'DISABLED'
  /** Featured */
  | 'FEATURED'
  /** Not Verified */
  | 'NOT_VERIFIED';

/** An enumeration. */
export type G_ProfileStatusNew =
  /** Active */
  | 'A_1'
  /** Not Verified */
  | 'A_2'
  /** Disabled */
  | 'A_3'
  /** Featured */
  | 'A_6';

/** An enumeration. */
export type G_ProfileVerificationDocumentsMangopayKycDocumentRefusedReasonType =
  /** Declaration Do Not Match Ubo Information */
  | 'DECLARATION_DO_NOT_MATCH_UBO_INFORMATION'
  /** Document Do Not Match Account Aata */
  | 'DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA'
  /** Document Do Not Match User Data */
  | 'DOCUMENT_DO_NOT_MATCH_USER_DATA'
  /** Document Falsified */
  | 'DOCUMENT_FALSIFIED'
  /** Document Has Expired */
  | 'DOCUMENT_HAS_EXPIRED'
  /** Document Incomplete */
  | 'DOCUMENT_INCOMPLETE'
  /** Document Needed */
  | 'DOCUMENT_NEEDED'
  /** Document Not Accepted */
  | 'DOCUMENT_NOT_ACCEPTED'
  /** Document Unreadable */
  | 'DOCUMENT_UNREADABLE'
  /** Missing Ubo */
  | 'MISSING_UBO'
  /** Organization Chart Needed */
  | 'ORGANIZATION_CHART_NEEDED'
  /** Shareholder Declaration Needed */
  | 'SHAREHOLDER_DECLARATION_NEEDED'
  /** Specific Case */
  | 'SPECIFIC_CASE'
  /** Ubo Identity Needed */
  | 'UBO_IDENTITY_NEEDED'
  /** Underage Person */
  | 'UNDERAGE_PERSON'
  /** Wrong Ubo Information */
  | 'WRONG_UBO_INFORMATION';

/** An enumeration. */
export type G_ProfileVerificationDocumentsMangopayKycDocumentRefusedReasonTypeNew =
  /** Document Unreadable */
  | 'A_1'
  /** Document Not Accepted */
  | 'A_2'
  /** Document Has Expired */
  | 'A_3'
  /** Document Incomplete */
  | 'A_4'
  /** Document Do Not Match User Data */
  | 'A_5'
  /** Document Do Not Match Account Data */
  | 'A_6'
  /** Document Falsified */
  | 'A_7'
  /** Underage Person */
  | 'A_8'
  /** Specific Case */
  | 'A_9'
  /** Missing Ubo */
  | 'A_10'
  /** Wrong Ubo Information */
  | 'A_11'
  /** Ubo Identity Needed */
  | 'A_12'
  /** Shareholder Declaration Needed */
  | 'A_13'
  /** Organization Chart Needed */
  | 'A_14'
  /** Document Needed */
  | 'A_15'
  /** Declaration Do Not Match Ubo Information */
  | 'A_16';

/** An enumeration. */
export type G_ProfileVerificationDocumentsMangopayKycDocumentStatus =
  /** Created */
  | 'CREATED'
  /** Incomplete */
  | 'INCOMPLETE'
  /** Out of Date */
  | 'OUT_OF_DATE'
  /** Refused */
  | 'REFUSED'
  /** Validated */
  | 'VALIDATED'
  /** Validation Asked */
  | 'VALIDATION_ASKED';

/** An enumeration. */
export type G_ProfileVerificationDocumentsMangopayKycDocumentStatusNew =
  /** Created */
  | 'A_1'
  /** Validation Asked */
  | 'A_2'
  /** Validated */
  | 'A_3'
  /** Refused */
  | 'A_4'
  /** Out of Date */
  | 'A_5'
  /** Incomplete */
  | 'A_6';

/** An enumeration. */
export type G_ProfileVerificationDocumentsMangopayKycDocumentType =
  /** Address Proof */
  | 'ADDRESS_PROOF'
  /** Articles of Association */
  | 'ARTICLES_OF_ASSOCIATION'
  /** Identity Proof */
  | 'IDENTITY_PROOF'
  /** Registration Proof */
  | 'REGISTRATION_PROOF'
  /** Shareholder Declaration */
  | 'SHAREHOLDER_DECLARATION';

/** An enumeration. */
export type G_ProfileVerificationDocumentsMangopayKycDocumentTypeNew =
  /** Identity Proof */
  | 'A_1'
  /** Registration Proof */
  | 'A_2'
  /** Articles of Association */
  | 'A_3'
  /** Shareholder Declaration */
  | 'A_4'
  /** Address Proof */
  | 'A_5';

export interface G_ProfileVerificationDocumentsNode extends G_Node {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  mangopayKycDocumentId?: Maybe<Scalars['String']>;
  mangopayKycDocumentRefusedReasonMessage?: Maybe<Scalars['String']>;
  mangopayKycDocumentRefusedReasonType?: Maybe<G_ProfileVerificationDocumentsMangopayKycDocumentRefusedReasonType>;
  mangopayKycDocumentRefusedReasonTypeNew?: Maybe<G_ProfileVerificationDocumentsMangopayKycDocumentRefusedReasonTypeNew>;
  mangopayKycDocumentStatus?: Maybe<G_ProfileVerificationDocumentsMangopayKycDocumentStatus>;
  mangopayKycDocumentStatusNew?: Maybe<G_ProfileVerificationDocumentsMangopayKycDocumentStatusNew>;
  mangopayKycDocumentSubmittedAt: Scalars['DateTime'];
  mangopayKycDocumentType?: Maybe<G_ProfileVerificationDocumentsMangopayKycDocumentType>;
  mangopayKycDocumentTypeNew?: Maybe<G_ProfileVerificationDocumentsMangopayKycDocumentTypeNew>;
  mangopayKycDocumentUri?: Maybe<Scalars['String']>;
  mangopayKycDocumentValidatedAt?: Maybe<Scalars['DateTime']>;
  profile: G_ProfileNode;
  updatedAt?: Maybe<Scalars['DateTime']>;
}

export interface G_ProfileVerificationDocumentsNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_ProfileVerificationDocumentsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `ProfileVerificationDocumentsNode` and its cursor. */
export interface G_ProfileVerificationDocumentsNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_ProfileVerificationDocumentsNode>;
}

export interface G_Query {
  _debug?: Maybe<G_DjangoDebug>;
  booking?: Maybe<G_BookingNode>;
  bookingMessages?: Maybe<Array<Maybe<G_BookingMessageNode>>>;
  bookingsAll?: Maybe<G_BookingNodeConnection>;
  bookingsByCustomer?: Maybe<Array<Maybe<G_BookingNode>>>;
  bookingsByExpert?: Maybe<Array<Maybe<G_BookingNode>>>;
  calendar?: Maybe<G_CalendarNode>;
  calendarsAll?: Maybe<G_CalendarNodeConnection>;
  checkExperience?: Maybe<G_CheckExperienceResponse>;
  checkUser?: Maybe<G_CheckUserResponse>;
  citiesAll?: Maybe<G_CityNodeConnection>;
  city?: Maybe<G_CityNode>;
  countriesAll?: Maybe<G_CountryNodeConnection>;
  country?: Maybe<G_CountryNode>;
  experienceBySlug?: Maybe<G_ExperienceBySlugNodeConnection>;
  experiencesAll?: Maybe<G_ExperienceNodeConnection>;
  getAvailabilityHours?: Maybe<Array<Maybe<G_CalendarAvailableHoursNode>>>;
  getCalendarEvents?: Maybe<Array<Maybe<G_CalendarOccurrenceNode>>>;
  getCalendarEventsUpcoming?: Maybe<G_CalendarOccurrenceNodeConnection>;
  getEventsBySpecificDate?: Maybe<G_CalendarOccurrenceNodeConnection>;
  getOccurrence?: Maybe<G_CalendarOccurrenceNode>;
  getPrice?: Maybe<G_ListingItemOptionNode>;
  language?: Maybe<G_LanguageNode>;
  languagesAll?: Maybe<G_LanguageNodeConnection>;
  listing?: Maybe<G_ListingNode>;
  listingByCategory?: Maybe<Array<Maybe<G_ListingNode>>>;
  listingCategories?: Maybe<Array<Maybe<G_ListingCategoryNode>>>;
  listingCategoriesAll?: Maybe<G_ListingCategoryNodeConnection>;
  listingCategory?: Maybe<G_ListingCategoryNode>;
  listingGetAvailableHours?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  listingGetOptions?: Maybe<Array<Maybe<G_ListingItemOptionNode>>>;
  listingItemSession?: Maybe<G_ListingItemSessionNode>;
  listingReviews?: Maybe<G_ListingReviewNodeConnection>;
  listingReviewsByProfile?: Maybe<G_ListingReviewsByProfileNodeConnection>;
  listingTopCategories?: Maybe<Array<Maybe<G_ListingCategoryNode>>>;
  listingUnfiltered?: Maybe<G_ListingNode>;
  listingUpcomingSessions?: Maybe<G_ListingItemSessionNodeConnection>;
  listingsAll?: Maybe<G_ListingNodeConnection>;
  listingsLatest?: Maybe<Array<Maybe<G_ListingNode>>>;
  listingsRandom?: Maybe<Array<Maybe<G_ListingNode>>>;
  profile?: Maybe<G_ProfileNode>;
  profileVerificationDocument?: Maybe<G_ProfileVerificationDocumentsNode>;
  profileVerificationDocumentsAll?: Maybe<G_ProfileVerificationDocumentsNodeConnection>;
  profilesAll?: Maybe<G_ProfileNodeConnection>;
  profilesFeaturedExperts?: Maybe<Array<Maybe<G_ProfileNode>>>;
  profilesMine?: Maybe<Array<Maybe<G_ProfileNode>>>;
  transaction?: Maybe<G_TransactionNode>;
  transactionsAll?: Maybe<G_TransactionNodeConnection>;
}


export interface G_QueryBookingArgs {
  id: Scalars['ID'];
}


export interface G_QueryBookingMessagesArgs {
  profile: Scalars['ID'];
}


export interface G_QueryBookingsAllArgs {
  after?: InputMaybe<Scalars['String']>;
  attendeeProfile?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_QueryBookingsByCustomerArgs {
  profile: Scalars['ID'];
}


export interface G_QueryBookingsByExpertArgs {
  profile: Scalars['ID'];
}


export interface G_QueryCalendarArgs {
  id: Scalars['ID'];
}


export interface G_QueryCalendarsAllArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  title_Icontains?: InputMaybe<Scalars['String']>;
  title_Istartswith?: InputMaybe<Scalars['String']>;
}


export interface G_QueryCheckExperienceArgs {
  referenceCode: Scalars['String'];
}


export interface G_QueryCheckUserArgs {
  login: Scalars['String'];
}


export interface G_QueryCitiesAllArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  name_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_QueryCityArgs {
  id: Scalars['ID'];
}


export interface G_QueryCountriesAllArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isoCode?: InputMaybe<Scalars['String']>;
  isoCode_Icontains?: InputMaybe<Scalars['String']>;
  isoCode_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  name_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_QueryCountryArgs {
  id: Scalars['ID'];
}


export interface G_QueryExperienceBySlugArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
}


export interface G_QueryExperiencesAllArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  referenceCode?: InputMaybe<Scalars['String']>;
  schedule: G_ExperienceScheduleTypes;
}


export interface G_QueryGetAvailabilityHoursArgs {
  calendar: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_QueryGetCalendarEventsArgs {
  month: Scalars['Int'];
  profile: Scalars['ID'];
  year: Scalars['Int'];
}


export interface G_QueryGetCalendarEventsUpcomingArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  profile: Scalars['ID'];
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}


export interface G_QueryGetEventsBySpecificDateArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  profile: Scalars['ID'];
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}


export interface G_QueryGetOccurrenceArgs {
  calendarOccurrence: Scalars['ID'];
  profile: Scalars['ID'];
}


export interface G_QueryGetPriceArgs {
  listingItemOption: Scalars['ID'];
}


export interface G_QueryLanguageArgs {
  id: Scalars['ID'];
}


export interface G_QueryLanguagesAllArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isoCode?: InputMaybe<Scalars['String']>;
  isoCode_Icontains?: InputMaybe<Scalars['String']>;
  isoCode_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  name_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_QueryListingArgs {
  id: Scalars['ID'];
}


export interface G_QueryListingByCategoryArgs {
  categoryId: Scalars['ID'];
}


export interface G_QueryListingCategoriesArgs {
  parentId?: InputMaybe<Scalars['ID']>;
}


export interface G_QueryListingCategoriesAllArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<Scalars['ID']>;
}


export interface G_QueryListingCategoryArgs {
  id: Scalars['ID'];
}


export interface G_QueryListingGetAvailableHoursArgs {
  endDate: Scalars['DateTime'];
  listingItemOption: Scalars['ID'];
  startDate: Scalars['DateTime'];
}


export interface G_QueryListingGetOptionsArgs {
  listingItem: Scalars['ID'];
}


export interface G_QueryListingItemSessionArgs {
  id: Scalars['ID'];
}


export interface G_QueryListingReviewsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  listingId: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
}


export interface G_QueryListingReviewsByProfileArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  profileSlug: Scalars['String'];
  rating?: InputMaybe<Scalars['Int']>;
}


export interface G_QueryListingTopCategoriesArgs {
  parentCategoryId?: InputMaybe<Scalars['ID']>;
}


export interface G_QueryListingUnfilteredArgs {
  id: Scalars['ID'];
}


export interface G_QueryListingUpcomingSessionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endTs_Gt?: InputMaybe<Scalars['Int']>;
  endTs_Gte?: InputMaybe<Scalars['Int']>;
  endTs_Lt?: InputMaybe<Scalars['Int']>;
  endTs_Lte?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locationType: G_ListingLocationFlags;
  offset?: InputMaybe<Scalars['Int']>;
  parentCategoryId?: InputMaybe<Scalars['ID']>;
  startTs_Gt?: InputMaybe<Scalars['Int']>;
  startTs_Gte?: InputMaybe<Scalars['Int']>;
  startTs_Lt?: InputMaybe<Scalars['Int']>;
  startTs_Lte?: InputMaybe<Scalars['Int']>;
}


export interface G_QueryListingsAllArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_Icontains?: InputMaybe<Scalars['String']>;
  title_Istartswith?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
}


export interface G_QueryListingsLatestArgs {
  listingType: G_ListingTypes;
  locationType: G_ListingLocationFlags;
}


export interface G_QueryListingsRandomArgs {
  listingType: G_ListingTypes;
  locationType: G_ListingLocationFlags;
  parentCategoryId?: InputMaybe<Scalars['ID']>;
}


export interface G_QueryProfileArgs {
  id: Scalars['ID'];
}


export interface G_QueryProfileVerificationDocumentArgs {
  id: Scalars['ID'];
}


export interface G_QueryProfileVerificationDocumentsAllArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  mangopayKycDocumentId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}


export interface G_QueryProfilesAllArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
}


export interface G_QueryProfilesFeaturedExpertsArgs {
  parentCategoryId?: InputMaybe<Scalars['ID']>;
}


export interface G_QueryTransactionArgs {
  id: Scalars['ID'];
}


export interface G_QueryTransactionsAllArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  mangopayPayinId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
}

export interface G_Refresh {
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
}

export interface G_RepresentativeInput {
  representativeAddressAddressLine1?: InputMaybe<Scalars['String']>;
  representativeAddressAddressLine2?: InputMaybe<Scalars['String']>;
  representativeAddressCity: Scalars['ID'];
  representativeAddressGeolocation?: InputMaybe<G_GeolocationInput>;
  representativeAddressPostalCode?: InputMaybe<Scalars['String']>;
  representativeAddressTitle: Scalars['String'];
  representativeBirthdate: Scalars['Date'];
  representativeEmail: Scalars['String'];
  representativeFirstName: Scalars['String'];
  representativeLastName: Scalars['String'];
}

export interface G_ResetPasswordConfirmResponse {
  username?: Maybe<Scalars['String']>;
}

export interface G_ResetPasswordResponse {
  username?: Maybe<Scalars['String']>;
}

export interface G_Revoke {
  revoked: Scalars['Int'];
}

export interface G_SignupConfirmResponse {
  username?: Maybe<Scalars['String']>;
}

export interface G_SignupResendResponse {
  username?: Maybe<Scalars['String']>;
}

export interface G_SignupResponse {
  username?: Maybe<Scalars['String']>;
}

/** An enumeration. */
export type G_TransactionAmountCurrency =
  /** Dollar */
  | 'DOLLAR'
  /** Euro */
  | 'EURO';

/** An enumeration. */
export type G_TransactionAmountCurrencyNew =
  /** Euro */
  | 'A_1'
  /** Dollar */
  | 'A_2';

/** An enumeration. */
export type G_TransactionDiscountAmountCurrency =
  /** Dollar */
  | 'DOLLAR'
  /** Euro */
  | 'EURO';

/** An enumeration. */
export type G_TransactionDiscountAmountCurrencyNew =
  /** Euro */
  | 'A_1'
  /** Dollar */
  | 'A_2';

/** An enumeration. */
export type G_TransactionExpertEarningAmountCurrency =
  /** Euro */
  | 'A_1'
  /** Dollar */
  | 'A_2';

export interface G_TransactionNode extends G_Node {
  amount: Scalars['Decimal'];
  amountCurrency: G_TransactionAmountCurrency;
  amountCurrencyNew: G_TransactionAmountCurrencyNew;
  booking?: Maybe<G_BookingNode>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  discountAmount?: Maybe<Scalars['Decimal']>;
  discountAmountCurrency?: Maybe<G_TransactionDiscountAmountCurrency>;
  discountAmountCurrencyNew?: Maybe<G_TransactionDiscountAmountCurrencyNew>;
  expertEarningAmount?: Maybe<Scalars['Decimal']>;
  expertEarningAmountCurrency?: Maybe<G_TransactionExpertEarningAmountCurrency>;
  /** The ID of the object. */
  id: Scalars['ID'];
  listing: G_ListingNode;
  listingItem?: Maybe<G_ListingItemNode>;
  listingItemOption?: Maybe<G_ListingItemOptionNode>;
  mangopayCardId?: Maybe<Scalars['String']>;
  mangopayPayinId?: Maybe<Scalars['String']>;
  mangopayProcessResultCode?: Maybe<Scalars['String']>;
  mangopayProcessResultMessage?: Maybe<Scalars['String']>;
  mangopayProcessStep?: Maybe<Scalars['String']>;
  mangopayTransferId?: Maybe<Scalars['String']>;
  mangopayTransferIdOfDiscount?: Maybe<Scalars['String']>;
  platformEarningAmount?: Maybe<Scalars['Decimal']>;
  platformEarningAmountCurrency?: Maybe<G_TransactionPlatformEarningAmountCurrency>;
  receiverProfile: G_ProfileNode;
  remoteAddr?: Maybe<Scalars['String']>;
  senderProfile?: Maybe<G_ProfileNode>;
  serviceFee: Scalars['Decimal'];
  serviceFeeRatio: Scalars['Decimal'];
  status: G_TransactionStatus;
  statusNew: G_TransactionStatusNew;
  threedsEnabled?: Maybe<Scalars['Boolean']>;
  type: G_TransactionType;
  typeNew: G_TransactionTypeNew;
  updatedAt?: Maybe<Scalars['DateTime']>;
}

export interface G_TransactionNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_TransactionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `TransactionNode` and its cursor. */
export interface G_TransactionNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_TransactionNode>;
}

/** An enumeration. */
export type G_TransactionPlatformEarningAmountCurrency =
  /** Euro */
  | 'A_1'
  /** Dollar */
  | 'A_2';

/** An enumeration. */
export type G_TransactionStatus =
  /** Awaiting Confirmation */
  | 'AWAITING_CONFIRMATION'
  /** Awaiting Transfer */
  | 'AWAITING_TRANSFER'
  /** Canceled */
  | 'CANCELED'
  /** Completed */
  | 'COMPLETED'
  /** Refunded */
  | 'REFUNDED'
  /** Rejected */
  | 'REJECTED';

/** An enumeration. */
export type G_TransactionStatusNew =
  /** Awaiting Confirmation */
  | 'A_101'
  /** Awaiting Transfer */
  | 'A_102'
  /** Completed */
  | 'A_501'
  /** Rejected */
  | 'A_502'
  /** Canceled */
  | 'A_601'
  /** Refunded */
  | 'A_801';

/** An enumeration. */
export type G_TransactionType =
  /** Payment */
  | 'PAYMENT'
  /** Payout */
  | 'PAYOUT'
  /** Transfer */
  | 'TRANSFER';

/** An enumeration. */
export type G_TransactionTypeNew =
  /** Payment */
  | 'A_1'
  /** Payout */
  | 'A_2'
  /** Transfer */
  | 'A_3';

/** An enumeration. */
export type G_UpdateTypes =
  | 'MODIFIED'
  | 'NEW'
  | 'REMOVE';

export interface G_UserNode extends G_Node {
  /** The ID of the object. */
  id: Scalars['ID'];
  profiles: G_ProfileNodeConnection;
}


export interface G_UserNodeProfilesArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
}

export interface G_UserNodeConnection {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<G_UserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: G_PageInfo;
}

/** A Relay edge containing a `UserNode` and its cursor. */
export interface G_UserNodeEdge {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<G_UserNode>;
}

export interface G_Verify {
  payload: Scalars['GenericScalar'];
}

export type G_AllLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type G_AllLanguagesQuery = { languagesAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'id' | 'name' | 'isoCode'>> }>> }> };

export type G_ListingCategoriesAllQueryVariables = Exact<{ [key: string]: never; }>;


export type G_ListingCategoriesAllQuery = { results?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_ListingCategoryNode, 'id' | 'slug' | 'name' | 'nameDe'>
        & { parent?: Maybe<Pick<G_ListingCategoryNode, 'id' | 'slug' | 'name'>> }
      )> }>> }> };

export type G_ListCategoriesQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars['ID']>;
}>;


export type G_ListCategoriesQuery = { listingCategories?: Maybe<Array<Maybe<(
    Pick<G_ListingCategoryNode, 'id' | 'slug' | 'name' | 'nameDe'>
    & { parent?: Maybe<Pick<G_ListingCategoryNode, 'id' | 'slug' | 'name'>> }
  )>>> };

export type G_AllCountriesWithCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type G_AllCountriesWithCitiesQuery = { countriesAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_CountryNode, 'id' | 'name'>
        & { cities: { edges: Array<Maybe<{ node?: Maybe<Pick<G_CityNode, 'id' | 'name'>> }>> } }
      )> }>> }> };

export type G_ProfileListingsForDropdownQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type G_ProfileListingsForDropdownQuery = { profilesAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<{ listings: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingNode, 'id' | 'title' | 'type'>> }>> } }> }>> }> };

export type G_ExpertStripeStatusQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type G_ExpertStripeStatusQuery = { profile?: Maybe<Pick<G_ProfileNode, 'isStripeExpertAccountInitialized' | 'stripeExpertId'>> };

export type G_DashboardStatsQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type G_DashboardStatsQuery = { profile?: Maybe<{ stats?: Maybe<Pick<G_ProfileStats, 'totalClients' | 'totalBookings' | 'totalRevenue' | 'balances'>> }> };

export type G_GetUpcomingsQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type G_GetUpcomingsQuery = { profile?: Maybe<{ listings: { edges: Array<Maybe<{ node?: Maybe<(
          Pick<G_ListingNode, 'hasUpcomingSession'>
          & { upcomingSessions?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
                Pick<G_ListingItemSessionNode, 'startTs' | 'endTs'>
                & { occurrence?: Maybe<(
                  Pick<G_CalendarOccurrenceNode, 'id'>
                  & { event: Pick<G_CalendarEventNode, 'type'>, bookings: { edges: Array<Maybe<{ node?: Maybe<(
                        Pick<G_BookingNode, 'id' | 'participationType'>
                        & { attendeeProfile: Pick<G_ProfileNode, 'slug' | 'firstName' | 'lastName' | 'profilePictureUri'> }
                      )> }>> }, listingItemOption?: Maybe<{ listingItem: (
                      Pick<G_ListingItemNode, 'availableSeats' | 'isAvailableOnline'>
                      & { locations: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LocationNode, 'id'>> }>> }, listing: Pick<G_ListingNode, 'id' | 'slug' | 'title'> }
                    ) }> }
                )> }
              )> }>> }> }
        )> }>> } }> };

export type G_GetTopCategoriesQueryVariables = Exact<{
  parentCategoryId?: InputMaybe<Scalars['ID']>;
}>;


export type G_GetTopCategoriesQuery = { items?: Maybe<Array<Maybe<Pick<G_ListingCategoryNode, 'slug' | 'name' | 'nameDe' | 'imageUri'>>>> };

export type G_GetLatestListingsQueryVariables = Exact<{
  listingType: G_ListingTypes;
  locationType: G_ListingLocationFlags;
}>;


export type G_GetLatestListingsQuery = { items?: Maybe<Array<Maybe<(
    Pick<G_ListingNode, 'slug' | 'title' | 'type' | 'coverImageUri'>
    & { upcomingSessions?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingItemSessionNode, 'startTs'>> }>> }>, profile: Pick<G_ProfileNode, 'firstName' | 'lastName' | 'profilePictureUri'> }
  )>>> };

export type G_GetUpcomingSessionsQueryVariables = Exact<{
  parentCategoryId?: InputMaybe<Scalars['ID']>;
  locationType: G_ListingLocationFlags;
}>;


export type G_GetUpcomingSessionsQuery = { items?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<{ listingItem: { listing: (
            Pick<G_ListingNode, 'slug' | 'title' | 'type' | 'coverImageUri'>
            & { upcomingSessions?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingItemSessionNode, 'startTs'>> }>> }>, profile: Pick<G_ProfileNode, 'firstName' | 'lastName' | 'profilePictureUri'> }
          ) } }> }>> }> };

export type G_ListingsRandomQueryVariables = Exact<{
  parentCategoryId?: InputMaybe<Scalars['ID']>;
  listingType: G_ListingTypes;
  locationType: G_ListingLocationFlags;
}>;


export type G_ListingsRandomQuery = { items?: Maybe<Array<Maybe<(
    Pick<G_ListingNode, 'slug' | 'title' | 'type' | 'coverImageUri'>
    & { upcomingSessions?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingItemSessionNode, 'startTs'>> }>> }>, profile: Pick<G_ProfileNode, 'firstName' | 'lastName' | 'profilePictureUri'> }
  )>>> };

export type G_GetFeaturedExpertsQueryVariables = Exact<{
  parentCategoryId?: InputMaybe<Scalars['ID']>;
}>;


export type G_GetFeaturedExpertsQuery = { items?: Maybe<Array<Maybe<(
    Pick<G_ProfileNode, 'slug' | 'firstName' | 'lastName' | 'profilePictureUri'>
    & { locationCity?: Maybe<Pick<G_CityNode, 'name'>> }
  )>>> };

export type G_ProfileAddressesQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type G_ProfileAddressesQuery = { profilesAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<{ addresses: { edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_LocationNode, 'id' | 'title' | 'addressLine1' | 'postalCode' | 'order'>
              & { city?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, country?: Maybe<Pick<G_CountryNode, 'id' | 'name'>> }
            )> }>> } }> }>> }> };

export type G_ProfileAddressAddMutationVariables = Exact<{
  profile: Scalars['ID'];
  title: Scalars['String'];
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['ID'];
  postalCode?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
}>;


export type G_ProfileAddressAddMutation = { profileAddressAdd?: Maybe<Pick<G_LocationNode, 'id'>> };

export type G_ProfileAddressEditMutationVariables = Exact<{
  profile: Scalars['ID'];
  address: Scalars['ID'];
  title: Scalars['String'];
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['ID'];
  postalCode?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
}>;


export type G_ProfileAddressEditMutation = { profileAddressEdit?: Maybe<Pick<G_LocationNode, 'id'>> };

export type G_ProfileAddressRemoveMutationVariables = Exact<{
  profile: Scalars['ID'];
  address: Scalars['ID'];
}>;


export type G_ProfileAddressRemoveMutation = { profileAddressRemove?: Maybe<Pick<G_LocationNode, 'id'>> };

export type G_UpdateAccountPreferencesStep1MutationVariables = Exact<{
  profile: Scalars['ID'];
  languages?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;


export type G_UpdateAccountPreferencesStep1Mutation = { profileUpdate?: Maybe<{ languages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'id' | 'name'>> }>> } }> };

export type G_ChangePayoutSettingsMutationVariables = Exact<{
  companyInfo?: InputMaybe<G_CompanyInput>;
  companyRepresentativeInfo?: InputMaybe<G_RepresentativeInput>;
  documents?: InputMaybe<Array<InputMaybe<G_DocumentInput>> | InputMaybe<G_DocumentInput>>;
  iban?: InputMaybe<Scalars['String']>;
  paymentPersonType?: InputMaybe<G_AccountTypes>;
  profile: Scalars['ID'];
}>;


export type G_ChangePayoutSettingsMutation = { changePayoutSettings?: Maybe<Pick<G_ProfileNode, 'id'>> };

export type G_GetKycInfoQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type G_GetKycInfoQuery = { profile?: Maybe<(
    Pick<G_ProfileNode, 'firstName' | 'lastName' | 'email' | 'role' | 'birthdate' | 'kycStatus' | 'payoutAccountType' | 'companyName' | 'companyRegistrationNumber' | 'companyAddressAddressLine1' | 'companyAddressPostalCode' | 'companyRepresentativeFirstName' | 'companyRepresentativeLastName' | 'companyRepresentativeBirthdate' | 'companyRepresentativeEmail' | 'companyRepresentativeAddressAddressLine1' | 'companyRepresentativeAddressPostalCode' | 'payoutSellerBankAccountIban'>
    & { companyAddressCity?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, companyAddressCountry?: Maybe<Pick<G_CountryNode, 'id' | 'name'>>, companyRepresentativeAddressCity?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, companyRepresentativeAddressCountry?: Maybe<Pick<G_CountryNode, 'id' | 'name'>>, documents: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ProfileVerificationDocumentsNode, 'mangopayKycDocumentType' | 'mangopayKycDocumentUri' | 'mangopayKycDocumentStatus'>> }>> } }
  )> };

export type G_AccountPersonalInfoQueryVariables = Exact<{
  profileSlug: Scalars['String'];
}>;


export type G_AccountPersonalInfoQuery = { profilesAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<Pick<G_ProfileNode, 'id' | 'slug' | 'firstName' | 'lastName' | 'gender' | 'birthdate' | 'email' | 'phone'>> }>> }> };

export type G_UpdateAccountPersonalInfoStep1MutationVariables = Exact<{
  profile: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
}>;


export type G_UpdateAccountPersonalInfoStep1Mutation = { profileUpdate?: Maybe<Pick<G_ProfileNode, 'slug' | 'firstName' | 'lastName'>> };

export type G_UpdateAccountPersonalInfoStep2MutationVariables = Exact<{
  profile: Scalars['ID'];
  gender?: InputMaybe<Scalars['String']>;
}>;


export type G_UpdateAccountPersonalInfoStep2Mutation = { profileUpdate?: Maybe<Pick<G_ProfileNode, 'gender'>> };

export type G_UpdateAccountPersonalInfoStep3MutationVariables = Exact<{
  profile: Scalars['ID'];
  birthdate?: InputMaybe<Scalars['String']>;
}>;


export type G_UpdateAccountPersonalInfoStep3Mutation = { profileUpdate?: Maybe<Pick<G_ProfileNode, 'birthdate'>> };

export type G_UpdateAccountPersonalInfoStep4MutationVariables = Exact<{
  profile: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
}>;


export type G_UpdateAccountPersonalInfoStep4Mutation = { profileUpdate?: Maybe<Pick<G_ProfileNode, 'email'>> };

export type G_UpdateAccountPersonalInfoStep5MutationVariables = Exact<{
  profile: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
}>;


export type G_UpdateAccountPersonalInfoStep5Mutation = { profileUpdate?: Maybe<Pick<G_ProfileNode, 'phone'>> };

export type G_ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  previousPassword: Scalars['String'];
}>;


export type G_ChangePasswordMutation = { changePassword?: Maybe<Pick<G_ChangePasswordResponse, 'username'>> };

export type G_CheckUserQueryVariables = Exact<{
  login: Scalars['String'];
}>;


export type G_CheckUserQuery = { checkUser?: Maybe<Pick<G_CheckUserResponse, 'username' | 'userExists' | 'status'>> };

export type G_LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type G_LoginMutation = { authToken?: Maybe<(
    Pick<G_ObtainJsonWebToken, 'payload' | 'refreshExpiresIn' | 'token'>
    & { user?: Maybe<(
      Pick<G_UserNode, 'id'>
      & { profiles: { edges: Array<Maybe<{ node?: Maybe<(
            Pick<G_ProfileNode, 'id' | 'slug' | 'role' | 'email' | 'firstName' | 'lastName' | 'timezone' | 'profilePictureUri' | 'serviceFeeRatio' | 'coverPictureUri' | 'bio'>
            & { calendars: { edges: Array<Maybe<{ node?: Maybe<Pick<G_CalendarNode, 'id' | 'title'>> }>> }, locationCity?: Maybe<Pick<G_CityNode, 'id'>>, locationCountry?: Maybe<Pick<G_CountryNode, 'id'>>, languages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'id'>> }>> }, listings: { edges: Array<Maybe<Pick<G_ListingNodeEdge, 'cursor'>>> } }
          )> }>> } }
    )> }
  )> };

export type G_ResetPasswordMutationVariables = Exact<{
  username: Scalars['String'];
}>;


export type G_ResetPasswordMutation = { resetPassword?: Maybe<Pick<G_ResetPasswordResponse, 'username'>> };

export type G_ResetPasswordConfirmMutationVariables = Exact<{
  confirmationCode: Scalars['String'];
  newPassword: Scalars['String'];
  username: Scalars['String'];
}>;


export type G_ResetPasswordConfirmMutation = { resetPasswordConfirm?: Maybe<Pick<G_ResetPasswordConfirmResponse, 'username'>> };

export type G_SignupMutationVariables = Exact<{
  login: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  role: G_ProfileRoles;
}>;


export type G_SignupMutation = { signup?: Maybe<Pick<G_SignupResponse, 'username'>> };

export type G_ResendSignupConfirmationMutationVariables = Exact<{
  username: Scalars['String'];
}>;


export type G_ResendSignupConfirmationMutation = { signupResend?: Maybe<Pick<G_SignupResendResponse, 'username'>> };

export type G_SignupConfirmMutationVariables = Exact<{
  confirmationCode: Scalars['String'];
  username: Scalars['String'];
}>;


export type G_SignupConfirmMutation = { signupConfirm?: Maybe<Pick<G_SignupConfirmResponse, 'username'>> };

export type G_ProfileBecomeExpertMutationVariables = Exact<{
  profile: Scalars['ID'];
}>;


export type G_ProfileBecomeExpertMutation = { profileBecomeExpert?: Maybe<Pick<G_ProfileNode, 'id' | 'role'>> };

export type G_LoginInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type G_LoginInfoQuery = { profilesMine?: Maybe<Array<Maybe<(
    Pick<G_ProfileNode, 'id' | 'email' | 'firstName' | 'lastName' | 'profilePictureUri' | 'role' | 'serviceFeeRatio' | 'slug' | 'timezone'>
    & { calendars: { edges: Array<Maybe<{ node?: Maybe<Pick<G_CalendarNode, 'id' | 'title'>> }>> } }
  )>>> };

export type G_GetAvailabilityHoursQueryVariables = Exact<{
  profile: Scalars['ID'];
  calendar: Scalars['ID'];
}>;


export type G_GetAvailabilityHoursQuery = { getAvailabilityHours?: Maybe<Array<Maybe<Pick<G_CalendarAvailableHoursNode, 'id' | 'weekday' | 'startTime' | 'endTime'>>>> };

export type G_CalendarSetAvailabilityMutationVariables = Exact<{
  availableHours?: InputMaybe<Array<InputMaybe<G_CalendarSetAvailabilityInput>> | InputMaybe<G_CalendarSetAvailabilityInput>>;
  profile: Scalars['ID'];
  calendar: Scalars['ID'];
}>;


export type G_CalendarSetAvailabilityMutation = { calendarSetAvailability?: Maybe<Array<Maybe<Pick<G_CalendarAvailableHoursNode, 'id' | 'weekday' | 'startTime' | 'endTime'>>>> };

export type G_ListingGetAvailableHoursQueryVariables = Exact<{
  listingItemOption: Scalars['ID'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type G_ListingGetAvailableHoursQuery = Pick<G_Query, 'listingGetAvailableHours'>;

export type G_BookMutationVariables = Exact<{
  bookedTime?: InputMaybe<Scalars['DateTime']>;
  cardCvx?: InputMaybe<Scalars['String']>;
  cardExpirationDate?: InputMaybe<Scalars['String']>;
  cardNumber?: InputMaybe<Scalars['String']>;
  cardType?: InputMaybe<Scalars['String']>;
  cardholderName?: InputMaybe<Scalars['String']>;
  couponCode?: InputMaybe<Scalars['String']>;
  listingItemOption: Scalars['ID'];
  listingItemSession?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  profile: Scalars['ID'];
  returnUrl?: InputMaybe<Scalars['String']>;
  acceptHeader?: InputMaybe<Scalars['String']>;
  javaEnabled?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  colorDepth?: InputMaybe<Scalars['Int']>;
  screenHeight?: InputMaybe<Scalars['Int']>;
  screenWidth?: InputMaybe<Scalars['Int']>;
  timezoneOffset?: InputMaybe<Scalars['String']>;
  userAgent?: InputMaybe<Scalars['String']>;
  javascriptEnabled?: InputMaybe<Scalars['Boolean']>;
}>;


export type G_BookMutation = { book?: Maybe<(
    Pick<G_BookResponse, 'transactionId' | 'threedsEnabled' | 'threedsUrl'>
    & { booking?: Maybe<Pick<G_BookingNode, 'id' | 'status'>>, transaction?: Maybe<Pick<G_TransactionNode, 'id' | 'status'>> }
  )> };

export type G_GetTransactionByTransactionIdQueryVariables = Exact<{
  mangopayPayinId: Scalars['String'];
}>;


export type G_GetTransactionByTransactionIdQuery = { transactionsAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_TransactionNode, 'id' | 'type' | 'status' | 'threedsEnabled' | 'amount' | 'amountCurrency'>
        & { booking?: Maybe<(
          Pick<G_BookingNode, 'id' | 'referenceCode' | 'listingTitle'>
          & { listingItemOption: { listingItem: { listing: Pick<G_ListingNode, 'slug'> } } }
        )> }
      )> }>> }> };

export type G_BookingByIdQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type G_BookingByIdQuery = { booking?: Maybe<(
    Pick<G_BookingNode, 'id' | 'createdAt' | 'referenceCode' | 'status' | 'participationType' | 'type' | 'bookedDate' | 'priceTotal' | 'priceFinal' | 'duration' | 'listingTitle' | 'bookedAddressTitle' | 'bookedAddressAddressLine1' | 'bookedAddressAddressLine2' | 'bookedAddressPostalCode' | 'bookedAddressGeolocation' | 'bookedAddressOnlineMeetingLink' | 'bookedAddressOnlineMeetingPin' | 'bookedAddressOnlineMeetingIntroduction' | 'bookingCreationDate'>
    & { attendeeProfile: Pick<G_ProfileNode, 'id' | 'slug' | 'firstName' | 'lastName' | 'email' | 'profilePictureUri'>, listingItemOption: { listingItem: { listing: (
          Pick<G_ListingNode, 'id' | 'description' | 'coverImageUri'>
          & { category: Pick<G_ListingCategoryNode, 'name' | 'nameDe'>, profile: Pick<G_ProfileNode, 'id' | 'slug' | 'firstName' | 'lastName' | 'email' | 'profilePictureUri'>, reviews: { edges: Array<Maybe<{ node?: Maybe<(
                Pick<G_ListingReviewsByProfileNode, 'createdAt' | 'rating' | 'content'>
                & { profile: Pick<G_ProfileNode, 'id'> }
              )> }>> } }
        ) } }, discountDetails?: Maybe<Array<Maybe<Pick<G_DiscountDetails, 'title' | 'description' | 'couponCode'>>>>, bookedAddressCity?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, bookedAddressCountry?: Maybe<Pick<G_CountryNode, 'id' | 'name'>>, messages: { edges: Array<Maybe<{ node?: Maybe<(
          Pick<G_BookingMessageNode, 'id' | 'type' | 'date' | 'body'>
          & { fromProfile: Pick<G_ProfileNode, 'id'>, toProfile: Pick<G_ProfileNode, 'id'> }
        )> }>> } }
  )> };

export type G_BookingsByExpertQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type G_BookingsByExpertQuery = { bookingsByExpert?: Maybe<Array<Maybe<(
    Pick<G_BookingNode, 'id' | 'createdAt' | 'referenceCode' | 'status' | 'participationType' | 'type' | 'bookedDate' | 'priceTotal' | 'priceFinal' | 'duration' | 'listingTitle' | 'bookingCreationDate'>
    & { attendeeProfile: Pick<G_ProfileNode, 'id' | 'slug' | 'firstName' | 'lastName' | 'profilePictureUri'> }
  )>>> };

export type G_BookingsByProfileQueryVariables = Exact<{
  attendeeProfileId: Scalars['ID'];
}>;


export type G_BookingsByProfileQuery = { bookingsAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_BookingNode, 'id' | 'createdAt' | 'referenceCode' | 'status' | 'participationType' | 'type' | 'bookedDate' | 'priceTotal' | 'priceFinal' | 'duration' | 'listingTitle' | 'bookedAddressTitle' | 'bookedAddressAddressLine1' | 'bookedAddressAddressLine2' | 'bookedAddressPostalCode' | 'bookedAddressGeolocation' | 'bookedAddressOnlineMeetingLink' | 'bookedAddressOnlineMeetingPin' | 'bookedAddressOnlineMeetingIntroduction' | 'bookingCreationDate'>
        & { attendeeProfile: Pick<G_ProfileNode, 'id' | 'firstName' | 'lastName' | 'profilePictureUri'>, listingItemOption: { listingItem: { listing: (
              Pick<G_ListingNode, 'coverImageUri'>
              & { profile: Pick<G_ProfileNode, 'id' | 'slug' | 'firstName' | 'lastName' | 'profilePictureUri'> }
            ) } }, bookedAddressCity?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, bookedAddressCountry?: Maybe<Pick<G_CountryNode, 'id' | 'name'>>, messages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_BookingMessageNode, 'id' | 'type' | 'date' | 'body'>> }>> } }
      )> }>> }> };

export type G_BookingMessageAddMutationVariables = Exact<{
  body: Scalars['String'];
  booking: Scalars['ID'];
  fromProfile: Scalars['ID'];
  type: G_BookMessageTypes;
}>;


export type G_BookingMessageAddMutation = { bookingMessageAdd?: Maybe<Pick<G_BookingMessageNode, 'id' | 'date' | 'body'>> };

export type G_BookingCalculatePriceMutationVariables = Exact<{
  couponCode?: InputMaybe<Scalars['String']>;
  listingItemOption: Scalars['ID'];
}>;


export type G_BookingCalculatePriceMutation = { bookingCalculatePrice?: Maybe<(
    Pick<G_BookingCalculatePriceResponse, 'appliedCouponCodes' | 'priceTotal' | 'priceFinal' | 'paymentRequired'>
    & { messages?: Maybe<Array<Maybe<Pick<G_CalculationMessage, 'message'>>>>, details?: Maybe<Array<Maybe<Pick<G_CalculationDetail, 'title' | 'description' | 'amount' | 'effect'>>>> }
  )> };

export type G_StripeCheckoutSessionMutationVariables = Exact<{
  profile_id: Scalars['ID'];
  listing_item_option_id: Scalars['ID'];
  listing_item_session_id?: InputMaybe<Scalars['ID']>;
  coupon_code?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['ID']>;
  booked_time?: InputMaybe<Scalars['DateTime']>;
  success_url: Scalars['String'];
  cancel_url: Scalars['String'];
  browser_info?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
}>;


export type G_StripeCheckoutSessionMutation = { checkoutSession?: Maybe<Pick<G_CheckoutSessionResponse, 'clientSecret'>> };

export type G_StripeCreateAccountLinkMutationVariables = Exact<{
  profile_id: Scalars['ID'];
}>;


export type G_StripeCreateAccountLinkMutation = { createAccountLink?: Maybe<Pick<G_AccountLinkResponse, 'oneTimeLink'>> };

export type G_ExperiencesRegisterMutationVariables = Exact<{
  referenceCode: Scalars['String'];
}>;


export type G_ExperiencesRegisterMutation = { experiencesRegister?: Maybe<Pick<G_ExperiencesRegistrationNode, 'referenceCode'>> };

export type G_CheckExperienceQueryVariables = Exact<{
  referenceCode: Scalars['String'];
}>;


export type G_CheckExperienceQuery = { result?: Maybe<Pick<G_CheckExperienceResponse, 'userRegistered'>> };

export type G_ExperiencesForCarouselQueryVariables = Exact<{ [key: string]: never; }>;


export type G_ExperiencesForCarouselQuery = { result?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_ExperienceNode, 'id' | 'slug' | 'title' | 'coverUri' | 'price' | 'discount' | 'paid' | 'hostedBySingleExperts'>
        & { sessions: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ExperienceSessionNode, 'title' | 'startsAt' | 'endsAt' | 'isAvailableOnline'>> }>> } }
      )> }>> }> };

export type G_ExperiencesPastQueryVariables = Exact<{ [key: string]: never; }>;


export type G_ExperiencesPastQuery = { result?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<Pick<G_ExperienceNode, 'id' | 'slug' | 'title' | 'coverUri' | 'price' | 'discount' | 'paid' | 'endsAt'>> }>> }> };

export type G_ExperiencesFutureQueryVariables = Exact<{ [key: string]: never; }>;


export type G_ExperiencesFutureQuery = { result?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_ExperienceNode, 'id' | 'referenceCode' | 'title' | 'slug' | 'description' | 'coverUri' | 'published' | 'paid' | 'price' | 'discount' | 'hostedBySingleExperts'>
        & { sessions: { edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_ExperienceSessionNode, 'id' | 'title' | 'description' | 'startsAt' | 'endsAt' | 'isAvailableOnline' | 'onlineMeetingLink' | 'onlineMeetingPin' | 'onlineMeetingIntroduction'>
              & { category: Pick<G_ListingCategoryNode, 'name' | 'nameDe'>, profile: (
                Pick<G_ProfileNode, 'slug' | 'firstName' | 'lastName' | 'profilePictureUri'>
                & { locationCity?: Maybe<Pick<G_CityNode, 'name'>>, locationCountry?: Maybe<Pick<G_CountryNode, 'name'>> }
              ) }
            )> }>> } }
      )> }>> }> };

export type G_ExperiencesOnDemandQueryVariables = Exact<{ [key: string]: never; }>;


export type G_ExperiencesOnDemandQuery = { result?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_ExperienceNode, 'id' | 'referenceCode' | 'title' | 'slug' | 'description' | 'coverUri' | 'published' | 'paid' | 'price' | 'discount' | 'hostedBySingleExperts'>
        & { sessions: { edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_ExperienceSessionNode, 'id' | 'title' | 'description' | 'startsAt' | 'endsAt' | 'isAvailableOnline' | 'onlineMeetingLink' | 'onlineMeetingPin' | 'onlineMeetingIntroduction'>
              & { category: Pick<G_ListingCategoryNode, 'name' | 'nameDe'>, profile: (
                Pick<G_ProfileNode, 'slug' | 'firstName' | 'lastName' | 'profilePictureUri'>
                & { locationCity?: Maybe<Pick<G_CityNode, 'name'>>, locationCountry?: Maybe<Pick<G_CountryNode, 'name'>> }
              ) }
            )> }>> } }
      )> }>> }> };

export type G_ExperienceBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type G_ExperienceBySlugQuery = { result?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_ExperienceBySlugNode, 'id' | 'referenceCode' | 'title' | 'slug' | 'description' | 'coverUri' | 'published' | 'paid' | 'price' | 'discount' | 'hostedBySingleExperts' | 'isPast'>
        & { sessions: { edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_ExperienceSessionNode, 'id' | 'title' | 'description' | 'startsAt' | 'endsAt' | 'isAvailableOnline' | 'onlineMeetingLink' | 'onlineMeetingPin' | 'onlineMeetingIntroduction'>
              & { category: Pick<G_ListingCategoryNode, 'name' | 'nameDe'>, profile: (
                Pick<G_ProfileNode, 'slug' | 'firstName' | 'lastName' | 'profilePictureUri'>
                & { locationCity?: Maybe<Pick<G_CityNode, 'name'>>, locationCountry?: Maybe<Pick<G_CountryNode, 'name'>> }
              ) }
            )> }>> } }
      )> }>> }> };

export type G_PublicProfileBySlugQueryVariables = Exact<{
  profileSlug: Scalars['String'];
}>;


export type G_PublicProfileBySlugQuery = { profilesAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_ProfileNode, 'id' | 'firstName' | 'lastName' | 'rating' | 'role' | 'profilePictureUri' | 'coverPictureUri' | 'createdAt' | 'birthdate' | 'bio' | 'hasAvailability' | 'serviceFeeRatio' | 'kycStatus'>
        & { locationCity?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, locationCountry?: Maybe<Pick<G_CountryNode, 'id' | 'name'>>, languages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'id' | 'name' | 'isoCode'>> }>> }, listings: { edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_ListingNode, 'id' | 'slug' | 'title' | 'description' | 'type' | 'status'>
              & { items: { edges: Array<Maybe<{ node?: Maybe<(
                    Pick<G_ListingItemNode, 'isAvailableOnline' | 'availableSeats'>
                    & { locations: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LocationNode, 'id'>> }>> } }
                  )> }>> }, media: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingMediaNode, 'title' | 'uri'>> }>> }, upcomingSessions?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<{ occurrence?: Maybe<{ listingItemOption?: Maybe<{ listingItem: (
                          Pick<G_ListingItemNode, 'isAvailableOnline'>
                          & { locations: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LocationNode, 'id'>> }>> } }
                        ) }> }> }> }>> }> }
            )> }>> } }
      )> }>> }> };

export type G_UpdatePublicProfileMutationVariables = Exact<{
  profile: Scalars['ID'];
  bio?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  locationCity?: InputMaybe<Scalars['ID']>;
  coverPictureUri?: InputMaybe<Scalars['String']>;
  coverPictureAssetId?: InputMaybe<Scalars['String']>;
}>;


export type G_UpdatePublicProfileMutation = { profileUpdate?: Maybe<(
    Pick<G_ProfileNode, 'bio' | 'coverPictureUri'>
    & { locationCity?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, locationCountry?: Maybe<Pick<G_CountryNode, 'id' | 'name'>>, languages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'id' | 'isoCode' | 'name'>> }>> } }
  )> };

export type G_UpdateAvatarMutationVariables = Exact<{
  profileId: Scalars['ID'];
  profilePictureUri?: InputMaybe<Scalars['String']>;
  profilePictureAssetId?: InputMaybe<Scalars['String']>;
}>;


export type G_UpdateAvatarMutation = { profileUpdate?: Maybe<Pick<G_ProfileNode, 'profilePictureUri'>> };

export type G_GetStartedCheckListQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type G_GetStartedCheckListQuery = { profile?: Maybe<(
    Pick<G_ProfileNode, 'profilePictureUri' | 'coverPictureUri' | 'bio' | 'kycStatus' | 'isStripeExpertAccountInitialized'>
    & { locationCity?: Maybe<Pick<G_CityNode, 'id'>>, locationCountry?: Maybe<Pick<G_CountryNode, 'id'>>, languages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'id'>> }>> }, listings: { edges: Array<Maybe<Pick<G_ListingNodeEdge, 'cursor'>>> } }
  )> };

export type G_GetProfileStatusInfoQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type G_GetProfileStatusInfoQuery = { profile?: Maybe<Pick<G_ProfileNode, 'hasAvailability' | 'serviceFeeRatio' | 'kycStatus'>> };

export type G_AddCustomEventMutationVariables = Exact<{
  profileId: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
}>;


export type G_AddCustomEventMutation = { calendarCustomEventAdd?: Maybe<Pick<G_CalendarEventNode, 'id' | 'type'>> };

export type G_UpdateCustomEventMutationVariables = Exact<{
  profileId: Scalars['ID'];
  calendarEventId: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
}>;


export type G_UpdateCustomEventMutation = { calendarCustomEventUpdate?: Maybe<Pick<G_CalendarEventNode, 'id' | 'type'>> };

export type G_RemoveCustomEventMutationVariables = Exact<{
  profileId: Scalars['ID'];
  calendarEventId: Scalars['ID'];
}>;


export type G_RemoveCustomEventMutation = { calendarCustomEventRemove?: Maybe<Pick<G_CalendarEventNode, 'id'>> };

export type G_CalendarOccurrenceRemoveMutationVariables = Exact<{
  profileId: Scalars['ID'];
  calendarOccurrenceId: Scalars['ID'];
}>;


export type G_CalendarOccurrenceRemoveMutation = { calendarOccurrenceRemove?: Maybe<Pick<G_CalendarOccurrenceNode, 'id'>> };

export type G_GetOccurrenceQueryVariables = Exact<{
  profileId: Scalars['ID'];
  calendarOccurrenceId: Scalars['ID'];
}>;


export type G_GetOccurrenceQuery = { getOccurrence?: Maybe<(
    Pick<G_CalendarOccurrenceNode, 'id' | 'startTs' | 'endTs' | 'rrulestr'>
    & { event: Pick<G_CalendarEventNode, 'id' | 'title' | 'description' | 'type'>, listingItemOption?: Maybe<(
      Pick<G_ListingItemOptionNode, 'price'>
      & { listingItem: (
        Pick<G_ListingItemNode, 'availableSeats' | 'isAvailableOnline' | 'onlineMeetingLink' | 'onlineMeetingPin' | 'onlineMeetingIntroduction'>
        & { locations: { edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_LocationNode, 'id' | 'title' | 'addressLine1' | 'postalCode'>
              & { city?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, country?: Maybe<Pick<G_CountryNode, 'id' | 'name'>> }
            )> }>> } }
      ) }
    )>, bookings: { edges: Array<Maybe<{ node?: Maybe<(
          Pick<G_BookingNode, 'id' | 'participationType' | 'priceTotal'>
          & { attendeeProfile: Pick<G_ProfileNode, 'slug' | 'firstName' | 'lastName' | 'profilePictureUri'> }
        )> }>> } }
  )> };

export type G_GetCalendarEventsQueryVariables = Exact<{
  profileId: Scalars['ID'];
  month: Scalars['Int'];
  year: Scalars['Int'];
}>;


export type G_GetCalendarEventsQuery = { getCalendarEvents?: Maybe<Array<Maybe<(
    Pick<G_CalendarOccurrenceNode, 'id' | 'startTs' | 'endTs' | 'rrulestr'>
    & { event: Pick<G_CalendarEventNode, 'id' | 'type' | 'title'>, bookings: { edges: Array<Maybe<{ node?: Maybe<(
          Pick<G_BookingNode, 'id' | 'participationType'>
          & { attendeeProfile: Pick<G_ProfileNode, 'slug' | 'firstName' | 'lastName' | 'profilePictureUri'> }
        )> }>> }, listingItemOption?: Maybe<{ listingItem: (
        Pick<G_ListingItemNode, 'availableSeats' | 'isAvailableOnline'>
        & { locations: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LocationNode, 'id'>> }>> } }
      ) }> }
  )>>> };

export type G_GetEventsBySpecificDateQueryVariables = Exact<{
  profileId: Scalars['ID'];
  startTime_Gte: Scalars['Int'];
  endTime_Lt: Scalars['Int'];
}>;


export type G_GetEventsBySpecificDateQuery = { getEventsBySpecificDate?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_CalendarOccurrenceNode, 'id' | 'startTs' | 'endTs'>
        & { event: Pick<G_CalendarEventNode, 'id' | 'type' | 'title'> }
      )> }>> }> };

export type G_ListingItemSessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type G_ListingItemSessionQuery = { listingItemSession?: Maybe<(
    Pick<G_ListingItemSessionNode, 'id' | 'startTs' | 'endTs'>
    & { listingItem: (
      Pick<G_ListingItemNode, 'isAvailableOnline' | 'onlineMeetingLink' | 'onlineMeetingPin' | 'onlineMeetingIntroduction' | 'availableSeats'>
      & { locations: { edges: Array<Maybe<{ node?: Maybe<(
            Pick<G_LocationNode, 'id' | 'title' | 'addressLine1' | 'addressLine2' | 'postalCode' | 'geolocation' | 'order'>
            & { city?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, country?: Maybe<Pick<G_CountryNode, 'id' | 'name'>> }
          )> }>> }, options: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingItemOptionNode, 'id' | 'duration' | 'price' | 'priceCurrency' | 'order'>> }>> } }
    ) }
  )> };

export type G_GetListingInfoQueryVariables = Exact<{
  listingSlug: Scalars['String'];
}>;


export type G_GetListingInfoQuery = { listingsAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_ListingNode, 'id' | 'slug' | 'type' | 'status' | 'title' | 'description' | 'rating' | 'coverImageUri' | 'createdAt' | 'updatedAt'>
        & { primaryLanguage: Pick<G_LanguageNode, 'name'>, languages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'name'>> }>> }, _geoloc?: Maybe<Array<Maybe<Pick<G_GeolocationPoint, 'lat' | 'lng'>>>>, category: (
          Pick<G_ListingCategoryNode, 'id' | 'name' | 'nameDe'>
          & { parent?: Maybe<Pick<G_ListingCategoryNode, 'id' | 'name'>> }
        ), profile: (
          Pick<G_ProfileNode, 'id' | 'slug' | 'firstName' | 'lastName' | 'profilePictureUri' | 'bio' | 'createdAt' | 'kycStatus' | 'hasAvailability'>
          & { locationCity?: Maybe<Pick<G_CityNode, 'name'>>, languages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'name' | 'isoCode'>> }>> } }
        ), media: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingMediaNode, 'id' | 'type' | 'title' | 'uri' | 'uriThumbnail' | 'cloudinaryAssetId' | 'order'>> }>> }, items: { edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_ListingItemNode, 'id' | 'isAvailableOnline' | 'availableSeats'>
              & { locations: { edges: Array<Maybe<{ node?: Maybe<(
                    Pick<G_LocationNode, 'id' | 'title' | 'addressLine1' | 'postalCode'>
                    & { city?: Maybe<Pick<G_CityNode, 'name'>>, country?: Maybe<Pick<G_CountryNode, 'name'>> }
                  )> }>> }, options: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingItemOptionNode, 'id' | 'price' | 'priceCurrency' | 'duration'>> }>> } }
            )> }>> }, upcomingSessions?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_ListingItemSessionNode, 'id' | 'startTs' | 'endTs' | 'bookedSeats'>
              & { occurrence?: Maybe<{ listingItemOption?: Maybe<(
                  Pick<G_ListingItemOptionNode, 'price' | 'priceCurrency' | 'duration'>
                  & { listingItem: (
                    Pick<G_ListingItemNode, 'id' | 'isAvailableOnline' | 'availableSeats'>
                    & { locations: { edges: Array<Maybe<{ node?: Maybe<(
                          Pick<G_LocationNode, 'id' | 'title' | 'addressLine1' | 'postalCode'>
                          & { city?: Maybe<Pick<G_CityNode, 'name'>>, country?: Maybe<Pick<G_CountryNode, 'name'>> }
                        )> }>> } }
                  ) }
                )> }> }
            )> }>> }> }
      )> }>> }> };

export type G_ListingBySlugQueryVariables = Exact<{
  listingSlug: Scalars['String'];
}>;


export type G_ListingBySlugQuery = { listingsAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_ListingNode, 'id' | 'slug' | 'type' | 'title' | 'description' | 'rating' | 'coverImageUri' | 'createdAt' | 'updatedAt'>
        & { primaryLanguage: Pick<G_LanguageNode, 'name'>, languages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'name'>> }>> }, _geoloc?: Maybe<Array<Maybe<Pick<G_GeolocationPoint, 'lat' | 'lng'>>>>, category: (
          Pick<G_ListingCategoryNode, 'id' | 'name' | 'nameDe'>
          & { parent?: Maybe<Pick<G_ListingCategoryNode, 'id' | 'name'>> }
        ), profile: (
          Pick<G_ProfileNode, 'id' | 'slug' | 'firstName' | 'lastName' | 'profilePictureUri' | 'bio' | 'createdAt' | 'kycStatus' | 'hasAvailability'>
          & { locationCity?: Maybe<Pick<G_CityNode, 'name'>>, languages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'name'>> }>> } }
        ), items: { edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_ListingItemNode, 'id' | 'isAvailableOnline' | 'availableSeats' | 'order'>
              & { locations: { edges: Array<Maybe<{ node?: Maybe<(
                    Pick<G_LocationNode, 'id' | 'title' | 'addressLine1' | 'addressLine2' | 'postalCode' | 'geolocation' | 'order'>
                    & { city?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, country?: Maybe<Pick<G_CountryNode, 'id' | 'name'>> }
                  )> }>> }, options: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingItemOptionNode, 'id' | 'duration' | 'price' | 'priceCurrency' | 'order'>> }>> } }
            )> }>> }, media: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingMediaNode, 'id' | 'type' | 'title' | 'uri' | 'uriThumbnail' | 'cloudinaryAssetId' | 'order'>> }>> }, upcomingSessions?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_ListingItemSessionNode, 'id' | 'startTs' | 'endTs' | 'bookedSeats'>
              & { occurrence?: Maybe<{ listingItemOption?: Maybe<Pick<G_ListingItemOptionNode, 'price'>> }>, listingItem: (
                Pick<G_ListingItemNode, 'id' | 'isAvailableOnline' | 'availableSeats'>
                & { locations: { edges: Array<Maybe<{ node?: Maybe<(
                      Pick<G_LocationNode, 'title' | 'addressLine1' | 'postalCode'>
                      & { city?: Maybe<Pick<G_CityNode, 'name'>>, country?: Maybe<Pick<G_CountryNode, 'name'>> }
                    )> }>> }, options: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingItemOptionNode, 'price' | 'priceCurrency' | 'duration'>> }>> } }
              ) }
            )> }>> }> }
      )> }>> }> };

export type G_GetListingReviewsByProfileQueryVariables = Exact<{
  profileSlug: Scalars['String'];
}>;


export type G_GetListingReviewsByProfileQuery = { listingReviewsByProfile?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_ListingReviewsByProfileNode, 'createdAt' | 'rating' | 'content'>
        & { profile: Pick<G_ProfileNode, 'firstName'> }
      )> }>> }> };

export type G_ListingAddAsAppointmentMutationVariables = Exact<{
  profile: Scalars['ID'];
  category: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  status: G_ListingStatuses;
  languagePrimary: Scalars['ID'];
  languageOthers?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  items?: InputMaybe<Array<InputMaybe<G_ListingItemInput>> | InputMaybe<G_ListingItemInput>>;
  media?: InputMaybe<Array<InputMaybe<G_ListingMediaInput>> | InputMaybe<G_ListingMediaInput>>;
}>;


export type G_ListingAddAsAppointmentMutation = { listingAdd?: Maybe<Pick<G_ListingNode, 'id'>> };

export type G_ListingUpdateAsAppointmentMutationVariables = Exact<{
  listing: Scalars['ID'];
  profile: Scalars['ID'];
  category: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  status: G_ListingStatuses;
  languagePrimary: Scalars['ID'];
  languageOthers?: InputMaybe<Array<InputMaybe<G_ListingUpdateLanguageInput>> | InputMaybe<G_ListingUpdateLanguageInput>>;
  items?: InputMaybe<Array<InputMaybe<G_ListingUpdateItemInput>> | InputMaybe<G_ListingUpdateItemInput>>;
  media?: InputMaybe<Array<InputMaybe<G_ListingMediaUpdateInput>> | InputMaybe<G_ListingMediaUpdateInput>>;
}>;


export type G_ListingUpdateAsAppointmentMutation = { listingUpdate?: Maybe<Pick<G_ListingNode, 'id'>> };

export type G_ListingAddAsClassMutationVariables = Exact<{
  profile: Scalars['ID'];
  category: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  languagePrimary: Scalars['ID'];
  languageOthers?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  status: G_ListingStatuses;
  media?: InputMaybe<Array<InputMaybe<G_ListingMediaInput>> | InputMaybe<G_ListingMediaInput>>;
}>;


export type G_ListingAddAsClassMutation = { listingAddAsClass?: Maybe<Pick<G_ListingNode, 'id'>> };

export type G_ListingUpdateAsClassMutationVariables = Exact<{
  listing: Scalars['ID'];
  profile: Scalars['ID'];
  category: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  status: G_ListingStatuses;
  languagePrimary: Scalars['ID'];
  languageOthers?: InputMaybe<Array<InputMaybe<G_ListingUpdateLanguageInput>> | InputMaybe<G_ListingUpdateLanguageInput>>;
  media?: InputMaybe<Array<InputMaybe<G_ListingMediaUpdateInput>> | InputMaybe<G_ListingMediaUpdateInput>>;
}>;


export type G_ListingUpdateAsClassMutation = { listingUpdateAsClass?: Maybe<Pick<G_ListingNode, 'id'>> };

export type G_AddClassSessionMutationVariables = Exact<{
  profile: Scalars['ID'];
  listing: Scalars['ID'];
  isAvailableOnline: Scalars['Boolean'];
  onlineMeetingLink?: InputMaybe<Scalars['String']>;
  onlineMeetingPin?: InputMaybe<Scalars['String']>;
  onlineMeetingIntroduction?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  availableSeats?: InputMaybe<Scalars['Int']>;
  duration: Scalars['Int'];
  price: Scalars['Int'];
  rrule?: InputMaybe<Scalars['String']>;
}>;


export type G_AddClassSessionMutation = { classSessions_Add?: Maybe<Pick<G_ListingNode, 'id'>> };

export type G_ListingByIdForEditQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type G_ListingByIdForEditQuery = { listing?: Maybe<(
    Pick<G_ListingNode, 'id' | 'slug' | 'status' | 'type' | 'title' | 'description'>
    & { category: (
      Pick<G_ListingCategoryNode, 'id'>
      & { parent?: Maybe<Pick<G_ListingCategoryNode, 'id'>> }
    ), primaryLanguage: Pick<G_LanguageNode, 'id'>, languages: { edges: Array<Maybe<{ node?: Maybe<Pick<G_LanguageNode, 'id'>> }>> }, profile: Pick<G_ProfileNode, 'id'>, items: { edges: Array<Maybe<{ node?: Maybe<(
          Pick<G_ListingItemNode, 'id' | 'isAvailableOnline' | 'onlineMeetingLink' | 'onlineMeetingPin' | 'onlineMeetingIntroduction' | 'availableSeats' | 'order'>
          & { locations: { edges: Array<Maybe<{ node?: Maybe<(
                Pick<G_LocationNode, 'id' | 'title' | 'addressLine1' | 'addressLine2' | 'postalCode' | 'order'>
                & { city?: Maybe<Pick<G_CityNode, 'id' | 'name'>>, country?: Maybe<Pick<G_CountryNode, 'id' | 'name'>> }
              )> }>> }, options: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingItemOptionNode, 'id' | 'duration' | 'price' | 'priceCurrency' | 'order'>> }>> } }
        )> }>> }, media: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingMediaNode, 'id' | 'type' | 'title' | 'uri' | 'uriThumbnail' | 'cloudinaryAssetId' | 'order'>> }>> } }
  )> };

export type G_ListingStatusUpdateMutationVariables = Exact<{
  listingId: Scalars['ID'];
  profileId: Scalars['ID'];
  status?: InputMaybe<G_ListingStatuses>;
}>;


export type G_ListingStatusUpdateMutation = (
  { __typename: 'Mutation' }
  & { listingUpdate?: Maybe<Pick<G_ListingNode, 'id' | 'status'>> }
);

export type G_ListingRemoveMutationVariables = Exact<{
  listingId: Scalars['ID'];
  profileId: Scalars['ID'];
}>;


export type G_ListingRemoveMutation = { listingRemove?: Maybe<{ __typename: 'ListingNode' }> };

export type G_ListingAddReviewMutationVariables = Exact<{
  listingId: Scalars['ID'];
  profileId: Scalars['ID'];
  bookingId: Scalars['ID'];
  content?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
}>;


export type G_ListingAddReviewMutation = { listingAddReview?: Maybe<Pick<G_ListingReviewNode, 'id'>> };

export type G_ListingsByProfileQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type G_ListingsByProfileQuery = { listingsAll?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<G_ListingNode, 'id' | 'slug' | 'title' | 'description' | 'type' | 'status' | 'hasUpcomingSession'>
        & { category: (
          Pick<G_ListingCategoryNode, 'id' | 'slug' | 'name' | 'nameDe'>
          & { parent?: Maybe<Pick<G_ListingCategoryNode, 'id' | 'slug' | 'name'>> }
        ), items: { edges: Array<Maybe<{ node?: Maybe<(
              Pick<G_ListingItemNode, 'isAvailableOnline' | 'availableSeats'>
              & { locations: { edges: Array<Maybe<{ node?: Maybe<{ city?: Maybe<Pick<G_CityNode, 'id'>> }> }>> } }
            )> }>> }, media: { edges: Array<Maybe<{ node?: Maybe<Pick<G_ListingMediaNode, 'title' | 'uri' | 'uriThumbnail' | 'order'>> }>> } }
      )> }>> }> };


export const AllLanguagesDocument = gql`
    query allLanguages {
  languagesAll {
    edges {
      node {
        id
        name
        isoCode
      }
    }
  }
}
    `;

/**
 * __useAllLanguagesQuery__
 *
 * To run a query within a React component, call `useAllLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<G_AllLanguagesQuery, G_AllLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_AllLanguagesQuery, G_AllLanguagesQueryVariables>(AllLanguagesDocument, options);
      }
export function useAllLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_AllLanguagesQuery, G_AllLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_AllLanguagesQuery, G_AllLanguagesQueryVariables>(AllLanguagesDocument, options);
        }
export type AllLanguagesQueryHookResult = ReturnType<typeof useAllLanguagesQuery>;
export type AllLanguagesLazyQueryHookResult = ReturnType<typeof useAllLanguagesLazyQuery>;
export type AllLanguagesQueryResult = Apollo.QueryResult<G_AllLanguagesQuery, G_AllLanguagesQueryVariables>;
export function refetchAllLanguagesQuery(variables?: G_AllLanguagesQueryVariables) {
      return { query: AllLanguagesDocument, variables: variables }
    }
export const ListingCategoriesAllDocument = gql`
    query listingCategoriesAll {
  results: listingCategoriesAll {
    edges {
      node {
        id
        slug
        name
        nameDe
        parent {
          id
          slug
          name
        }
      }
    }
  }
}
    `;

/**
 * __useListingCategoriesAllQuery__
 *
 * To run a query within a React component, call `useListingCategoriesAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingCategoriesAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingCategoriesAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useListingCategoriesAllQuery(baseOptions?: Apollo.QueryHookOptions<G_ListingCategoriesAllQuery, G_ListingCategoriesAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ListingCategoriesAllQuery, G_ListingCategoriesAllQueryVariables>(ListingCategoriesAllDocument, options);
      }
export function useListingCategoriesAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ListingCategoriesAllQuery, G_ListingCategoriesAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ListingCategoriesAllQuery, G_ListingCategoriesAllQueryVariables>(ListingCategoriesAllDocument, options);
        }
export type ListingCategoriesAllQueryHookResult = ReturnType<typeof useListingCategoriesAllQuery>;
export type ListingCategoriesAllLazyQueryHookResult = ReturnType<typeof useListingCategoriesAllLazyQuery>;
export type ListingCategoriesAllQueryResult = Apollo.QueryResult<G_ListingCategoriesAllQuery, G_ListingCategoriesAllQueryVariables>;
export function refetchListingCategoriesAllQuery(variables?: G_ListingCategoriesAllQueryVariables) {
      return { query: ListingCategoriesAllDocument, variables: variables }
    }
export const ListCategoriesDocument = gql`
    query listCategories($parentId: ID) {
  listingCategories(parentId: $parentId) {
    id
    slug
    name
    nameDe
    parent {
      id
      slug
      name
    }
  }
}
    `;

/**
 * __useListCategoriesQuery__
 *
 * To run a query within a React component, call `useListCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCategoriesQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useListCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<G_ListCategoriesQuery, G_ListCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ListCategoriesQuery, G_ListCategoriesQueryVariables>(ListCategoriesDocument, options);
      }
export function useListCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ListCategoriesQuery, G_ListCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ListCategoriesQuery, G_ListCategoriesQueryVariables>(ListCategoriesDocument, options);
        }
export type ListCategoriesQueryHookResult = ReturnType<typeof useListCategoriesQuery>;
export type ListCategoriesLazyQueryHookResult = ReturnType<typeof useListCategoriesLazyQuery>;
export type ListCategoriesQueryResult = Apollo.QueryResult<G_ListCategoriesQuery, G_ListCategoriesQueryVariables>;
export function refetchListCategoriesQuery(variables?: G_ListCategoriesQueryVariables) {
      return { query: ListCategoriesDocument, variables: variables }
    }
export const AllCountriesWithCitiesDocument = gql`
    query allCountriesWithCities {
  countriesAll {
    edges {
      node {
        id
        name
        cities {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllCountriesWithCitiesQuery__
 *
 * To run a query within a React component, call `useAllCountriesWithCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCountriesWithCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCountriesWithCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCountriesWithCitiesQuery(baseOptions?: Apollo.QueryHookOptions<G_AllCountriesWithCitiesQuery, G_AllCountriesWithCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_AllCountriesWithCitiesQuery, G_AllCountriesWithCitiesQueryVariables>(AllCountriesWithCitiesDocument, options);
      }
export function useAllCountriesWithCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_AllCountriesWithCitiesQuery, G_AllCountriesWithCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_AllCountriesWithCitiesQuery, G_AllCountriesWithCitiesQueryVariables>(AllCountriesWithCitiesDocument, options);
        }
export type AllCountriesWithCitiesQueryHookResult = ReturnType<typeof useAllCountriesWithCitiesQuery>;
export type AllCountriesWithCitiesLazyQueryHookResult = ReturnType<typeof useAllCountriesWithCitiesLazyQuery>;
export type AllCountriesWithCitiesQueryResult = Apollo.QueryResult<G_AllCountriesWithCitiesQuery, G_AllCountriesWithCitiesQueryVariables>;
export function refetchAllCountriesWithCitiesQuery(variables?: G_AllCountriesWithCitiesQueryVariables) {
      return { query: AllCountriesWithCitiesDocument, variables: variables }
    }
export const ProfileListingsForDropdownDocument = gql`
    query profileListingsForDropdown($slug: String!) {
  profilesAll(slug: $slug, first: 1) {
    edges {
      node {
        listings {
          edges {
            node {
              id
              title
              type
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useProfileListingsForDropdownQuery__
 *
 * To run a query within a React component, call `useProfileListingsForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileListingsForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileListingsForDropdownQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProfileListingsForDropdownQuery(baseOptions: Apollo.QueryHookOptions<G_ProfileListingsForDropdownQuery, G_ProfileListingsForDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ProfileListingsForDropdownQuery, G_ProfileListingsForDropdownQueryVariables>(ProfileListingsForDropdownDocument, options);
      }
export function useProfileListingsForDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ProfileListingsForDropdownQuery, G_ProfileListingsForDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ProfileListingsForDropdownQuery, G_ProfileListingsForDropdownQueryVariables>(ProfileListingsForDropdownDocument, options);
        }
export type ProfileListingsForDropdownQueryHookResult = ReturnType<typeof useProfileListingsForDropdownQuery>;
export type ProfileListingsForDropdownLazyQueryHookResult = ReturnType<typeof useProfileListingsForDropdownLazyQuery>;
export type ProfileListingsForDropdownQueryResult = Apollo.QueryResult<G_ProfileListingsForDropdownQuery, G_ProfileListingsForDropdownQueryVariables>;
export function refetchProfileListingsForDropdownQuery(variables: G_ProfileListingsForDropdownQueryVariables) {
      return { query: ProfileListingsForDropdownDocument, variables: variables }
    }
export const ExpertStripeStatusDocument = gql`
    query expertStripeStatus($profileId: ID!) {
  profile(id: $profileId) {
    isStripeExpertAccountInitialized
    stripeExpertId
  }
}
    `;

/**
 * __useExpertStripeStatusQuery__
 *
 * To run a query within a React component, call `useExpertStripeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertStripeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertStripeStatusQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useExpertStripeStatusQuery(baseOptions: Apollo.QueryHookOptions<G_ExpertStripeStatusQuery, G_ExpertStripeStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ExpertStripeStatusQuery, G_ExpertStripeStatusQueryVariables>(ExpertStripeStatusDocument, options);
      }
export function useExpertStripeStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ExpertStripeStatusQuery, G_ExpertStripeStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ExpertStripeStatusQuery, G_ExpertStripeStatusQueryVariables>(ExpertStripeStatusDocument, options);
        }
export type ExpertStripeStatusQueryHookResult = ReturnType<typeof useExpertStripeStatusQuery>;
export type ExpertStripeStatusLazyQueryHookResult = ReturnType<typeof useExpertStripeStatusLazyQuery>;
export type ExpertStripeStatusQueryResult = Apollo.QueryResult<G_ExpertStripeStatusQuery, G_ExpertStripeStatusQueryVariables>;
export function refetchExpertStripeStatusQuery(variables: G_ExpertStripeStatusQueryVariables) {
      return { query: ExpertStripeStatusDocument, variables: variables }
    }
export const DashboardStatsDocument = gql`
    query dashboardStats($profileId: ID!) {
  profile(id: $profileId) {
    stats {
      totalClients
      totalBookings
      totalRevenue
      balances
    }
  }
}
    `;

/**
 * __useDashboardStatsQuery__
 *
 * To run a query within a React component, call `useDashboardStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardStatsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useDashboardStatsQuery(baseOptions: Apollo.QueryHookOptions<G_DashboardStatsQuery, G_DashboardStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_DashboardStatsQuery, G_DashboardStatsQueryVariables>(DashboardStatsDocument, options);
      }
export function useDashboardStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_DashboardStatsQuery, G_DashboardStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_DashboardStatsQuery, G_DashboardStatsQueryVariables>(DashboardStatsDocument, options);
        }
export type DashboardStatsQueryHookResult = ReturnType<typeof useDashboardStatsQuery>;
export type DashboardStatsLazyQueryHookResult = ReturnType<typeof useDashboardStatsLazyQuery>;
export type DashboardStatsQueryResult = Apollo.QueryResult<G_DashboardStatsQuery, G_DashboardStatsQueryVariables>;
export function refetchDashboardStatsQuery(variables: G_DashboardStatsQueryVariables) {
      return { query: DashboardStatsDocument, variables: variables }
    }
export const GetUpcomingsDocument = gql`
    query getUpcomings($profileId: ID!) {
  profile(id: $profileId) {
    listings {
      edges {
        node {
          hasUpcomingSession
          upcomingSessions(first: 5) {
            edges {
              node {
                startTs
                endTs
                occurrence {
                  id
                  event {
                    type
                  }
                  bookings {
                    edges {
                      node {
                        id
                        participationType
                        attendeeProfile {
                          slug
                          firstName
                          lastName
                          profilePictureUri
                        }
                      }
                    }
                  }
                  listingItemOption {
                    listingItem {
                      availableSeats
                      isAvailableOnline
                      locations {
                        edges {
                          node {
                            id
                          }
                        }
                      }
                      listing {
                        id
                        slug
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUpcomingsQuery__
 *
 * To run a query within a React component, call `useGetUpcomingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetUpcomingsQuery(baseOptions: Apollo.QueryHookOptions<G_GetUpcomingsQuery, G_GetUpcomingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetUpcomingsQuery, G_GetUpcomingsQueryVariables>(GetUpcomingsDocument, options);
      }
export function useGetUpcomingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetUpcomingsQuery, G_GetUpcomingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetUpcomingsQuery, G_GetUpcomingsQueryVariables>(GetUpcomingsDocument, options);
        }
export type GetUpcomingsQueryHookResult = ReturnType<typeof useGetUpcomingsQuery>;
export type GetUpcomingsLazyQueryHookResult = ReturnType<typeof useGetUpcomingsLazyQuery>;
export type GetUpcomingsQueryResult = Apollo.QueryResult<G_GetUpcomingsQuery, G_GetUpcomingsQueryVariables>;
export function refetchGetUpcomingsQuery(variables: G_GetUpcomingsQueryVariables) {
      return { query: GetUpcomingsDocument, variables: variables }
    }
export const GetTopCategoriesDocument = gql`
    query getTopCategories($parentCategoryId: ID) {
  items: listingTopCategories(parentCategoryId: $parentCategoryId) {
    slug
    name
    nameDe
    imageUri
  }
}
    `;

/**
 * __useGetTopCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTopCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopCategoriesQuery({
 *   variables: {
 *      parentCategoryId: // value for 'parentCategoryId'
 *   },
 * });
 */
export function useGetTopCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<G_GetTopCategoriesQuery, G_GetTopCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetTopCategoriesQuery, G_GetTopCategoriesQueryVariables>(GetTopCategoriesDocument, options);
      }
export function useGetTopCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetTopCategoriesQuery, G_GetTopCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetTopCategoriesQuery, G_GetTopCategoriesQueryVariables>(GetTopCategoriesDocument, options);
        }
export type GetTopCategoriesQueryHookResult = ReturnType<typeof useGetTopCategoriesQuery>;
export type GetTopCategoriesLazyQueryHookResult = ReturnType<typeof useGetTopCategoriesLazyQuery>;
export type GetTopCategoriesQueryResult = Apollo.QueryResult<G_GetTopCategoriesQuery, G_GetTopCategoriesQueryVariables>;
export function refetchGetTopCategoriesQuery(variables?: G_GetTopCategoriesQueryVariables) {
      return { query: GetTopCategoriesDocument, variables: variables }
    }
export const GetLatestListingsDocument = gql`
    query getLatestListings($listingType: ListingTypes!, $locationType: ListingLocationFlags!) {
  items: listingsLatest(listingType: $listingType, locationType: $locationType) {
    slug
    title
    type
    upcomingSessions(first: 1) {
      edges {
        node {
          startTs
        }
      }
    }
    coverImageUri
    profile {
      firstName
      lastName
      profilePictureUri
    }
  }
}
    `;

/**
 * __useGetLatestListingsQuery__
 *
 * To run a query within a React component, call `useGetLatestListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestListingsQuery({
 *   variables: {
 *      listingType: // value for 'listingType'
 *      locationType: // value for 'locationType'
 *   },
 * });
 */
export function useGetLatestListingsQuery(baseOptions: Apollo.QueryHookOptions<G_GetLatestListingsQuery, G_GetLatestListingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetLatestListingsQuery, G_GetLatestListingsQueryVariables>(GetLatestListingsDocument, options);
      }
export function useGetLatestListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetLatestListingsQuery, G_GetLatestListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetLatestListingsQuery, G_GetLatestListingsQueryVariables>(GetLatestListingsDocument, options);
        }
export type GetLatestListingsQueryHookResult = ReturnType<typeof useGetLatestListingsQuery>;
export type GetLatestListingsLazyQueryHookResult = ReturnType<typeof useGetLatestListingsLazyQuery>;
export type GetLatestListingsQueryResult = Apollo.QueryResult<G_GetLatestListingsQuery, G_GetLatestListingsQueryVariables>;
export function refetchGetLatestListingsQuery(variables: G_GetLatestListingsQueryVariables) {
      return { query: GetLatestListingsDocument, variables: variables }
    }
export const GetUpcomingSessionsDocument = gql`
    query getUpcomingSessions($parentCategoryId: ID, $locationType: ListingLocationFlags!) {
  items: listingUpcomingSessions(
    parentCategoryId: $parentCategoryId
    locationType: $locationType
  ) {
    edges {
      node {
        listingItem {
          listing {
            slug
            title
            type
            upcomingSessions(first: 1) {
              edges {
                node {
                  startTs
                }
              }
            }
            coverImageUri
            profile {
              firstName
              lastName
              profilePictureUri
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUpcomingSessionsQuery__
 *
 * To run a query within a React component, call `useGetUpcomingSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingSessionsQuery({
 *   variables: {
 *      parentCategoryId: // value for 'parentCategoryId'
 *      locationType: // value for 'locationType'
 *   },
 * });
 */
export function useGetUpcomingSessionsQuery(baseOptions: Apollo.QueryHookOptions<G_GetUpcomingSessionsQuery, G_GetUpcomingSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetUpcomingSessionsQuery, G_GetUpcomingSessionsQueryVariables>(GetUpcomingSessionsDocument, options);
      }
export function useGetUpcomingSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetUpcomingSessionsQuery, G_GetUpcomingSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetUpcomingSessionsQuery, G_GetUpcomingSessionsQueryVariables>(GetUpcomingSessionsDocument, options);
        }
export type GetUpcomingSessionsQueryHookResult = ReturnType<typeof useGetUpcomingSessionsQuery>;
export type GetUpcomingSessionsLazyQueryHookResult = ReturnType<typeof useGetUpcomingSessionsLazyQuery>;
export type GetUpcomingSessionsQueryResult = Apollo.QueryResult<G_GetUpcomingSessionsQuery, G_GetUpcomingSessionsQueryVariables>;
export function refetchGetUpcomingSessionsQuery(variables: G_GetUpcomingSessionsQueryVariables) {
      return { query: GetUpcomingSessionsDocument, variables: variables }
    }
export const ListingsRandomDocument = gql`
    query listingsRandom($parentCategoryId: ID, $listingType: ListingTypes!, $locationType: ListingLocationFlags!) {
  items: listingsRandom(
    parentCategoryId: $parentCategoryId
    listingType: $listingType
    locationType: $locationType
  ) {
    slug
    title
    type
    upcomingSessions(first: 1) {
      edges {
        node {
          startTs
        }
      }
    }
    coverImageUri
    profile {
      firstName
      lastName
      profilePictureUri
    }
  }
}
    `;

/**
 * __useListingsRandomQuery__
 *
 * To run a query within a React component, call `useListingsRandomQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingsRandomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingsRandomQuery({
 *   variables: {
 *      parentCategoryId: // value for 'parentCategoryId'
 *      listingType: // value for 'listingType'
 *      locationType: // value for 'locationType'
 *   },
 * });
 */
export function useListingsRandomQuery(baseOptions: Apollo.QueryHookOptions<G_ListingsRandomQuery, G_ListingsRandomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ListingsRandomQuery, G_ListingsRandomQueryVariables>(ListingsRandomDocument, options);
      }
export function useListingsRandomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ListingsRandomQuery, G_ListingsRandomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ListingsRandomQuery, G_ListingsRandomQueryVariables>(ListingsRandomDocument, options);
        }
export type ListingsRandomQueryHookResult = ReturnType<typeof useListingsRandomQuery>;
export type ListingsRandomLazyQueryHookResult = ReturnType<typeof useListingsRandomLazyQuery>;
export type ListingsRandomQueryResult = Apollo.QueryResult<G_ListingsRandomQuery, G_ListingsRandomQueryVariables>;
export function refetchListingsRandomQuery(variables: G_ListingsRandomQueryVariables) {
      return { query: ListingsRandomDocument, variables: variables }
    }
export const GetFeaturedExpertsDocument = gql`
    query getFeaturedExperts($parentCategoryId: ID) {
  items: profilesFeaturedExperts(parentCategoryId: $parentCategoryId) {
    slug
    firstName
    lastName
    profilePictureUri
    locationCity {
      name
    }
  }
}
    `;

/**
 * __useGetFeaturedExpertsQuery__
 *
 * To run a query within a React component, call `useGetFeaturedExpertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedExpertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedExpertsQuery({
 *   variables: {
 *      parentCategoryId: // value for 'parentCategoryId'
 *   },
 * });
 */
export function useGetFeaturedExpertsQuery(baseOptions?: Apollo.QueryHookOptions<G_GetFeaturedExpertsQuery, G_GetFeaturedExpertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetFeaturedExpertsQuery, G_GetFeaturedExpertsQueryVariables>(GetFeaturedExpertsDocument, options);
      }
export function useGetFeaturedExpertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetFeaturedExpertsQuery, G_GetFeaturedExpertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetFeaturedExpertsQuery, G_GetFeaturedExpertsQueryVariables>(GetFeaturedExpertsDocument, options);
        }
export type GetFeaturedExpertsQueryHookResult = ReturnType<typeof useGetFeaturedExpertsQuery>;
export type GetFeaturedExpertsLazyQueryHookResult = ReturnType<typeof useGetFeaturedExpertsLazyQuery>;
export type GetFeaturedExpertsQueryResult = Apollo.QueryResult<G_GetFeaturedExpertsQuery, G_GetFeaturedExpertsQueryVariables>;
export function refetchGetFeaturedExpertsQuery(variables?: G_GetFeaturedExpertsQueryVariables) {
      return { query: GetFeaturedExpertsDocument, variables: variables }
    }
export const ProfileAddressesDocument = gql`
    query profileAddresses($slug: String!) {
  profilesAll(slug: $slug, first: 1) {
    edges {
      node {
        addresses {
          edges {
            node {
              id
              title
              addressLine1
              postalCode
              city {
                id
                name
              }
              country {
                id
                name
              }
              order
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useProfileAddressesQuery__
 *
 * To run a query within a React component, call `useProfileAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileAddressesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProfileAddressesQuery(baseOptions: Apollo.QueryHookOptions<G_ProfileAddressesQuery, G_ProfileAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ProfileAddressesQuery, G_ProfileAddressesQueryVariables>(ProfileAddressesDocument, options);
      }
export function useProfileAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ProfileAddressesQuery, G_ProfileAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ProfileAddressesQuery, G_ProfileAddressesQueryVariables>(ProfileAddressesDocument, options);
        }
export type ProfileAddressesQueryHookResult = ReturnType<typeof useProfileAddressesQuery>;
export type ProfileAddressesLazyQueryHookResult = ReturnType<typeof useProfileAddressesLazyQuery>;
export type ProfileAddressesQueryResult = Apollo.QueryResult<G_ProfileAddressesQuery, G_ProfileAddressesQueryVariables>;
export function refetchProfileAddressesQuery(variables: G_ProfileAddressesQueryVariables) {
      return { query: ProfileAddressesDocument, variables: variables }
    }
export const ProfileAddressAddDocument = gql`
    mutation profileAddressAdd($profile: ID!, $title: String!, $addressLine1: String, $addressLine2: String, $city: ID!, $postalCode: String, $order: Int) {
  profileAddressAdd(
    profile: $profile
    title: $title
    addressLine1: $addressLine1
    addressLine2: $addressLine2
    city: $city
    postalCode: $postalCode
    order: $order
  ) {
    id
  }
}
    `;
export type G_ProfileAddressAddMutationFn = Apollo.MutationFunction<G_ProfileAddressAddMutation, G_ProfileAddressAddMutationVariables>;

/**
 * __useProfileAddressAddMutation__
 *
 * To run a mutation, you first call `useProfileAddressAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAddressAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAddressAddMutation, { data, loading, error }] = useProfileAddressAddMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      title: // value for 'title'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      postalCode: // value for 'postalCode'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useProfileAddressAddMutation(baseOptions?: Apollo.MutationHookOptions<G_ProfileAddressAddMutation, G_ProfileAddressAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ProfileAddressAddMutation, G_ProfileAddressAddMutationVariables>(ProfileAddressAddDocument, options);
      }
export type ProfileAddressAddMutationHookResult = ReturnType<typeof useProfileAddressAddMutation>;
export type ProfileAddressAddMutationResult = Apollo.MutationResult<G_ProfileAddressAddMutation>;
export type ProfileAddressAddMutationOptions = Apollo.BaseMutationOptions<G_ProfileAddressAddMutation, G_ProfileAddressAddMutationVariables>;
export const ProfileAddressEditDocument = gql`
    mutation profileAddressEdit($profile: ID!, $address: ID!, $title: String!, $addressLine1: String, $addressLine2: String, $city: ID!, $postalCode: String, $order: Int) {
  profileAddressEdit(
    profile: $profile
    address: $address
    title: $title
    addressLine1: $addressLine1
    addressLine2: $addressLine2
    city: $city
    postalCode: $postalCode
    order: $order
  ) {
    id
  }
}
    `;
export type G_ProfileAddressEditMutationFn = Apollo.MutationFunction<G_ProfileAddressEditMutation, G_ProfileAddressEditMutationVariables>;

/**
 * __useProfileAddressEditMutation__
 *
 * To run a mutation, you first call `useProfileAddressEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAddressEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAddressEditMutation, { data, loading, error }] = useProfileAddressEditMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      address: // value for 'address'
 *      title: // value for 'title'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      postalCode: // value for 'postalCode'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useProfileAddressEditMutation(baseOptions?: Apollo.MutationHookOptions<G_ProfileAddressEditMutation, G_ProfileAddressEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ProfileAddressEditMutation, G_ProfileAddressEditMutationVariables>(ProfileAddressEditDocument, options);
      }
export type ProfileAddressEditMutationHookResult = ReturnType<typeof useProfileAddressEditMutation>;
export type ProfileAddressEditMutationResult = Apollo.MutationResult<G_ProfileAddressEditMutation>;
export type ProfileAddressEditMutationOptions = Apollo.BaseMutationOptions<G_ProfileAddressEditMutation, G_ProfileAddressEditMutationVariables>;
export const ProfileAddressRemoveDocument = gql`
    mutation profileAddressRemove($profile: ID!, $address: ID!) {
  profileAddressRemove(profile: $profile, address: $address) {
    id
  }
}
    `;
export type G_ProfileAddressRemoveMutationFn = Apollo.MutationFunction<G_ProfileAddressRemoveMutation, G_ProfileAddressRemoveMutationVariables>;

/**
 * __useProfileAddressRemoveMutation__
 *
 * To run a mutation, you first call `useProfileAddressRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileAddressRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileAddressRemoveMutation, { data, loading, error }] = useProfileAddressRemoveMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useProfileAddressRemoveMutation(baseOptions?: Apollo.MutationHookOptions<G_ProfileAddressRemoveMutation, G_ProfileAddressRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ProfileAddressRemoveMutation, G_ProfileAddressRemoveMutationVariables>(ProfileAddressRemoveDocument, options);
      }
export type ProfileAddressRemoveMutationHookResult = ReturnType<typeof useProfileAddressRemoveMutation>;
export type ProfileAddressRemoveMutationResult = Apollo.MutationResult<G_ProfileAddressRemoveMutation>;
export type ProfileAddressRemoveMutationOptions = Apollo.BaseMutationOptions<G_ProfileAddressRemoveMutation, G_ProfileAddressRemoveMutationVariables>;
export const UpdateAccountPreferencesStep1Document = gql`
    mutation updateAccountPreferencesStep1($profile: ID!, $languages: [ID]) {
  profileUpdate(profile: $profile, languages: $languages) {
    languages {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
    `;
export type G_UpdateAccountPreferencesStep1MutationFn = Apollo.MutationFunction<G_UpdateAccountPreferencesStep1Mutation, G_UpdateAccountPreferencesStep1MutationVariables>;

/**
 * __useUpdateAccountPreferencesStep1Mutation__
 *
 * To run a mutation, you first call `useUpdateAccountPreferencesStep1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPreferencesStep1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPreferencesStep1Mutation, { data, loading, error }] = useUpdateAccountPreferencesStep1Mutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useUpdateAccountPreferencesStep1Mutation(baseOptions?: Apollo.MutationHookOptions<G_UpdateAccountPreferencesStep1Mutation, G_UpdateAccountPreferencesStep1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_UpdateAccountPreferencesStep1Mutation, G_UpdateAccountPreferencesStep1MutationVariables>(UpdateAccountPreferencesStep1Document, options);
      }
export type UpdateAccountPreferencesStep1MutationHookResult = ReturnType<typeof useUpdateAccountPreferencesStep1Mutation>;
export type UpdateAccountPreferencesStep1MutationResult = Apollo.MutationResult<G_UpdateAccountPreferencesStep1Mutation>;
export type UpdateAccountPreferencesStep1MutationOptions = Apollo.BaseMutationOptions<G_UpdateAccountPreferencesStep1Mutation, G_UpdateAccountPreferencesStep1MutationVariables>;
export const ChangePayoutSettingsDocument = gql`
    mutation changePayoutSettings($companyInfo: CompanyInput, $companyRepresentativeInfo: RepresentativeInput, $documents: [DocumentInput], $iban: String, $paymentPersonType: AccountTypes, $profile: ID!) {
  changePayoutSettings(
    companyInfo: $companyInfo
    companyRepresentativeInfo: $companyRepresentativeInfo
    documents: $documents
    iban: $iban
    paymentPersonType: $paymentPersonType
    profile: $profile
  ) {
    id
  }
}
    `;
export type G_ChangePayoutSettingsMutationFn = Apollo.MutationFunction<G_ChangePayoutSettingsMutation, G_ChangePayoutSettingsMutationVariables>;

/**
 * __useChangePayoutSettingsMutation__
 *
 * To run a mutation, you first call `useChangePayoutSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePayoutSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePayoutSettingsMutation, { data, loading, error }] = useChangePayoutSettingsMutation({
 *   variables: {
 *      companyInfo: // value for 'companyInfo'
 *      companyRepresentativeInfo: // value for 'companyRepresentativeInfo'
 *      documents: // value for 'documents'
 *      iban: // value for 'iban'
 *      paymentPersonType: // value for 'paymentPersonType'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useChangePayoutSettingsMutation(baseOptions?: Apollo.MutationHookOptions<G_ChangePayoutSettingsMutation, G_ChangePayoutSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ChangePayoutSettingsMutation, G_ChangePayoutSettingsMutationVariables>(ChangePayoutSettingsDocument, options);
      }
export type ChangePayoutSettingsMutationHookResult = ReturnType<typeof useChangePayoutSettingsMutation>;
export type ChangePayoutSettingsMutationResult = Apollo.MutationResult<G_ChangePayoutSettingsMutation>;
export type ChangePayoutSettingsMutationOptions = Apollo.BaseMutationOptions<G_ChangePayoutSettingsMutation, G_ChangePayoutSettingsMutationVariables>;
export const GetKycInfoDocument = gql`
    query getKycInfo($profileId: ID!) {
  profile(id: $profileId) {
    firstName
    lastName
    email
    role
    birthdate
    kycStatus
    payoutAccountType
    companyName
    companyRegistrationNumber
    companyAddressAddressLine1
    companyAddressPostalCode
    companyAddressCity {
      id
      name
    }
    companyAddressCountry {
      id
      name
    }
    companyRepresentativeFirstName
    companyRepresentativeLastName
    companyRepresentativeBirthdate
    companyRepresentativeEmail
    companyRepresentativeAddressAddressLine1
    companyRepresentativeAddressPostalCode
    companyRepresentativeAddressCity {
      id
      name
    }
    companyRepresentativeAddressCountry {
      id
      name
    }
    payoutSellerBankAccountIban
    documents {
      edges {
        node {
          mangopayKycDocumentType
          mangopayKycDocumentUri
          mangopayKycDocumentStatus
        }
      }
    }
  }
}
    `;

/**
 * __useGetKycInfoQuery__
 *
 * To run a query within a React component, call `useGetKycInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKycInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKycInfoQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetKycInfoQuery(baseOptions: Apollo.QueryHookOptions<G_GetKycInfoQuery, G_GetKycInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetKycInfoQuery, G_GetKycInfoQueryVariables>(GetKycInfoDocument, options);
      }
export function useGetKycInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetKycInfoQuery, G_GetKycInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetKycInfoQuery, G_GetKycInfoQueryVariables>(GetKycInfoDocument, options);
        }
export type GetKycInfoQueryHookResult = ReturnType<typeof useGetKycInfoQuery>;
export type GetKycInfoLazyQueryHookResult = ReturnType<typeof useGetKycInfoLazyQuery>;
export type GetKycInfoQueryResult = Apollo.QueryResult<G_GetKycInfoQuery, G_GetKycInfoQueryVariables>;
export function refetchGetKycInfoQuery(variables: G_GetKycInfoQueryVariables) {
      return { query: GetKycInfoDocument, variables: variables }
    }
export const AccountPersonalInfoDocument = gql`
    query accountPersonalInfo($profileSlug: String!) {
  profilesAll(slug: $profileSlug, first: 1) {
    edges {
      node {
        id
        slug
        firstName
        lastName
        gender
        birthdate
        email
        phone
      }
    }
  }
}
    `;

/**
 * __useAccountPersonalInfoQuery__
 *
 * To run a query within a React component, call `useAccountPersonalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPersonalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPersonalInfoQuery({
 *   variables: {
 *      profileSlug: // value for 'profileSlug'
 *   },
 * });
 */
export function useAccountPersonalInfoQuery(baseOptions: Apollo.QueryHookOptions<G_AccountPersonalInfoQuery, G_AccountPersonalInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_AccountPersonalInfoQuery, G_AccountPersonalInfoQueryVariables>(AccountPersonalInfoDocument, options);
      }
export function useAccountPersonalInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_AccountPersonalInfoQuery, G_AccountPersonalInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_AccountPersonalInfoQuery, G_AccountPersonalInfoQueryVariables>(AccountPersonalInfoDocument, options);
        }
export type AccountPersonalInfoQueryHookResult = ReturnType<typeof useAccountPersonalInfoQuery>;
export type AccountPersonalInfoLazyQueryHookResult = ReturnType<typeof useAccountPersonalInfoLazyQuery>;
export type AccountPersonalInfoQueryResult = Apollo.QueryResult<G_AccountPersonalInfoQuery, G_AccountPersonalInfoQueryVariables>;
export function refetchAccountPersonalInfoQuery(variables: G_AccountPersonalInfoQueryVariables) {
      return { query: AccountPersonalInfoDocument, variables: variables }
    }
export const UpdateAccountPersonalInfoStep1Document = gql`
    mutation updateAccountPersonalInfoStep1($profile: ID!, $firstName: String, $lastName: String) {
  profileUpdate(profile: $profile, firstName: $firstName, lastName: $lastName) {
    slug
    firstName
    lastName
  }
}
    `;
export type G_UpdateAccountPersonalInfoStep1MutationFn = Apollo.MutationFunction<G_UpdateAccountPersonalInfoStep1Mutation, G_UpdateAccountPersonalInfoStep1MutationVariables>;

/**
 * __useUpdateAccountPersonalInfoStep1Mutation__
 *
 * To run a mutation, you first call `useUpdateAccountPersonalInfoStep1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPersonalInfoStep1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPersonalInfoStep1Mutation, { data, loading, error }] = useUpdateAccountPersonalInfoStep1Mutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateAccountPersonalInfoStep1Mutation(baseOptions?: Apollo.MutationHookOptions<G_UpdateAccountPersonalInfoStep1Mutation, G_UpdateAccountPersonalInfoStep1MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_UpdateAccountPersonalInfoStep1Mutation, G_UpdateAccountPersonalInfoStep1MutationVariables>(UpdateAccountPersonalInfoStep1Document, options);
      }
export type UpdateAccountPersonalInfoStep1MutationHookResult = ReturnType<typeof useUpdateAccountPersonalInfoStep1Mutation>;
export type UpdateAccountPersonalInfoStep1MutationResult = Apollo.MutationResult<G_UpdateAccountPersonalInfoStep1Mutation>;
export type UpdateAccountPersonalInfoStep1MutationOptions = Apollo.BaseMutationOptions<G_UpdateAccountPersonalInfoStep1Mutation, G_UpdateAccountPersonalInfoStep1MutationVariables>;
export const UpdateAccountPersonalInfoStep2Document = gql`
    mutation updateAccountPersonalInfoStep2($profile: ID!, $gender: String) {
  profileUpdate(profile: $profile, gender: $gender) {
    gender
  }
}
    `;
export type G_UpdateAccountPersonalInfoStep2MutationFn = Apollo.MutationFunction<G_UpdateAccountPersonalInfoStep2Mutation, G_UpdateAccountPersonalInfoStep2MutationVariables>;

/**
 * __useUpdateAccountPersonalInfoStep2Mutation__
 *
 * To run a mutation, you first call `useUpdateAccountPersonalInfoStep2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPersonalInfoStep2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPersonalInfoStep2Mutation, { data, loading, error }] = useUpdateAccountPersonalInfoStep2Mutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useUpdateAccountPersonalInfoStep2Mutation(baseOptions?: Apollo.MutationHookOptions<G_UpdateAccountPersonalInfoStep2Mutation, G_UpdateAccountPersonalInfoStep2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_UpdateAccountPersonalInfoStep2Mutation, G_UpdateAccountPersonalInfoStep2MutationVariables>(UpdateAccountPersonalInfoStep2Document, options);
      }
export type UpdateAccountPersonalInfoStep2MutationHookResult = ReturnType<typeof useUpdateAccountPersonalInfoStep2Mutation>;
export type UpdateAccountPersonalInfoStep2MutationResult = Apollo.MutationResult<G_UpdateAccountPersonalInfoStep2Mutation>;
export type UpdateAccountPersonalInfoStep2MutationOptions = Apollo.BaseMutationOptions<G_UpdateAccountPersonalInfoStep2Mutation, G_UpdateAccountPersonalInfoStep2MutationVariables>;
export const UpdateAccountPersonalInfoStep3Document = gql`
    mutation updateAccountPersonalInfoStep3($profile: ID!, $birthdate: String) {
  profileUpdate(profile: $profile, birthdate: $birthdate) {
    birthdate
  }
}
    `;
export type G_UpdateAccountPersonalInfoStep3MutationFn = Apollo.MutationFunction<G_UpdateAccountPersonalInfoStep3Mutation, G_UpdateAccountPersonalInfoStep3MutationVariables>;

/**
 * __useUpdateAccountPersonalInfoStep3Mutation__
 *
 * To run a mutation, you first call `useUpdateAccountPersonalInfoStep3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPersonalInfoStep3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPersonalInfoStep3Mutation, { data, loading, error }] = useUpdateAccountPersonalInfoStep3Mutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      birthdate: // value for 'birthdate'
 *   },
 * });
 */
export function useUpdateAccountPersonalInfoStep3Mutation(baseOptions?: Apollo.MutationHookOptions<G_UpdateAccountPersonalInfoStep3Mutation, G_UpdateAccountPersonalInfoStep3MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_UpdateAccountPersonalInfoStep3Mutation, G_UpdateAccountPersonalInfoStep3MutationVariables>(UpdateAccountPersonalInfoStep3Document, options);
      }
export type UpdateAccountPersonalInfoStep3MutationHookResult = ReturnType<typeof useUpdateAccountPersonalInfoStep3Mutation>;
export type UpdateAccountPersonalInfoStep3MutationResult = Apollo.MutationResult<G_UpdateAccountPersonalInfoStep3Mutation>;
export type UpdateAccountPersonalInfoStep3MutationOptions = Apollo.BaseMutationOptions<G_UpdateAccountPersonalInfoStep3Mutation, G_UpdateAccountPersonalInfoStep3MutationVariables>;
export const UpdateAccountPersonalInfoStep4Document = gql`
    mutation updateAccountPersonalInfoStep4($profile: ID!, $email: String) {
  profileUpdate(profile: $profile, email: $email) {
    email
  }
}
    `;
export type G_UpdateAccountPersonalInfoStep4MutationFn = Apollo.MutationFunction<G_UpdateAccountPersonalInfoStep4Mutation, G_UpdateAccountPersonalInfoStep4MutationVariables>;

/**
 * __useUpdateAccountPersonalInfoStep4Mutation__
 *
 * To run a mutation, you first call `useUpdateAccountPersonalInfoStep4Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPersonalInfoStep4Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPersonalInfoStep4Mutation, { data, loading, error }] = useUpdateAccountPersonalInfoStep4Mutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateAccountPersonalInfoStep4Mutation(baseOptions?: Apollo.MutationHookOptions<G_UpdateAccountPersonalInfoStep4Mutation, G_UpdateAccountPersonalInfoStep4MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_UpdateAccountPersonalInfoStep4Mutation, G_UpdateAccountPersonalInfoStep4MutationVariables>(UpdateAccountPersonalInfoStep4Document, options);
      }
export type UpdateAccountPersonalInfoStep4MutationHookResult = ReturnType<typeof useUpdateAccountPersonalInfoStep4Mutation>;
export type UpdateAccountPersonalInfoStep4MutationResult = Apollo.MutationResult<G_UpdateAccountPersonalInfoStep4Mutation>;
export type UpdateAccountPersonalInfoStep4MutationOptions = Apollo.BaseMutationOptions<G_UpdateAccountPersonalInfoStep4Mutation, G_UpdateAccountPersonalInfoStep4MutationVariables>;
export const UpdateAccountPersonalInfoStep5Document = gql`
    mutation updateAccountPersonalInfoStep5($profile: ID!, $phone: String) {
  profileUpdate(profile: $profile, phone: $phone) {
    phone
  }
}
    `;
export type G_UpdateAccountPersonalInfoStep5MutationFn = Apollo.MutationFunction<G_UpdateAccountPersonalInfoStep5Mutation, G_UpdateAccountPersonalInfoStep5MutationVariables>;

/**
 * __useUpdateAccountPersonalInfoStep5Mutation__
 *
 * To run a mutation, you first call `useUpdateAccountPersonalInfoStep5Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPersonalInfoStep5Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPersonalInfoStep5Mutation, { data, loading, error }] = useUpdateAccountPersonalInfoStep5Mutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateAccountPersonalInfoStep5Mutation(baseOptions?: Apollo.MutationHookOptions<G_UpdateAccountPersonalInfoStep5Mutation, G_UpdateAccountPersonalInfoStep5MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_UpdateAccountPersonalInfoStep5Mutation, G_UpdateAccountPersonalInfoStep5MutationVariables>(UpdateAccountPersonalInfoStep5Document, options);
      }
export type UpdateAccountPersonalInfoStep5MutationHookResult = ReturnType<typeof useUpdateAccountPersonalInfoStep5Mutation>;
export type UpdateAccountPersonalInfoStep5MutationResult = Apollo.MutationResult<G_UpdateAccountPersonalInfoStep5Mutation>;
export type UpdateAccountPersonalInfoStep5MutationOptions = Apollo.BaseMutationOptions<G_UpdateAccountPersonalInfoStep5Mutation, G_UpdateAccountPersonalInfoStep5MutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $previousPassword: String!) {
  changePassword(newPassword: $newPassword, previousPassword: $previousPassword) {
    username
  }
}
    `;
export type G_ChangePasswordMutationFn = Apollo.MutationFunction<G_ChangePasswordMutation, G_ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      previousPassword: // value for 'previousPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<G_ChangePasswordMutation, G_ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ChangePasswordMutation, G_ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<G_ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<G_ChangePasswordMutation, G_ChangePasswordMutationVariables>;
export const CheckUserDocument = gql`
    query checkUser($login: String!) {
  checkUser(login: $login) {
    username
    userExists
    status
  }
}
    `;

/**
 * __useCheckUserQuery__
 *
 * To run a query within a React component, call `useCheckUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useCheckUserQuery(baseOptions: Apollo.QueryHookOptions<G_CheckUserQuery, G_CheckUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_CheckUserQuery, G_CheckUserQueryVariables>(CheckUserDocument, options);
      }
export function useCheckUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_CheckUserQuery, G_CheckUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_CheckUserQuery, G_CheckUserQueryVariables>(CheckUserDocument, options);
        }
export type CheckUserQueryHookResult = ReturnType<typeof useCheckUserQuery>;
export type CheckUserLazyQueryHookResult = ReturnType<typeof useCheckUserLazyQuery>;
export type CheckUserQueryResult = Apollo.QueryResult<G_CheckUserQuery, G_CheckUserQueryVariables>;
export function refetchCheckUserQuery(variables: G_CheckUserQueryVariables) {
      return { query: CheckUserDocument, variables: variables }
    }
export const LoginDocument = gql`
    mutation login($username: String!, $password: String!) {
  authToken(username: $username, password: $password) {
    payload
    refreshExpiresIn
    token
    user {
      id
      profiles {
        edges {
          node {
            id
            slug
            role
            email
            firstName
            lastName
            timezone
            profilePictureUri
            serviceFeeRatio
            calendars(first: 1) {
              edges {
                node {
                  id
                  title
                }
              }
            }
            coverPictureUri
            bio
            locationCity {
              id
            }
            locationCountry {
              id
            }
            languages {
              edges {
                node {
                  id
                }
              }
            }
            listings(first: 1) {
              edges {
                cursor
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type G_LoginMutationFn = Apollo.MutationFunction<G_LoginMutation, G_LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<G_LoginMutation, G_LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_LoginMutation, G_LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<G_LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<G_LoginMutation, G_LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($username: String!) {
  resetPassword(username: $username) {
    username
  }
}
    `;
export type G_ResetPasswordMutationFn = Apollo.MutationFunction<G_ResetPasswordMutation, G_ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<G_ResetPasswordMutation, G_ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ResetPasswordMutation, G_ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<G_ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<G_ResetPasswordMutation, G_ResetPasswordMutationVariables>;
export const ResetPasswordConfirmDocument = gql`
    mutation resetPasswordConfirm($confirmationCode: String!, $newPassword: String!, $username: String!) {
  resetPasswordConfirm(
    confirmationCode: $confirmationCode
    newPassword: $newPassword
    username: $username
  ) {
    username
  }
}
    `;
export type G_ResetPasswordConfirmMutationFn = Apollo.MutationFunction<G_ResetPasswordConfirmMutation, G_ResetPasswordConfirmMutationVariables>;

/**
 * __useResetPasswordConfirmMutation__
 *
 * To run a mutation, you first call `useResetPasswordConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordConfirmMutation, { data, loading, error }] = useResetPasswordConfirmMutation({
 *   variables: {
 *      confirmationCode: // value for 'confirmationCode'
 *      newPassword: // value for 'newPassword'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useResetPasswordConfirmMutation(baseOptions?: Apollo.MutationHookOptions<G_ResetPasswordConfirmMutation, G_ResetPasswordConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ResetPasswordConfirmMutation, G_ResetPasswordConfirmMutationVariables>(ResetPasswordConfirmDocument, options);
      }
export type ResetPasswordConfirmMutationHookResult = ReturnType<typeof useResetPasswordConfirmMutation>;
export type ResetPasswordConfirmMutationResult = Apollo.MutationResult<G_ResetPasswordConfirmMutation>;
export type ResetPasswordConfirmMutationOptions = Apollo.BaseMutationOptions<G_ResetPasswordConfirmMutation, G_ResetPasswordConfirmMutationVariables>;
export const SignupDocument = gql`
    mutation signup($login: String!, $firstName: String, $lastName: String, $locale: String, $password: String!, $role: ProfileRoles!) {
  signup(
    login: $login
    firstName: $firstName
    lastName: $lastName
    locale: $locale
    password: $password
    role: $role
  ) {
    username
  }
}
    `;
export type G_SignupMutationFn = Apollo.MutationFunction<G_SignupMutation, G_SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      login: // value for 'login'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      locale: // value for 'locale'
 *      password: // value for 'password'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<G_SignupMutation, G_SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_SignupMutation, G_SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<G_SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<G_SignupMutation, G_SignupMutationVariables>;
export const ResendSignupConfirmationDocument = gql`
    mutation resendSignupConfirmation($username: String!) {
  signupResend(username: $username) {
    username
  }
}
    `;
export type G_ResendSignupConfirmationMutationFn = Apollo.MutationFunction<G_ResendSignupConfirmationMutation, G_ResendSignupConfirmationMutationVariables>;

/**
 * __useResendSignupConfirmationMutation__
 *
 * To run a mutation, you first call `useResendSignupConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSignupConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSignupConfirmationMutation, { data, loading, error }] = useResendSignupConfirmationMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useResendSignupConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<G_ResendSignupConfirmationMutation, G_ResendSignupConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ResendSignupConfirmationMutation, G_ResendSignupConfirmationMutationVariables>(ResendSignupConfirmationDocument, options);
      }
export type ResendSignupConfirmationMutationHookResult = ReturnType<typeof useResendSignupConfirmationMutation>;
export type ResendSignupConfirmationMutationResult = Apollo.MutationResult<G_ResendSignupConfirmationMutation>;
export type ResendSignupConfirmationMutationOptions = Apollo.BaseMutationOptions<G_ResendSignupConfirmationMutation, G_ResendSignupConfirmationMutationVariables>;
export const SignupConfirmDocument = gql`
    mutation signupConfirm($confirmationCode: String!, $username: String!) {
  signupConfirm(confirmationCode: $confirmationCode, username: $username) {
    username
  }
}
    `;
export type G_SignupConfirmMutationFn = Apollo.MutationFunction<G_SignupConfirmMutation, G_SignupConfirmMutationVariables>;

/**
 * __useSignupConfirmMutation__
 *
 * To run a mutation, you first call `useSignupConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupConfirmMutation, { data, loading, error }] = useSignupConfirmMutation({
 *   variables: {
 *      confirmationCode: // value for 'confirmationCode'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useSignupConfirmMutation(baseOptions?: Apollo.MutationHookOptions<G_SignupConfirmMutation, G_SignupConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_SignupConfirmMutation, G_SignupConfirmMutationVariables>(SignupConfirmDocument, options);
      }
export type SignupConfirmMutationHookResult = ReturnType<typeof useSignupConfirmMutation>;
export type SignupConfirmMutationResult = Apollo.MutationResult<G_SignupConfirmMutation>;
export type SignupConfirmMutationOptions = Apollo.BaseMutationOptions<G_SignupConfirmMutation, G_SignupConfirmMutationVariables>;
export const ProfileBecomeExpertDocument = gql`
    mutation profileBecomeExpert($profile: ID!) {
  profileBecomeExpert(profile: $profile) {
    id
    role
  }
}
    `;
export type G_ProfileBecomeExpertMutationFn = Apollo.MutationFunction<G_ProfileBecomeExpertMutation, G_ProfileBecomeExpertMutationVariables>;

/**
 * __useProfileBecomeExpertMutation__
 *
 * To run a mutation, you first call `useProfileBecomeExpertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileBecomeExpertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileBecomeExpertMutation, { data, loading, error }] = useProfileBecomeExpertMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useProfileBecomeExpertMutation(baseOptions?: Apollo.MutationHookOptions<G_ProfileBecomeExpertMutation, G_ProfileBecomeExpertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ProfileBecomeExpertMutation, G_ProfileBecomeExpertMutationVariables>(ProfileBecomeExpertDocument, options);
      }
export type ProfileBecomeExpertMutationHookResult = ReturnType<typeof useProfileBecomeExpertMutation>;
export type ProfileBecomeExpertMutationResult = Apollo.MutationResult<G_ProfileBecomeExpertMutation>;
export type ProfileBecomeExpertMutationOptions = Apollo.BaseMutationOptions<G_ProfileBecomeExpertMutation, G_ProfileBecomeExpertMutationVariables>;
export const LoginInfoDocument = gql`
    query loginInfo {
  profilesMine {
    id
    email
    firstName
    lastName
    profilePictureUri
    role
    serviceFeeRatio
    slug
    timezone
    calendars {
      edges {
        node {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useLoginInfoQuery__
 *
 * To run a query within a React component, call `useLoginInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoginInfoQuery(baseOptions?: Apollo.QueryHookOptions<G_LoginInfoQuery, G_LoginInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_LoginInfoQuery, G_LoginInfoQueryVariables>(LoginInfoDocument, options);
      }
export function useLoginInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_LoginInfoQuery, G_LoginInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_LoginInfoQuery, G_LoginInfoQueryVariables>(LoginInfoDocument, options);
        }
export type LoginInfoQueryHookResult = ReturnType<typeof useLoginInfoQuery>;
export type LoginInfoLazyQueryHookResult = ReturnType<typeof useLoginInfoLazyQuery>;
export type LoginInfoQueryResult = Apollo.QueryResult<G_LoginInfoQuery, G_LoginInfoQueryVariables>;
export function refetchLoginInfoQuery(variables?: G_LoginInfoQueryVariables) {
      return { query: LoginInfoDocument, variables: variables }
    }
export const GetAvailabilityHoursDocument = gql`
    query getAvailabilityHours($profile: ID!, $calendar: ID!) {
  getAvailabilityHours(profile: $profile, calendar: $calendar) {
    id
    weekday
    startTime
    endTime
  }
}
    `;

/**
 * __useGetAvailabilityHoursQuery__
 *
 * To run a query within a React component, call `useGetAvailabilityHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailabilityHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailabilityHoursQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      calendar: // value for 'calendar'
 *   },
 * });
 */
export function useGetAvailabilityHoursQuery(baseOptions: Apollo.QueryHookOptions<G_GetAvailabilityHoursQuery, G_GetAvailabilityHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetAvailabilityHoursQuery, G_GetAvailabilityHoursQueryVariables>(GetAvailabilityHoursDocument, options);
      }
export function useGetAvailabilityHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetAvailabilityHoursQuery, G_GetAvailabilityHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetAvailabilityHoursQuery, G_GetAvailabilityHoursQueryVariables>(GetAvailabilityHoursDocument, options);
        }
export type GetAvailabilityHoursQueryHookResult = ReturnType<typeof useGetAvailabilityHoursQuery>;
export type GetAvailabilityHoursLazyQueryHookResult = ReturnType<typeof useGetAvailabilityHoursLazyQuery>;
export type GetAvailabilityHoursQueryResult = Apollo.QueryResult<G_GetAvailabilityHoursQuery, G_GetAvailabilityHoursQueryVariables>;
export function refetchGetAvailabilityHoursQuery(variables: G_GetAvailabilityHoursQueryVariables) {
      return { query: GetAvailabilityHoursDocument, variables: variables }
    }
export const CalendarSetAvailabilityDocument = gql`
    mutation calendarSetAvailability($availableHours: [CalendarSetAvailabilityInput], $profile: ID!, $calendar: ID!) {
  calendarSetAvailability(
    availableHours: $availableHours
    profile: $profile
    calendar: $calendar
  ) {
    id
    weekday
    startTime
    endTime
  }
}
    `;
export type G_CalendarSetAvailabilityMutationFn = Apollo.MutationFunction<G_CalendarSetAvailabilityMutation, G_CalendarSetAvailabilityMutationVariables>;

/**
 * __useCalendarSetAvailabilityMutation__
 *
 * To run a mutation, you first call `useCalendarSetAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarSetAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarSetAvailabilityMutation, { data, loading, error }] = useCalendarSetAvailabilityMutation({
 *   variables: {
 *      availableHours: // value for 'availableHours'
 *      profile: // value for 'profile'
 *      calendar: // value for 'calendar'
 *   },
 * });
 */
export function useCalendarSetAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<G_CalendarSetAvailabilityMutation, G_CalendarSetAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_CalendarSetAvailabilityMutation, G_CalendarSetAvailabilityMutationVariables>(CalendarSetAvailabilityDocument, options);
      }
export type CalendarSetAvailabilityMutationHookResult = ReturnType<typeof useCalendarSetAvailabilityMutation>;
export type CalendarSetAvailabilityMutationResult = Apollo.MutationResult<G_CalendarSetAvailabilityMutation>;
export type CalendarSetAvailabilityMutationOptions = Apollo.BaseMutationOptions<G_CalendarSetAvailabilityMutation, G_CalendarSetAvailabilityMutationVariables>;
export const ListingGetAvailableHoursDocument = gql`
    query listingGetAvailableHours($listingItemOption: ID!, $startDate: DateTime!, $endDate: DateTime!) {
  listingGetAvailableHours(
    listingItemOption: $listingItemOption
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

/**
 * __useListingGetAvailableHoursQuery__
 *
 * To run a query within a React component, call `useListingGetAvailableHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingGetAvailableHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingGetAvailableHoursQuery({
 *   variables: {
 *      listingItemOption: // value for 'listingItemOption'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useListingGetAvailableHoursQuery(baseOptions: Apollo.QueryHookOptions<G_ListingGetAvailableHoursQuery, G_ListingGetAvailableHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ListingGetAvailableHoursQuery, G_ListingGetAvailableHoursQueryVariables>(ListingGetAvailableHoursDocument, options);
      }
export function useListingGetAvailableHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ListingGetAvailableHoursQuery, G_ListingGetAvailableHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ListingGetAvailableHoursQuery, G_ListingGetAvailableHoursQueryVariables>(ListingGetAvailableHoursDocument, options);
        }
export type ListingGetAvailableHoursQueryHookResult = ReturnType<typeof useListingGetAvailableHoursQuery>;
export type ListingGetAvailableHoursLazyQueryHookResult = ReturnType<typeof useListingGetAvailableHoursLazyQuery>;
export type ListingGetAvailableHoursQueryResult = Apollo.QueryResult<G_ListingGetAvailableHoursQuery, G_ListingGetAvailableHoursQueryVariables>;
export function refetchListingGetAvailableHoursQuery(variables: G_ListingGetAvailableHoursQueryVariables) {
      return { query: ListingGetAvailableHoursDocument, variables: variables }
    }
export const BookDocument = gql`
    mutation book($bookedTime: DateTime, $cardCvx: String, $cardExpirationDate: String, $cardNumber: String, $cardType: String, $cardholderName: String, $couponCode: String, $listingItemOption: ID!, $listingItemSession: ID, $location: ID, $message: String, $profile: ID!, $returnUrl: String, $acceptHeader: String, $javaEnabled: Boolean, $language: String, $colorDepth: Int, $screenHeight: Int, $screenWidth: Int, $timezoneOffset: String, $userAgent: String, $javascriptEnabled: Boolean) {
  book(
    bookedTime: $bookedTime
    cardCvx: $cardCvx
    cardExpirationDate: $cardExpirationDate
    cardNumber: $cardNumber
    cardType: $cardType
    cardholderName: $cardholderName
    couponCode: $couponCode
    listingItemOption: $listingItemOption
    listingItemSession: $listingItemSession
    location: $location
    message: $message
    profile: $profile
    returnUrl: $returnUrl
    acceptHeader: $acceptHeader
    javaEnabled: $javaEnabled
    language: $language
    colorDepth: $colorDepth
    screenHeight: $screenHeight
    screenWidth: $screenWidth
    timezoneOffset: $timezoneOffset
    userAgent: $userAgent
    javascriptEnabled: $javascriptEnabled
  ) {
    transactionId
    threedsEnabled
    threedsUrl
    booking {
      id
      status
    }
    transaction {
      id
      status
    }
  }
}
    `;
export type G_BookMutationFn = Apollo.MutationFunction<G_BookMutation, G_BookMutationVariables>;

/**
 * __useBookMutation__
 *
 * To run a mutation, you first call `useBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookMutation, { data, loading, error }] = useBookMutation({
 *   variables: {
 *      bookedTime: // value for 'bookedTime'
 *      cardCvx: // value for 'cardCvx'
 *      cardExpirationDate: // value for 'cardExpirationDate'
 *      cardNumber: // value for 'cardNumber'
 *      cardType: // value for 'cardType'
 *      cardholderName: // value for 'cardholderName'
 *      couponCode: // value for 'couponCode'
 *      listingItemOption: // value for 'listingItemOption'
 *      listingItemSession: // value for 'listingItemSession'
 *      location: // value for 'location'
 *      message: // value for 'message'
 *      profile: // value for 'profile'
 *      returnUrl: // value for 'returnUrl'
 *      acceptHeader: // value for 'acceptHeader'
 *      javaEnabled: // value for 'javaEnabled'
 *      language: // value for 'language'
 *      colorDepth: // value for 'colorDepth'
 *      screenHeight: // value for 'screenHeight'
 *      screenWidth: // value for 'screenWidth'
 *      timezoneOffset: // value for 'timezoneOffset'
 *      userAgent: // value for 'userAgent'
 *      javascriptEnabled: // value for 'javascriptEnabled'
 *   },
 * });
 */
export function useBookMutation(baseOptions?: Apollo.MutationHookOptions<G_BookMutation, G_BookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_BookMutation, G_BookMutationVariables>(BookDocument, options);
      }
export type BookMutationHookResult = ReturnType<typeof useBookMutation>;
export type BookMutationResult = Apollo.MutationResult<G_BookMutation>;
export type BookMutationOptions = Apollo.BaseMutationOptions<G_BookMutation, G_BookMutationVariables>;
export const GetTransactionByTransactionIdDocument = gql`
    query getTransactionByTransactionId($mangopayPayinId: String!) {
  transactionsAll(mangopayPayinId: $mangopayPayinId) {
    edges {
      node {
        id
        type
        status
        booking {
          id
          referenceCode
          listingTitle
          listingItemOption {
            listingItem {
              listing {
                slug
              }
            }
          }
        }
        threedsEnabled
        amount
        amountCurrency
      }
    }
  }
}
    `;

/**
 * __useGetTransactionByTransactionIdQuery__
 *
 * To run a query within a React component, call `useGetTransactionByTransactionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionByTransactionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionByTransactionIdQuery({
 *   variables: {
 *      mangopayPayinId: // value for 'mangopayPayinId'
 *   },
 * });
 */
export function useGetTransactionByTransactionIdQuery(baseOptions: Apollo.QueryHookOptions<G_GetTransactionByTransactionIdQuery, G_GetTransactionByTransactionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetTransactionByTransactionIdQuery, G_GetTransactionByTransactionIdQueryVariables>(GetTransactionByTransactionIdDocument, options);
      }
export function useGetTransactionByTransactionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetTransactionByTransactionIdQuery, G_GetTransactionByTransactionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetTransactionByTransactionIdQuery, G_GetTransactionByTransactionIdQueryVariables>(GetTransactionByTransactionIdDocument, options);
        }
export type GetTransactionByTransactionIdQueryHookResult = ReturnType<typeof useGetTransactionByTransactionIdQuery>;
export type GetTransactionByTransactionIdLazyQueryHookResult = ReturnType<typeof useGetTransactionByTransactionIdLazyQuery>;
export type GetTransactionByTransactionIdQueryResult = Apollo.QueryResult<G_GetTransactionByTransactionIdQuery, G_GetTransactionByTransactionIdQueryVariables>;
export function refetchGetTransactionByTransactionIdQuery(variables: G_GetTransactionByTransactionIdQueryVariables) {
      return { query: GetTransactionByTransactionIdDocument, variables: variables }
    }
export const BookingByIdDocument = gql`
    query bookingById($bookingId: ID!) {
  booking(id: $bookingId) {
    id
    createdAt
    referenceCode
    status
    attendeeProfile {
      id
      slug
      firstName
      lastName
      email
      profilePictureUri
    }
    listingItemOption {
      listingItem {
        listing {
          id
          category {
            name
            nameDe
          }
          description
          profile {
            id
            slug
            firstName
            lastName
            email
            profilePictureUri
          }
          coverImageUri
          reviews {
            edges {
              node {
                createdAt
                rating
                content
                profile {
                  id
                }
              }
            }
          }
        }
      }
    }
    participationType
    type
    bookedDate
    priceTotal
    priceFinal
    discountDetails {
      title
      description
      couponCode
    }
    duration
    listingTitle
    bookedAddressTitle
    bookedAddressAddressLine1
    bookedAddressAddressLine2
    bookedAddressPostalCode
    bookedAddressCity {
      id
      name
    }
    bookedAddressCountry {
      id
      name
    }
    bookedAddressGeolocation
    bookedAddressOnlineMeetingLink
    bookedAddressOnlineMeetingPin
    bookedAddressOnlineMeetingIntroduction
    bookingCreationDate
    messages {
      edges {
        node {
          id
          type
          date
          body
          fromProfile {
            id
          }
          toProfile {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBookingByIdQuery__
 *
 * To run a query within a React component, call `useBookingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingByIdQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useBookingByIdQuery(baseOptions: Apollo.QueryHookOptions<G_BookingByIdQuery, G_BookingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_BookingByIdQuery, G_BookingByIdQueryVariables>(BookingByIdDocument, options);
      }
export function useBookingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_BookingByIdQuery, G_BookingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_BookingByIdQuery, G_BookingByIdQueryVariables>(BookingByIdDocument, options);
        }
export type BookingByIdQueryHookResult = ReturnType<typeof useBookingByIdQuery>;
export type BookingByIdLazyQueryHookResult = ReturnType<typeof useBookingByIdLazyQuery>;
export type BookingByIdQueryResult = Apollo.QueryResult<G_BookingByIdQuery, G_BookingByIdQueryVariables>;
export function refetchBookingByIdQuery(variables: G_BookingByIdQueryVariables) {
      return { query: BookingByIdDocument, variables: variables }
    }
export const BookingsByExpertDocument = gql`
    query bookingsByExpert($profileId: ID!) {
  bookingsByExpert(profile: $profileId) {
    id
    createdAt
    referenceCode
    status
    attendeeProfile {
      id
      slug
      firstName
      lastName
      profilePictureUri
    }
    participationType
    type
    bookedDate
    priceTotal
    priceFinal
    duration
    listingTitle
    bookingCreationDate
  }
}
    `;

/**
 * __useBookingsByExpertQuery__
 *
 * To run a query within a React component, call `useBookingsByExpertQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsByExpertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsByExpertQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useBookingsByExpertQuery(baseOptions: Apollo.QueryHookOptions<G_BookingsByExpertQuery, G_BookingsByExpertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_BookingsByExpertQuery, G_BookingsByExpertQueryVariables>(BookingsByExpertDocument, options);
      }
export function useBookingsByExpertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_BookingsByExpertQuery, G_BookingsByExpertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_BookingsByExpertQuery, G_BookingsByExpertQueryVariables>(BookingsByExpertDocument, options);
        }
export type BookingsByExpertQueryHookResult = ReturnType<typeof useBookingsByExpertQuery>;
export type BookingsByExpertLazyQueryHookResult = ReturnType<typeof useBookingsByExpertLazyQuery>;
export type BookingsByExpertQueryResult = Apollo.QueryResult<G_BookingsByExpertQuery, G_BookingsByExpertQueryVariables>;
export function refetchBookingsByExpertQuery(variables: G_BookingsByExpertQueryVariables) {
      return { query: BookingsByExpertDocument, variables: variables }
    }
export const BookingsByProfileDocument = gql`
    query bookingsByProfile($attendeeProfileId: ID!) {
  bookingsAll(attendeeProfile: $attendeeProfileId) {
    edges {
      node {
        id
        createdAt
        referenceCode
        status
        attendeeProfile {
          id
          firstName
          lastName
          profilePictureUri
        }
        listingItemOption {
          listingItem {
            listing {
              profile {
                id
                slug
                firstName
                lastName
                profilePictureUri
              }
              coverImageUri
            }
          }
        }
        participationType
        type
        bookedDate
        priceTotal
        priceFinal
        duration
        listingTitle
        bookedAddressTitle
        bookedAddressAddressLine1
        bookedAddressAddressLine2
        bookedAddressPostalCode
        bookedAddressCity {
          id
          name
        }
        bookedAddressCountry {
          id
          name
        }
        bookedAddressGeolocation
        bookedAddressOnlineMeetingLink
        bookedAddressOnlineMeetingPin
        bookedAddressOnlineMeetingIntroduction
        bookingCreationDate
        messages {
          edges {
            node {
              id
              type
              date
              body
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBookingsByProfileQuery__
 *
 * To run a query within a React component, call `useBookingsByProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsByProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsByProfileQuery({
 *   variables: {
 *      attendeeProfileId: // value for 'attendeeProfileId'
 *   },
 * });
 */
export function useBookingsByProfileQuery(baseOptions: Apollo.QueryHookOptions<G_BookingsByProfileQuery, G_BookingsByProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_BookingsByProfileQuery, G_BookingsByProfileQueryVariables>(BookingsByProfileDocument, options);
      }
export function useBookingsByProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_BookingsByProfileQuery, G_BookingsByProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_BookingsByProfileQuery, G_BookingsByProfileQueryVariables>(BookingsByProfileDocument, options);
        }
export type BookingsByProfileQueryHookResult = ReturnType<typeof useBookingsByProfileQuery>;
export type BookingsByProfileLazyQueryHookResult = ReturnType<typeof useBookingsByProfileLazyQuery>;
export type BookingsByProfileQueryResult = Apollo.QueryResult<G_BookingsByProfileQuery, G_BookingsByProfileQueryVariables>;
export function refetchBookingsByProfileQuery(variables: G_BookingsByProfileQueryVariables) {
      return { query: BookingsByProfileDocument, variables: variables }
    }
export const BookingMessageAddDocument = gql`
    mutation bookingMessageAdd($body: String!, $booking: ID!, $fromProfile: ID!, $type: BookMessageTypes!) {
  bookingMessageAdd(
    body: $body
    booking: $booking
    fromProfile: $fromProfile
    type: $type
  ) {
    id
    date
    body
  }
}
    `;
export type G_BookingMessageAddMutationFn = Apollo.MutationFunction<G_BookingMessageAddMutation, G_BookingMessageAddMutationVariables>;

/**
 * __useBookingMessageAddMutation__
 *
 * To run a mutation, you first call `useBookingMessageAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingMessageAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingMessageAddMutation, { data, loading, error }] = useBookingMessageAddMutation({
 *   variables: {
 *      body: // value for 'body'
 *      booking: // value for 'booking'
 *      fromProfile: // value for 'fromProfile'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useBookingMessageAddMutation(baseOptions?: Apollo.MutationHookOptions<G_BookingMessageAddMutation, G_BookingMessageAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_BookingMessageAddMutation, G_BookingMessageAddMutationVariables>(BookingMessageAddDocument, options);
      }
export type BookingMessageAddMutationHookResult = ReturnType<typeof useBookingMessageAddMutation>;
export type BookingMessageAddMutationResult = Apollo.MutationResult<G_BookingMessageAddMutation>;
export type BookingMessageAddMutationOptions = Apollo.BaseMutationOptions<G_BookingMessageAddMutation, G_BookingMessageAddMutationVariables>;
export const BookingCalculatePriceDocument = gql`
    mutation bookingCalculatePrice($couponCode: String, $listingItemOption: ID!) {
  bookingCalculatePrice(
    couponCode: $couponCode
    listingItemOption: $listingItemOption
  ) {
    appliedCouponCodes
    priceTotal
    priceFinal
    paymentRequired
    messages {
      message
    }
    details {
      title
      description
      amount
      effect
    }
  }
}
    `;
export type G_BookingCalculatePriceMutationFn = Apollo.MutationFunction<G_BookingCalculatePriceMutation, G_BookingCalculatePriceMutationVariables>;

/**
 * __useBookingCalculatePriceMutation__
 *
 * To run a mutation, you first call `useBookingCalculatePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingCalculatePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingCalculatePriceMutation, { data, loading, error }] = useBookingCalculatePriceMutation({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *      listingItemOption: // value for 'listingItemOption'
 *   },
 * });
 */
export function useBookingCalculatePriceMutation(baseOptions?: Apollo.MutationHookOptions<G_BookingCalculatePriceMutation, G_BookingCalculatePriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_BookingCalculatePriceMutation, G_BookingCalculatePriceMutationVariables>(BookingCalculatePriceDocument, options);
      }
export type BookingCalculatePriceMutationHookResult = ReturnType<typeof useBookingCalculatePriceMutation>;
export type BookingCalculatePriceMutationResult = Apollo.MutationResult<G_BookingCalculatePriceMutation>;
export type BookingCalculatePriceMutationOptions = Apollo.BaseMutationOptions<G_BookingCalculatePriceMutation, G_BookingCalculatePriceMutationVariables>;
export const StripeCheckoutSessionDocument = gql`
    mutation StripeCheckoutSession($profile_id: ID!, $listing_item_option_id: ID!, $listing_item_session_id: ID, $coupon_code: String, $location_id: ID, $booked_time: DateTime, $success_url: String!, $cancel_url: String!, $browser_info: String, $message: String) {
  checkoutSession(
    profileId: $profile_id
    listingItemOptionId: $listing_item_option_id
    listingItemSessionId: $listing_item_session_id
    couponCode: $coupon_code
    locationId: $location_id
    bookedTime: $booked_time
    successUrl: $success_url
    cancelUrl: $cancel_url
    browserInfo: $browser_info
    message: $message
  ) {
    clientSecret
  }
}
    `;
export type G_StripeCheckoutSessionMutationFn = Apollo.MutationFunction<G_StripeCheckoutSessionMutation, G_StripeCheckoutSessionMutationVariables>;

/**
 * __useStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCheckoutSessionMutation, { data, loading, error }] = useStripeCheckoutSessionMutation({
 *   variables: {
 *      profile_id: // value for 'profile_id'
 *      listing_item_option_id: // value for 'listing_item_option_id'
 *      listing_item_session_id: // value for 'listing_item_session_id'
 *      coupon_code: // value for 'coupon_code'
 *      location_id: // value for 'location_id'
 *      booked_time: // value for 'booked_time'
 *      success_url: // value for 'success_url'
 *      cancel_url: // value for 'cancel_url'
 *      browser_info: // value for 'browser_info'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useStripeCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<G_StripeCheckoutSessionMutation, G_StripeCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_StripeCheckoutSessionMutation, G_StripeCheckoutSessionMutationVariables>(StripeCheckoutSessionDocument, options);
      }
export type StripeCheckoutSessionMutationHookResult = ReturnType<typeof useStripeCheckoutSessionMutation>;
export type StripeCheckoutSessionMutationResult = Apollo.MutationResult<G_StripeCheckoutSessionMutation>;
export type StripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<G_StripeCheckoutSessionMutation, G_StripeCheckoutSessionMutationVariables>;
export const StripeCreateAccountLinkDocument = gql`
    mutation StripeCreateAccountLink($profile_id: ID!) {
  createAccountLink(profileId: $profile_id) {
    oneTimeLink
  }
}
    `;
export type G_StripeCreateAccountLinkMutationFn = Apollo.MutationFunction<G_StripeCreateAccountLinkMutation, G_StripeCreateAccountLinkMutationVariables>;

/**
 * __useStripeCreateAccountLinkMutation__
 *
 * To run a mutation, you first call `useStripeCreateAccountLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreateAccountLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreateAccountLinkMutation, { data, loading, error }] = useStripeCreateAccountLinkMutation({
 *   variables: {
 *      profile_id: // value for 'profile_id'
 *   },
 * });
 */
export function useStripeCreateAccountLinkMutation(baseOptions?: Apollo.MutationHookOptions<G_StripeCreateAccountLinkMutation, G_StripeCreateAccountLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_StripeCreateAccountLinkMutation, G_StripeCreateAccountLinkMutationVariables>(StripeCreateAccountLinkDocument, options);
      }
export type StripeCreateAccountLinkMutationHookResult = ReturnType<typeof useStripeCreateAccountLinkMutation>;
export type StripeCreateAccountLinkMutationResult = Apollo.MutationResult<G_StripeCreateAccountLinkMutation>;
export type StripeCreateAccountLinkMutationOptions = Apollo.BaseMutationOptions<G_StripeCreateAccountLinkMutation, G_StripeCreateAccountLinkMutationVariables>;
export const ExperiencesRegisterDocument = gql`
    mutation experiencesRegister($referenceCode: String!) {
  experiencesRegister(referenceCode: $referenceCode) {
    referenceCode
  }
}
    `;
export type G_ExperiencesRegisterMutationFn = Apollo.MutationFunction<G_ExperiencesRegisterMutation, G_ExperiencesRegisterMutationVariables>;

/**
 * __useExperiencesRegisterMutation__
 *
 * To run a mutation, you first call `useExperiencesRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperiencesRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experiencesRegisterMutation, { data, loading, error }] = useExperiencesRegisterMutation({
 *   variables: {
 *      referenceCode: // value for 'referenceCode'
 *   },
 * });
 */
export function useExperiencesRegisterMutation(baseOptions?: Apollo.MutationHookOptions<G_ExperiencesRegisterMutation, G_ExperiencesRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ExperiencesRegisterMutation, G_ExperiencesRegisterMutationVariables>(ExperiencesRegisterDocument, options);
      }
export type ExperiencesRegisterMutationHookResult = ReturnType<typeof useExperiencesRegisterMutation>;
export type ExperiencesRegisterMutationResult = Apollo.MutationResult<G_ExperiencesRegisterMutation>;
export type ExperiencesRegisterMutationOptions = Apollo.BaseMutationOptions<G_ExperiencesRegisterMutation, G_ExperiencesRegisterMutationVariables>;
export const CheckExperienceDocument = gql`
    query checkExperience($referenceCode: String!) {
  result: checkExperience(referenceCode: $referenceCode) {
    userRegistered
  }
}
    `;

/**
 * __useCheckExperienceQuery__
 *
 * To run a query within a React component, call `useCheckExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExperienceQuery({
 *   variables: {
 *      referenceCode: // value for 'referenceCode'
 *   },
 * });
 */
export function useCheckExperienceQuery(baseOptions: Apollo.QueryHookOptions<G_CheckExperienceQuery, G_CheckExperienceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_CheckExperienceQuery, G_CheckExperienceQueryVariables>(CheckExperienceDocument, options);
      }
export function useCheckExperienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_CheckExperienceQuery, G_CheckExperienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_CheckExperienceQuery, G_CheckExperienceQueryVariables>(CheckExperienceDocument, options);
        }
export type CheckExperienceQueryHookResult = ReturnType<typeof useCheckExperienceQuery>;
export type CheckExperienceLazyQueryHookResult = ReturnType<typeof useCheckExperienceLazyQuery>;
export type CheckExperienceQueryResult = Apollo.QueryResult<G_CheckExperienceQuery, G_CheckExperienceQueryVariables>;
export function refetchCheckExperienceQuery(variables: G_CheckExperienceQueryVariables) {
      return { query: CheckExperienceDocument, variables: variables }
    }
export const ExperiencesForCarouselDocument = gql`
    query experiencesForCarousel {
  result: experiencesAll(schedule: ALL) {
    edges {
      node {
        id
        slug
        title
        coverUri
        price
        discount
        paid
        hostedBySingleExperts
        sessions {
          edges {
            node {
              title
              startsAt
              endsAt
              isAvailableOnline
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useExperiencesForCarouselQuery__
 *
 * To run a query within a React component, call `useExperiencesForCarouselQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperiencesForCarouselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperiencesForCarouselQuery({
 *   variables: {
 *   },
 * });
 */
export function useExperiencesForCarouselQuery(baseOptions?: Apollo.QueryHookOptions<G_ExperiencesForCarouselQuery, G_ExperiencesForCarouselQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ExperiencesForCarouselQuery, G_ExperiencesForCarouselQueryVariables>(ExperiencesForCarouselDocument, options);
      }
export function useExperiencesForCarouselLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ExperiencesForCarouselQuery, G_ExperiencesForCarouselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ExperiencesForCarouselQuery, G_ExperiencesForCarouselQueryVariables>(ExperiencesForCarouselDocument, options);
        }
export type ExperiencesForCarouselQueryHookResult = ReturnType<typeof useExperiencesForCarouselQuery>;
export type ExperiencesForCarouselLazyQueryHookResult = ReturnType<typeof useExperiencesForCarouselLazyQuery>;
export type ExperiencesForCarouselQueryResult = Apollo.QueryResult<G_ExperiencesForCarouselQuery, G_ExperiencesForCarouselQueryVariables>;
export function refetchExperiencesForCarouselQuery(variables?: G_ExperiencesForCarouselQueryVariables) {
      return { query: ExperiencesForCarouselDocument, variables: variables }
    }
export const ExperiencesPastDocument = gql`
    query experiencesPast {
  result: experiencesAll(schedule: PAST) {
    edges {
      node {
        id
        slug
        title
        coverUri
        price
        discount
        paid
        endsAt
      }
    }
  }
}
    `;

/**
 * __useExperiencesPastQuery__
 *
 * To run a query within a React component, call `useExperiencesPastQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperiencesPastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperiencesPastQuery({
 *   variables: {
 *   },
 * });
 */
export function useExperiencesPastQuery(baseOptions?: Apollo.QueryHookOptions<G_ExperiencesPastQuery, G_ExperiencesPastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ExperiencesPastQuery, G_ExperiencesPastQueryVariables>(ExperiencesPastDocument, options);
      }
export function useExperiencesPastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ExperiencesPastQuery, G_ExperiencesPastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ExperiencesPastQuery, G_ExperiencesPastQueryVariables>(ExperiencesPastDocument, options);
        }
export type ExperiencesPastQueryHookResult = ReturnType<typeof useExperiencesPastQuery>;
export type ExperiencesPastLazyQueryHookResult = ReturnType<typeof useExperiencesPastLazyQuery>;
export type ExperiencesPastQueryResult = Apollo.QueryResult<G_ExperiencesPastQuery, G_ExperiencesPastQueryVariables>;
export function refetchExperiencesPastQuery(variables?: G_ExperiencesPastQueryVariables) {
      return { query: ExperiencesPastDocument, variables: variables }
    }
export const ExperiencesFutureDocument = gql`
    query experiencesFuture {
  result: experiencesAll(schedule: FUTURE) {
    edges {
      node {
        id
        referenceCode
        title
        slug
        description
        coverUri
        published
        paid
        price
        discount
        hostedBySingleExperts
        sessions {
          edges {
            node {
              id
              title
              description
              category {
                name
                nameDe
              }
              startsAt
              endsAt
              profile {
                slug
                firstName
                lastName
                locationCity {
                  name
                }
                locationCountry {
                  name
                }
                profilePictureUri
              }
              isAvailableOnline
              onlineMeetingLink
              onlineMeetingPin
              onlineMeetingIntroduction
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useExperiencesFutureQuery__
 *
 * To run a query within a React component, call `useExperiencesFutureQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperiencesFutureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperiencesFutureQuery({
 *   variables: {
 *   },
 * });
 */
export function useExperiencesFutureQuery(baseOptions?: Apollo.QueryHookOptions<G_ExperiencesFutureQuery, G_ExperiencesFutureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ExperiencesFutureQuery, G_ExperiencesFutureQueryVariables>(ExperiencesFutureDocument, options);
      }
export function useExperiencesFutureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ExperiencesFutureQuery, G_ExperiencesFutureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ExperiencesFutureQuery, G_ExperiencesFutureQueryVariables>(ExperiencesFutureDocument, options);
        }
export type ExperiencesFutureQueryHookResult = ReturnType<typeof useExperiencesFutureQuery>;
export type ExperiencesFutureLazyQueryHookResult = ReturnType<typeof useExperiencesFutureLazyQuery>;
export type ExperiencesFutureQueryResult = Apollo.QueryResult<G_ExperiencesFutureQuery, G_ExperiencesFutureQueryVariables>;
export function refetchExperiencesFutureQuery(variables?: G_ExperiencesFutureQueryVariables) {
      return { query: ExperiencesFutureDocument, variables: variables }
    }
export const ExperiencesOnDemandDocument = gql`
    query experiencesOnDemand {
  result: experiencesAll(schedule: ON_DEMAND) {
    edges {
      node {
        id
        referenceCode
        title
        slug
        description
        coverUri
        published
        paid
        price
        discount
        hostedBySingleExperts
        sessions {
          edges {
            node {
              id
              title
              description
              category {
                name
                nameDe
              }
              startsAt
              endsAt
              profile {
                slug
                firstName
                lastName
                locationCity {
                  name
                }
                locationCountry {
                  name
                }
                profilePictureUri
              }
              isAvailableOnline
              onlineMeetingLink
              onlineMeetingPin
              onlineMeetingIntroduction
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useExperiencesOnDemandQuery__
 *
 * To run a query within a React component, call `useExperiencesOnDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperiencesOnDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperiencesOnDemandQuery({
 *   variables: {
 *   },
 * });
 */
export function useExperiencesOnDemandQuery(baseOptions?: Apollo.QueryHookOptions<G_ExperiencesOnDemandQuery, G_ExperiencesOnDemandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ExperiencesOnDemandQuery, G_ExperiencesOnDemandQueryVariables>(ExperiencesOnDemandDocument, options);
      }
export function useExperiencesOnDemandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ExperiencesOnDemandQuery, G_ExperiencesOnDemandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ExperiencesOnDemandQuery, G_ExperiencesOnDemandQueryVariables>(ExperiencesOnDemandDocument, options);
        }
export type ExperiencesOnDemandQueryHookResult = ReturnType<typeof useExperiencesOnDemandQuery>;
export type ExperiencesOnDemandLazyQueryHookResult = ReturnType<typeof useExperiencesOnDemandLazyQuery>;
export type ExperiencesOnDemandQueryResult = Apollo.QueryResult<G_ExperiencesOnDemandQuery, G_ExperiencesOnDemandQueryVariables>;
export function refetchExperiencesOnDemandQuery(variables?: G_ExperiencesOnDemandQueryVariables) {
      return { query: ExperiencesOnDemandDocument, variables: variables }
    }
export const ExperienceBySlugDocument = gql`
    query experienceBySlug($slug: String!) {
  result: experienceBySlug(slug: $slug) {
    edges {
      node {
        id
        referenceCode
        title
        slug
        description
        coverUri
        published
        paid
        price
        discount
        hostedBySingleExperts
        isPast
        sessions {
          edges {
            node {
              id
              title
              description
              category {
                name
                nameDe
              }
              startsAt
              endsAt
              profile {
                slug
                firstName
                lastName
                locationCity {
                  name
                }
                locationCountry {
                  name
                }
                profilePictureUri
              }
              isAvailableOnline
              onlineMeetingLink
              onlineMeetingPin
              onlineMeetingIntroduction
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useExperienceBySlugQuery__
 *
 * To run a query within a React component, call `useExperienceBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useExperienceBySlugQuery(baseOptions: Apollo.QueryHookOptions<G_ExperienceBySlugQuery, G_ExperienceBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ExperienceBySlugQuery, G_ExperienceBySlugQueryVariables>(ExperienceBySlugDocument, options);
      }
export function useExperienceBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ExperienceBySlugQuery, G_ExperienceBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ExperienceBySlugQuery, G_ExperienceBySlugQueryVariables>(ExperienceBySlugDocument, options);
        }
export type ExperienceBySlugQueryHookResult = ReturnType<typeof useExperienceBySlugQuery>;
export type ExperienceBySlugLazyQueryHookResult = ReturnType<typeof useExperienceBySlugLazyQuery>;
export type ExperienceBySlugQueryResult = Apollo.QueryResult<G_ExperienceBySlugQuery, G_ExperienceBySlugQueryVariables>;
export function refetchExperienceBySlugQuery(variables: G_ExperienceBySlugQueryVariables) {
      return { query: ExperienceBySlugDocument, variables: variables }
    }
export const PublicProfileBySlugDocument = gql`
    query publicProfileBySlug($profileSlug: String!) {
  profilesAll(slug: $profileSlug, first: 1) {
    edges {
      node {
        id
        firstName
        lastName
        rating
        role
        profilePictureUri
        coverPictureUri
        createdAt
        birthdate
        bio
        hasAvailability
        serviceFeeRatio
        kycStatus
        locationCity {
          id
          name
        }
        locationCountry {
          id
          name
        }
        languages {
          edges {
            node {
              id
              name
              isoCode
            }
          }
        }
        listings {
          edges {
            node {
              id
              slug
              title
              description
              type
              status
              items {
                edges {
                  node {
                    isAvailableOnline
                    availableSeats
                    locations {
                      edges {
                        node {
                          id
                        }
                      }
                    }
                  }
                }
              }
              media {
                edges {
                  node {
                    title
                    uri
                  }
                }
              }
              upcomingSessions {
                edges {
                  node {
                    occurrence {
                      listingItemOption {
                        listingItem {
                          isAvailableOnline
                          locations {
                            edges {
                              node {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePublicProfileBySlugQuery__
 *
 * To run a query within a React component, call `usePublicProfileBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicProfileBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicProfileBySlugQuery({
 *   variables: {
 *      profileSlug: // value for 'profileSlug'
 *   },
 * });
 */
export function usePublicProfileBySlugQuery(baseOptions: Apollo.QueryHookOptions<G_PublicProfileBySlugQuery, G_PublicProfileBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_PublicProfileBySlugQuery, G_PublicProfileBySlugQueryVariables>(PublicProfileBySlugDocument, options);
      }
export function usePublicProfileBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_PublicProfileBySlugQuery, G_PublicProfileBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_PublicProfileBySlugQuery, G_PublicProfileBySlugQueryVariables>(PublicProfileBySlugDocument, options);
        }
export type PublicProfileBySlugQueryHookResult = ReturnType<typeof usePublicProfileBySlugQuery>;
export type PublicProfileBySlugLazyQueryHookResult = ReturnType<typeof usePublicProfileBySlugLazyQuery>;
export type PublicProfileBySlugQueryResult = Apollo.QueryResult<G_PublicProfileBySlugQuery, G_PublicProfileBySlugQueryVariables>;
export function refetchPublicProfileBySlugQuery(variables: G_PublicProfileBySlugQueryVariables) {
      return { query: PublicProfileBySlugDocument, variables: variables }
    }
export const UpdatePublicProfileDocument = gql`
    mutation updatePublicProfile($profile: ID!, $bio: String, $languages: [ID], $locationCity: ID, $coverPictureUri: String, $coverPictureAssetId: String) {
  profileUpdate(
    profile: $profile
    bio: $bio
    languages: $languages
    locationCity: $locationCity
    coverPictureUri: $coverPictureUri
    coverPictureAssetId: $coverPictureAssetId
  ) {
    bio
    locationCity {
      id
      name
    }
    locationCountry {
      id
      name
    }
    languages {
      edges {
        node {
          id
          isoCode
          name
        }
      }
    }
    coverPictureUri
  }
}
    `;
export type G_UpdatePublicProfileMutationFn = Apollo.MutationFunction<G_UpdatePublicProfileMutation, G_UpdatePublicProfileMutationVariables>;

/**
 * __useUpdatePublicProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePublicProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicProfileMutation, { data, loading, error }] = useUpdatePublicProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      bio: // value for 'bio'
 *      languages: // value for 'languages'
 *      locationCity: // value for 'locationCity'
 *      coverPictureUri: // value for 'coverPictureUri'
 *      coverPictureAssetId: // value for 'coverPictureAssetId'
 *   },
 * });
 */
export function useUpdatePublicProfileMutation(baseOptions?: Apollo.MutationHookOptions<G_UpdatePublicProfileMutation, G_UpdatePublicProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_UpdatePublicProfileMutation, G_UpdatePublicProfileMutationVariables>(UpdatePublicProfileDocument, options);
      }
export type UpdatePublicProfileMutationHookResult = ReturnType<typeof useUpdatePublicProfileMutation>;
export type UpdatePublicProfileMutationResult = Apollo.MutationResult<G_UpdatePublicProfileMutation>;
export type UpdatePublicProfileMutationOptions = Apollo.BaseMutationOptions<G_UpdatePublicProfileMutation, G_UpdatePublicProfileMutationVariables>;
export const UpdateAvatarDocument = gql`
    mutation updateAvatar($profileId: ID!, $profilePictureUri: String, $profilePictureAssetId: String) {
  profileUpdate(
    profile: $profileId
    profilePictureUri: $profilePictureUri
    profilePictureAssetId: $profilePictureAssetId
  ) {
    profilePictureUri
  }
}
    `;
export type G_UpdateAvatarMutationFn = Apollo.MutationFunction<G_UpdateAvatarMutation, G_UpdateAvatarMutationVariables>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      profilePictureUri: // value for 'profilePictureUri'
 *      profilePictureAssetId: // value for 'profilePictureAssetId'
 *   },
 * });
 */
export function useUpdateAvatarMutation(baseOptions?: Apollo.MutationHookOptions<G_UpdateAvatarMutation, G_UpdateAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_UpdateAvatarMutation, G_UpdateAvatarMutationVariables>(UpdateAvatarDocument, options);
      }
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<G_UpdateAvatarMutation>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<G_UpdateAvatarMutation, G_UpdateAvatarMutationVariables>;
export const GetStartedCheckListDocument = gql`
    query getStartedCheckList($profileId: ID!) {
  profile(id: $profileId) {
    profilePictureUri
    coverPictureUri
    bio
    kycStatus
    locationCity {
      id
    }
    locationCountry {
      id
    }
    languages {
      edges {
        node {
          id
        }
      }
    }
    listings(first: 1) {
      edges {
        cursor
      }
    }
    isStripeExpertAccountInitialized
  }
}
    `;

/**
 * __useGetStartedCheckListQuery__
 *
 * To run a query within a React component, call `useGetStartedCheckListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStartedCheckListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStartedCheckListQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetStartedCheckListQuery(baseOptions: Apollo.QueryHookOptions<G_GetStartedCheckListQuery, G_GetStartedCheckListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetStartedCheckListQuery, G_GetStartedCheckListQueryVariables>(GetStartedCheckListDocument, options);
      }
export function useGetStartedCheckListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetStartedCheckListQuery, G_GetStartedCheckListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetStartedCheckListQuery, G_GetStartedCheckListQueryVariables>(GetStartedCheckListDocument, options);
        }
export type GetStartedCheckListQueryHookResult = ReturnType<typeof useGetStartedCheckListQuery>;
export type GetStartedCheckListLazyQueryHookResult = ReturnType<typeof useGetStartedCheckListLazyQuery>;
export type GetStartedCheckListQueryResult = Apollo.QueryResult<G_GetStartedCheckListQuery, G_GetStartedCheckListQueryVariables>;
export function refetchGetStartedCheckListQuery(variables: G_GetStartedCheckListQueryVariables) {
      return { query: GetStartedCheckListDocument, variables: variables }
    }
export const GetProfileStatusInfoDocument = gql`
    query getProfileStatusInfo($profileId: ID!) {
  profile(id: $profileId) {
    hasAvailability
    serviceFeeRatio
    kycStatus
  }
}
    `;

/**
 * __useGetProfileStatusInfoQuery__
 *
 * To run a query within a React component, call `useGetProfileStatusInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileStatusInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileStatusInfoQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetProfileStatusInfoQuery(baseOptions: Apollo.QueryHookOptions<G_GetProfileStatusInfoQuery, G_GetProfileStatusInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetProfileStatusInfoQuery, G_GetProfileStatusInfoQueryVariables>(GetProfileStatusInfoDocument, options);
      }
export function useGetProfileStatusInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetProfileStatusInfoQuery, G_GetProfileStatusInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetProfileStatusInfoQuery, G_GetProfileStatusInfoQueryVariables>(GetProfileStatusInfoDocument, options);
        }
export type GetProfileStatusInfoQueryHookResult = ReturnType<typeof useGetProfileStatusInfoQuery>;
export type GetProfileStatusInfoLazyQueryHookResult = ReturnType<typeof useGetProfileStatusInfoLazyQuery>;
export type GetProfileStatusInfoQueryResult = Apollo.QueryResult<G_GetProfileStatusInfoQuery, G_GetProfileStatusInfoQueryVariables>;
export function refetchGetProfileStatusInfoQuery(variables: G_GetProfileStatusInfoQueryVariables) {
      return { query: GetProfileStatusInfoDocument, variables: variables }
    }
export const AddCustomEventDocument = gql`
    mutation addCustomEvent($profileId: ID!, $title: String!, $description: String!, $startTime: DateTime!, $endTime: DateTime!) {
  calendarCustomEventAdd(
    profile: $profileId
    title: $title
    description: $description
    startTime: $startTime
    endTime: $endTime
  ) {
    id
    type
  }
}
    `;
export type G_AddCustomEventMutationFn = Apollo.MutationFunction<G_AddCustomEventMutation, G_AddCustomEventMutationVariables>;

/**
 * __useAddCustomEventMutation__
 *
 * To run a mutation, you first call `useAddCustomEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomEventMutation, { data, loading, error }] = useAddCustomEventMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useAddCustomEventMutation(baseOptions?: Apollo.MutationHookOptions<G_AddCustomEventMutation, G_AddCustomEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_AddCustomEventMutation, G_AddCustomEventMutationVariables>(AddCustomEventDocument, options);
      }
export type AddCustomEventMutationHookResult = ReturnType<typeof useAddCustomEventMutation>;
export type AddCustomEventMutationResult = Apollo.MutationResult<G_AddCustomEventMutation>;
export type AddCustomEventMutationOptions = Apollo.BaseMutationOptions<G_AddCustomEventMutation, G_AddCustomEventMutationVariables>;
export const UpdateCustomEventDocument = gql`
    mutation updateCustomEvent($profileId: ID!, $calendarEventId: ID!, $title: String!, $description: String!, $startTime: DateTime!, $endTime: DateTime!) {
  calendarCustomEventUpdate(
    profile: $profileId
    calendarEvent: $calendarEventId
    title: $title
    description: $description
    startTime: $startTime
    endTime: $endTime
  ) {
    id
    type
  }
}
    `;
export type G_UpdateCustomEventMutationFn = Apollo.MutationFunction<G_UpdateCustomEventMutation, G_UpdateCustomEventMutationVariables>;

/**
 * __useUpdateCustomEventMutation__
 *
 * To run a mutation, you first call `useUpdateCustomEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomEventMutation, { data, loading, error }] = useUpdateCustomEventMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      calendarEventId: // value for 'calendarEventId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useUpdateCustomEventMutation(baseOptions?: Apollo.MutationHookOptions<G_UpdateCustomEventMutation, G_UpdateCustomEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_UpdateCustomEventMutation, G_UpdateCustomEventMutationVariables>(UpdateCustomEventDocument, options);
      }
export type UpdateCustomEventMutationHookResult = ReturnType<typeof useUpdateCustomEventMutation>;
export type UpdateCustomEventMutationResult = Apollo.MutationResult<G_UpdateCustomEventMutation>;
export type UpdateCustomEventMutationOptions = Apollo.BaseMutationOptions<G_UpdateCustomEventMutation, G_UpdateCustomEventMutationVariables>;
export const RemoveCustomEventDocument = gql`
    mutation removeCustomEvent($profileId: ID!, $calendarEventId: ID!) {
  calendarCustomEventRemove(profile: $profileId, calendarEvent: $calendarEventId) {
    id
  }
}
    `;
export type G_RemoveCustomEventMutationFn = Apollo.MutationFunction<G_RemoveCustomEventMutation, G_RemoveCustomEventMutationVariables>;

/**
 * __useRemoveCustomEventMutation__
 *
 * To run a mutation, you first call `useRemoveCustomEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomEventMutation, { data, loading, error }] = useRemoveCustomEventMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      calendarEventId: // value for 'calendarEventId'
 *   },
 * });
 */
export function useRemoveCustomEventMutation(baseOptions?: Apollo.MutationHookOptions<G_RemoveCustomEventMutation, G_RemoveCustomEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_RemoveCustomEventMutation, G_RemoveCustomEventMutationVariables>(RemoveCustomEventDocument, options);
      }
export type RemoveCustomEventMutationHookResult = ReturnType<typeof useRemoveCustomEventMutation>;
export type RemoveCustomEventMutationResult = Apollo.MutationResult<G_RemoveCustomEventMutation>;
export type RemoveCustomEventMutationOptions = Apollo.BaseMutationOptions<G_RemoveCustomEventMutation, G_RemoveCustomEventMutationVariables>;
export const CalendarOccurrenceRemoveDocument = gql`
    mutation calendarOccurrenceRemove($profileId: ID!, $calendarOccurrenceId: ID!) {
  calendarOccurrenceRemove(
    profile: $profileId
    calendarOccurrence: $calendarOccurrenceId
  ) {
    id
  }
}
    `;
export type G_CalendarOccurrenceRemoveMutationFn = Apollo.MutationFunction<G_CalendarOccurrenceRemoveMutation, G_CalendarOccurrenceRemoveMutationVariables>;

/**
 * __useCalendarOccurrenceRemoveMutation__
 *
 * To run a mutation, you first call `useCalendarOccurrenceRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarOccurrenceRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarOccurrenceRemoveMutation, { data, loading, error }] = useCalendarOccurrenceRemoveMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      calendarOccurrenceId: // value for 'calendarOccurrenceId'
 *   },
 * });
 */
export function useCalendarOccurrenceRemoveMutation(baseOptions?: Apollo.MutationHookOptions<G_CalendarOccurrenceRemoveMutation, G_CalendarOccurrenceRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_CalendarOccurrenceRemoveMutation, G_CalendarOccurrenceRemoveMutationVariables>(CalendarOccurrenceRemoveDocument, options);
      }
export type CalendarOccurrenceRemoveMutationHookResult = ReturnType<typeof useCalendarOccurrenceRemoveMutation>;
export type CalendarOccurrenceRemoveMutationResult = Apollo.MutationResult<G_CalendarOccurrenceRemoveMutation>;
export type CalendarOccurrenceRemoveMutationOptions = Apollo.BaseMutationOptions<G_CalendarOccurrenceRemoveMutation, G_CalendarOccurrenceRemoveMutationVariables>;
export const GetOccurrenceDocument = gql`
    query getOccurrence($profileId: ID!, $calendarOccurrenceId: ID!) {
  getOccurrence(profile: $profileId, calendarOccurrence: $calendarOccurrenceId) {
    id
    startTs
    endTs
    rrulestr
    event {
      id
      title
      description
      type
    }
    listingItemOption {
      price
      listingItem {
        availableSeats
        isAvailableOnline
        locations {
          edges {
            node {
              id
              title
              addressLine1
              postalCode
              city {
                id
                name
              }
              country {
                id
                name
              }
            }
          }
        }
        onlineMeetingLink
        onlineMeetingPin
        onlineMeetingIntroduction
      }
    }
    bookings {
      edges {
        node {
          id
          participationType
          priceTotal
          attendeeProfile {
            slug
            firstName
            lastName
            profilePictureUri
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOccurrenceQuery__
 *
 * To run a query within a React component, call `useGetOccurrenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOccurrenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOccurrenceQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      calendarOccurrenceId: // value for 'calendarOccurrenceId'
 *   },
 * });
 */
export function useGetOccurrenceQuery(baseOptions: Apollo.QueryHookOptions<G_GetOccurrenceQuery, G_GetOccurrenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetOccurrenceQuery, G_GetOccurrenceQueryVariables>(GetOccurrenceDocument, options);
      }
export function useGetOccurrenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetOccurrenceQuery, G_GetOccurrenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetOccurrenceQuery, G_GetOccurrenceQueryVariables>(GetOccurrenceDocument, options);
        }
export type GetOccurrenceQueryHookResult = ReturnType<typeof useGetOccurrenceQuery>;
export type GetOccurrenceLazyQueryHookResult = ReturnType<typeof useGetOccurrenceLazyQuery>;
export type GetOccurrenceQueryResult = Apollo.QueryResult<G_GetOccurrenceQuery, G_GetOccurrenceQueryVariables>;
export function refetchGetOccurrenceQuery(variables: G_GetOccurrenceQueryVariables) {
      return { query: GetOccurrenceDocument, variables: variables }
    }
export const GetCalendarEventsDocument = gql`
    query getCalendarEvents($profileId: ID!, $month: Int!, $year: Int!) {
  getCalendarEvents(profile: $profileId, month: $month, year: $year) {
    id
    startTs
    endTs
    rrulestr
    event {
      id
      type
      title
    }
    bookings {
      edges {
        node {
          id
          participationType
          attendeeProfile {
            slug
            firstName
            lastName
            profilePictureUri
          }
        }
      }
    }
    listingItemOption {
      listingItem {
        availableSeats
        isAvailableOnline
        locations {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCalendarEventsQuery__
 *
 * To run a query within a React component, call `useGetCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarEventsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetCalendarEventsQuery(baseOptions: Apollo.QueryHookOptions<G_GetCalendarEventsQuery, G_GetCalendarEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetCalendarEventsQuery, G_GetCalendarEventsQueryVariables>(GetCalendarEventsDocument, options);
      }
export function useGetCalendarEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetCalendarEventsQuery, G_GetCalendarEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetCalendarEventsQuery, G_GetCalendarEventsQueryVariables>(GetCalendarEventsDocument, options);
        }
export type GetCalendarEventsQueryHookResult = ReturnType<typeof useGetCalendarEventsQuery>;
export type GetCalendarEventsLazyQueryHookResult = ReturnType<typeof useGetCalendarEventsLazyQuery>;
export type GetCalendarEventsQueryResult = Apollo.QueryResult<G_GetCalendarEventsQuery, G_GetCalendarEventsQueryVariables>;
export function refetchGetCalendarEventsQuery(variables: G_GetCalendarEventsQueryVariables) {
      return { query: GetCalendarEventsDocument, variables: variables }
    }
export const GetEventsBySpecificDateDocument = gql`
    query getEventsBySpecificDate($profileId: ID!, $startTime_Gte: Int!, $endTime_Lt: Int!) {
  getEventsBySpecificDate(
    profile: $profileId
    startTs_Gte: $startTime_Gte
    endTs_Lt: $endTime_Lt
  ) {
    edges {
      node {
        id
        startTs
        endTs
        event {
          id
          type
          title
        }
      }
    }
  }
}
    `;

/**
 * __useGetEventsBySpecificDateQuery__
 *
 * To run a query within a React component, call `useGetEventsBySpecificDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsBySpecificDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsBySpecificDateQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      startTime_Gte: // value for 'startTime_Gte'
 *      endTime_Lt: // value for 'endTime_Lt'
 *   },
 * });
 */
export function useGetEventsBySpecificDateQuery(baseOptions: Apollo.QueryHookOptions<G_GetEventsBySpecificDateQuery, G_GetEventsBySpecificDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetEventsBySpecificDateQuery, G_GetEventsBySpecificDateQueryVariables>(GetEventsBySpecificDateDocument, options);
      }
export function useGetEventsBySpecificDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetEventsBySpecificDateQuery, G_GetEventsBySpecificDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetEventsBySpecificDateQuery, G_GetEventsBySpecificDateQueryVariables>(GetEventsBySpecificDateDocument, options);
        }
export type GetEventsBySpecificDateQueryHookResult = ReturnType<typeof useGetEventsBySpecificDateQuery>;
export type GetEventsBySpecificDateLazyQueryHookResult = ReturnType<typeof useGetEventsBySpecificDateLazyQuery>;
export type GetEventsBySpecificDateQueryResult = Apollo.QueryResult<G_GetEventsBySpecificDateQuery, G_GetEventsBySpecificDateQueryVariables>;
export function refetchGetEventsBySpecificDateQuery(variables: G_GetEventsBySpecificDateQueryVariables) {
      return { query: GetEventsBySpecificDateDocument, variables: variables }
    }
export const ListingItemSessionDocument = gql`
    query listingItemSession($id: ID!) {
  listingItemSession(id: $id) {
    id
    startTs
    endTs
    listingItem {
      isAvailableOnline
      onlineMeetingLink
      onlineMeetingPin
      onlineMeetingIntroduction
      availableSeats
      locations {
        edges {
          node {
            id
            title
            addressLine1
            addressLine2
            postalCode
            city {
              id
              name
            }
            country {
              id
              name
            }
            geolocation
            order
          }
        }
      }
      options {
        edges {
          node {
            id
            duration
            price
            priceCurrency
            order
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListingItemSessionQuery__
 *
 * To run a query within a React component, call `useListingItemSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingItemSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingItemSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListingItemSessionQuery(baseOptions: Apollo.QueryHookOptions<G_ListingItemSessionQuery, G_ListingItemSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ListingItemSessionQuery, G_ListingItemSessionQueryVariables>(ListingItemSessionDocument, options);
      }
export function useListingItemSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ListingItemSessionQuery, G_ListingItemSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ListingItemSessionQuery, G_ListingItemSessionQueryVariables>(ListingItemSessionDocument, options);
        }
export type ListingItemSessionQueryHookResult = ReturnType<typeof useListingItemSessionQuery>;
export type ListingItemSessionLazyQueryHookResult = ReturnType<typeof useListingItemSessionLazyQuery>;
export type ListingItemSessionQueryResult = Apollo.QueryResult<G_ListingItemSessionQuery, G_ListingItemSessionQueryVariables>;
export function refetchListingItemSessionQuery(variables: G_ListingItemSessionQueryVariables) {
      return { query: ListingItemSessionDocument, variables: variables }
    }
export const GetListingInfoDocument = gql`
    query getListingInfo($listingSlug: String!) {
  listingsAll(slug: $listingSlug, first: 1) {
    edges {
      node {
        id
        slug
        type
        status
        title
        description
        rating
        coverImageUri
        createdAt
        updatedAt
        primaryLanguage {
          name
        }
        languages {
          edges {
            node {
              name
            }
          }
        }
        _geoloc {
          lat
          lng
        }
        category {
          id
          name
          nameDe
          parent {
            id
            name
          }
        }
        profile {
          id
          slug
          firstName
          lastName
          profilePictureUri
          bio
          createdAt
          kycStatus
          hasAvailability
          locationCity {
            name
          }
          languages {
            edges {
              node {
                name
                isoCode
              }
            }
          }
        }
        media {
          edges {
            node {
              id
              type
              title
              uri
              uriThumbnail
              cloudinaryAssetId
              order
            }
          }
        }
        items {
          edges {
            node {
              id
              isAvailableOnline
              availableSeats
              locations {
                edges {
                  node {
                    id
                    title
                    addressLine1
                    postalCode
                    city {
                      name
                    }
                    country {
                      name
                    }
                  }
                }
              }
              options {
                edges {
                  node {
                    id
                    price
                    priceCurrency
                    duration
                  }
                }
              }
            }
          }
        }
        upcomingSessions(first: 2) {
          edges {
            node {
              id
              startTs
              endTs
              bookedSeats
              occurrence {
                listingItemOption {
                  price
                  priceCurrency
                  duration
                  listingItem {
                    id
                    isAvailableOnline
                    availableSeats
                    locations {
                      edges {
                        node {
                          id
                          title
                          addressLine1
                          postalCode
                          city {
                            name
                          }
                          country {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetListingInfoQuery__
 *
 * To run a query within a React component, call `useGetListingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListingInfoQuery({
 *   variables: {
 *      listingSlug: // value for 'listingSlug'
 *   },
 * });
 */
export function useGetListingInfoQuery(baseOptions: Apollo.QueryHookOptions<G_GetListingInfoQuery, G_GetListingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetListingInfoQuery, G_GetListingInfoQueryVariables>(GetListingInfoDocument, options);
      }
export function useGetListingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetListingInfoQuery, G_GetListingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetListingInfoQuery, G_GetListingInfoQueryVariables>(GetListingInfoDocument, options);
        }
export type GetListingInfoQueryHookResult = ReturnType<typeof useGetListingInfoQuery>;
export type GetListingInfoLazyQueryHookResult = ReturnType<typeof useGetListingInfoLazyQuery>;
export type GetListingInfoQueryResult = Apollo.QueryResult<G_GetListingInfoQuery, G_GetListingInfoQueryVariables>;
export function refetchGetListingInfoQuery(variables: G_GetListingInfoQueryVariables) {
      return { query: GetListingInfoDocument, variables: variables }
    }
export const ListingBySlugDocument = gql`
    query listingBySlug($listingSlug: String!) {
  listingsAll(slug: $listingSlug, first: 1) {
    edges {
      node {
        id
        slug
        type
        title
        description
        rating
        coverImageUri
        createdAt
        updatedAt
        primaryLanguage {
          name
        }
        languages {
          edges {
            node {
              name
            }
          }
        }
        _geoloc {
          lat
          lng
        }
        category {
          id
          name
          nameDe
          parent {
            id
            name
          }
        }
        profile {
          id
          slug
          firstName
          lastName
          profilePictureUri
          bio
          createdAt
          kycStatus
          hasAvailability
          locationCity {
            name
          }
          languages {
            edges {
              node {
                name
              }
            }
          }
        }
        items {
          edges {
            node {
              id
              isAvailableOnline
              locations {
                edges {
                  node {
                    id
                    title
                    addressLine1
                    addressLine2
                    postalCode
                    city {
                      id
                      name
                    }
                    country {
                      id
                      name
                    }
                    geolocation
                    order
                  }
                }
              }
              availableSeats
              order
              options {
                edges {
                  node {
                    id
                    duration
                    price
                    priceCurrency
                    order
                  }
                }
              }
            }
          }
        }
        media {
          edges {
            node {
              id
              type
              title
              uri
              uriThumbnail
              cloudinaryAssetId
              order
            }
          }
        }
        upcomingSessions {
          edges {
            node {
              id
              startTs
              endTs
              bookedSeats
              occurrence {
                listingItemOption {
                  price
                }
              }
              listingItem {
                id
                isAvailableOnline
                availableSeats
                locations {
                  edges {
                    node {
                      title
                      addressLine1
                      postalCode
                      city {
                        name
                      }
                      country {
                        name
                      }
                    }
                  }
                }
                options {
                  edges {
                    node {
                      price
                      priceCurrency
                      duration
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListingBySlugQuery__
 *
 * To run a query within a React component, call `useListingBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingBySlugQuery({
 *   variables: {
 *      listingSlug: // value for 'listingSlug'
 *   },
 * });
 */
export function useListingBySlugQuery(baseOptions: Apollo.QueryHookOptions<G_ListingBySlugQuery, G_ListingBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ListingBySlugQuery, G_ListingBySlugQueryVariables>(ListingBySlugDocument, options);
      }
export function useListingBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ListingBySlugQuery, G_ListingBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ListingBySlugQuery, G_ListingBySlugQueryVariables>(ListingBySlugDocument, options);
        }
export type ListingBySlugQueryHookResult = ReturnType<typeof useListingBySlugQuery>;
export type ListingBySlugLazyQueryHookResult = ReturnType<typeof useListingBySlugLazyQuery>;
export type ListingBySlugQueryResult = Apollo.QueryResult<G_ListingBySlugQuery, G_ListingBySlugQueryVariables>;
export function refetchListingBySlugQuery(variables: G_ListingBySlugQueryVariables) {
      return { query: ListingBySlugDocument, variables: variables }
    }
export const GetListingReviewsByProfileDocument = gql`
    query getListingReviewsByProfile($profileSlug: String!) {
  listingReviewsByProfile(profileSlug: $profileSlug) {
    edges {
      node {
        createdAt
        rating
        content
        profile {
          firstName
        }
      }
    }
  }
}
    `;

/**
 * __useGetListingReviewsByProfileQuery__
 *
 * To run a query within a React component, call `useGetListingReviewsByProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListingReviewsByProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListingReviewsByProfileQuery({
 *   variables: {
 *      profileSlug: // value for 'profileSlug'
 *   },
 * });
 */
export function useGetListingReviewsByProfileQuery(baseOptions: Apollo.QueryHookOptions<G_GetListingReviewsByProfileQuery, G_GetListingReviewsByProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_GetListingReviewsByProfileQuery, G_GetListingReviewsByProfileQueryVariables>(GetListingReviewsByProfileDocument, options);
      }
export function useGetListingReviewsByProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_GetListingReviewsByProfileQuery, G_GetListingReviewsByProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_GetListingReviewsByProfileQuery, G_GetListingReviewsByProfileQueryVariables>(GetListingReviewsByProfileDocument, options);
        }
export type GetListingReviewsByProfileQueryHookResult = ReturnType<typeof useGetListingReviewsByProfileQuery>;
export type GetListingReviewsByProfileLazyQueryHookResult = ReturnType<typeof useGetListingReviewsByProfileLazyQuery>;
export type GetListingReviewsByProfileQueryResult = Apollo.QueryResult<G_GetListingReviewsByProfileQuery, G_GetListingReviewsByProfileQueryVariables>;
export function refetchGetListingReviewsByProfileQuery(variables: G_GetListingReviewsByProfileQueryVariables) {
      return { query: GetListingReviewsByProfileDocument, variables: variables }
    }
export const ListingAddAsAppointmentDocument = gql`
    mutation listingAddAsAppointment($profile: ID!, $category: ID!, $slug: String!, $title: String!, $description: String!, $status: ListingStatuses!, $languagePrimary: ID!, $languageOthers: [ID], $items: [ListingItemInput], $media: [ListingMediaInput]) {
  listingAdd(
    type: APPOINTMENT
    profile: $profile
    status: $status
    category: $category
    slug: $slug
    title: $title
    description: $description
    languagePrimary: $languagePrimary
    languageOthers: $languageOthers
    media: $media
    items: $items
  ) {
    id
  }
}
    `;
export type G_ListingAddAsAppointmentMutationFn = Apollo.MutationFunction<G_ListingAddAsAppointmentMutation, G_ListingAddAsAppointmentMutationVariables>;

/**
 * __useListingAddAsAppointmentMutation__
 *
 * To run a mutation, you first call `useListingAddAsAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListingAddAsAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listingAddAsAppointmentMutation, { data, loading, error }] = useListingAddAsAppointmentMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      category: // value for 'category'
 *      slug: // value for 'slug'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      languagePrimary: // value for 'languagePrimary'
 *      languageOthers: // value for 'languageOthers'
 *      items: // value for 'items'
 *      media: // value for 'media'
 *   },
 * });
 */
export function useListingAddAsAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<G_ListingAddAsAppointmentMutation, G_ListingAddAsAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ListingAddAsAppointmentMutation, G_ListingAddAsAppointmentMutationVariables>(ListingAddAsAppointmentDocument, options);
      }
export type ListingAddAsAppointmentMutationHookResult = ReturnType<typeof useListingAddAsAppointmentMutation>;
export type ListingAddAsAppointmentMutationResult = Apollo.MutationResult<G_ListingAddAsAppointmentMutation>;
export type ListingAddAsAppointmentMutationOptions = Apollo.BaseMutationOptions<G_ListingAddAsAppointmentMutation, G_ListingAddAsAppointmentMutationVariables>;
export const ListingUpdateAsAppointmentDocument = gql`
    mutation listingUpdateAsAppointment($listing: ID!, $profile: ID!, $category: ID!, $slug: String!, $title: String!, $description: String!, $status: ListingStatuses!, $languagePrimary: ID!, $languageOthers: [ListingUpdateLanguageInput], $items: [ListingUpdateItemInput], $media: [ListingMediaUpdateInput]) {
  listingUpdate(
    listing: $listing
    profile: $profile
    status: $status
    category: $category
    slug: $slug
    title: $title
    description: $description
    languagePrimary: $languagePrimary
    languageOthers: $languageOthers
    media: $media
    items: $items
  ) {
    id
  }
}
    `;
export type G_ListingUpdateAsAppointmentMutationFn = Apollo.MutationFunction<G_ListingUpdateAsAppointmentMutation, G_ListingUpdateAsAppointmentMutationVariables>;

/**
 * __useListingUpdateAsAppointmentMutation__
 *
 * To run a mutation, you first call `useListingUpdateAsAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListingUpdateAsAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listingUpdateAsAppointmentMutation, { data, loading, error }] = useListingUpdateAsAppointmentMutation({
 *   variables: {
 *      listing: // value for 'listing'
 *      profile: // value for 'profile'
 *      category: // value for 'category'
 *      slug: // value for 'slug'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      languagePrimary: // value for 'languagePrimary'
 *      languageOthers: // value for 'languageOthers'
 *      items: // value for 'items'
 *      media: // value for 'media'
 *   },
 * });
 */
export function useListingUpdateAsAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<G_ListingUpdateAsAppointmentMutation, G_ListingUpdateAsAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ListingUpdateAsAppointmentMutation, G_ListingUpdateAsAppointmentMutationVariables>(ListingUpdateAsAppointmentDocument, options);
      }
export type ListingUpdateAsAppointmentMutationHookResult = ReturnType<typeof useListingUpdateAsAppointmentMutation>;
export type ListingUpdateAsAppointmentMutationResult = Apollo.MutationResult<G_ListingUpdateAsAppointmentMutation>;
export type ListingUpdateAsAppointmentMutationOptions = Apollo.BaseMutationOptions<G_ListingUpdateAsAppointmentMutation, G_ListingUpdateAsAppointmentMutationVariables>;
export const ListingAddAsClassDocument = gql`
    mutation listingAddAsClass($profile: ID!, $category: ID!, $slug: String!, $title: String!, $description: String!, $languagePrimary: ID!, $languageOthers: [ID], $status: ListingStatuses!, $media: [ListingMediaInput]) {
  listingAddAsClass(
    type: CLASS
    profile: $profile
    status: $status
    category: $category
    slug: $slug
    title: $title
    description: $description
    languagePrimary: $languagePrimary
    languageOthers: $languageOthers
    media: $media
  ) {
    id
  }
}
    `;
export type G_ListingAddAsClassMutationFn = Apollo.MutationFunction<G_ListingAddAsClassMutation, G_ListingAddAsClassMutationVariables>;

/**
 * __useListingAddAsClassMutation__
 *
 * To run a mutation, you first call `useListingAddAsClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListingAddAsClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listingAddAsClassMutation, { data, loading, error }] = useListingAddAsClassMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      category: // value for 'category'
 *      slug: // value for 'slug'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      languagePrimary: // value for 'languagePrimary'
 *      languageOthers: // value for 'languageOthers'
 *      status: // value for 'status'
 *      media: // value for 'media'
 *   },
 * });
 */
export function useListingAddAsClassMutation(baseOptions?: Apollo.MutationHookOptions<G_ListingAddAsClassMutation, G_ListingAddAsClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ListingAddAsClassMutation, G_ListingAddAsClassMutationVariables>(ListingAddAsClassDocument, options);
      }
export type ListingAddAsClassMutationHookResult = ReturnType<typeof useListingAddAsClassMutation>;
export type ListingAddAsClassMutationResult = Apollo.MutationResult<G_ListingAddAsClassMutation>;
export type ListingAddAsClassMutationOptions = Apollo.BaseMutationOptions<G_ListingAddAsClassMutation, G_ListingAddAsClassMutationVariables>;
export const ListingUpdateAsClassDocument = gql`
    mutation listingUpdateAsClass($listing: ID!, $profile: ID!, $category: ID!, $slug: String!, $title: String!, $description: String!, $status: ListingStatuses!, $languagePrimary: ID!, $languageOthers: [ListingUpdateLanguageInput], $media: [ListingMediaUpdateInput]) {
  listingUpdateAsClass(
    listing: $listing
    profile: $profile
    status: $status
    category: $category
    slug: $slug
    title: $title
    description: $description
    languagePrimary: $languagePrimary
    languageOthers: $languageOthers
    media: $media
  ) {
    id
  }
}
    `;
export type G_ListingUpdateAsClassMutationFn = Apollo.MutationFunction<G_ListingUpdateAsClassMutation, G_ListingUpdateAsClassMutationVariables>;

/**
 * __useListingUpdateAsClassMutation__
 *
 * To run a mutation, you first call `useListingUpdateAsClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListingUpdateAsClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listingUpdateAsClassMutation, { data, loading, error }] = useListingUpdateAsClassMutation({
 *   variables: {
 *      listing: // value for 'listing'
 *      profile: // value for 'profile'
 *      category: // value for 'category'
 *      slug: // value for 'slug'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      languagePrimary: // value for 'languagePrimary'
 *      languageOthers: // value for 'languageOthers'
 *      media: // value for 'media'
 *   },
 * });
 */
export function useListingUpdateAsClassMutation(baseOptions?: Apollo.MutationHookOptions<G_ListingUpdateAsClassMutation, G_ListingUpdateAsClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ListingUpdateAsClassMutation, G_ListingUpdateAsClassMutationVariables>(ListingUpdateAsClassDocument, options);
      }
export type ListingUpdateAsClassMutationHookResult = ReturnType<typeof useListingUpdateAsClassMutation>;
export type ListingUpdateAsClassMutationResult = Apollo.MutationResult<G_ListingUpdateAsClassMutation>;
export type ListingUpdateAsClassMutationOptions = Apollo.BaseMutationOptions<G_ListingUpdateAsClassMutation, G_ListingUpdateAsClassMutationVariables>;
export const AddClassSessionDocument = gql`
    mutation addClassSession($profile: ID!, $listing: ID!, $isAvailableOnline: Boolean!, $onlineMeetingLink: String, $onlineMeetingPin: String, $onlineMeetingIntroduction: String, $locations: [ID], $availableSeats: Int, $duration: Int!, $price: Int!, $rrule: String) {
  classSessions_Add(
    profile: $profile
    listing: $listing
    items: {order: 0, isAvailableOnline: $isAvailableOnline, onlineMeetingLink: $onlineMeetingLink, onlineMeetingPin: $onlineMeetingPin, onlineMeetingIntroduction: $onlineMeetingIntroduction, locations: $locations, availableSeats: $availableSeats, options: {order: 0, duration: $duration, price: $price, priceCurrency: EURO, rrule: $rrule}}
  ) {
    id
  }
}
    `;
export type G_AddClassSessionMutationFn = Apollo.MutationFunction<G_AddClassSessionMutation, G_AddClassSessionMutationVariables>;

/**
 * __useAddClassSessionMutation__
 *
 * To run a mutation, you first call `useAddClassSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClassSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClassSessionMutation, { data, loading, error }] = useAddClassSessionMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      listing: // value for 'listing'
 *      isAvailableOnline: // value for 'isAvailableOnline'
 *      onlineMeetingLink: // value for 'onlineMeetingLink'
 *      onlineMeetingPin: // value for 'onlineMeetingPin'
 *      onlineMeetingIntroduction: // value for 'onlineMeetingIntroduction'
 *      locations: // value for 'locations'
 *      availableSeats: // value for 'availableSeats'
 *      duration: // value for 'duration'
 *      price: // value for 'price'
 *      rrule: // value for 'rrule'
 *   },
 * });
 */
export function useAddClassSessionMutation(baseOptions?: Apollo.MutationHookOptions<G_AddClassSessionMutation, G_AddClassSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_AddClassSessionMutation, G_AddClassSessionMutationVariables>(AddClassSessionDocument, options);
      }
export type AddClassSessionMutationHookResult = ReturnType<typeof useAddClassSessionMutation>;
export type AddClassSessionMutationResult = Apollo.MutationResult<G_AddClassSessionMutation>;
export type AddClassSessionMutationOptions = Apollo.BaseMutationOptions<G_AddClassSessionMutation, G_AddClassSessionMutationVariables>;
export const ListingByIdForEditDocument = gql`
    query listingByIdForEdit($listingId: ID!) {
  listing(id: $listingId) {
    id
    slug
    status
    type
    title
    description
    category {
      id
      parent {
        id
      }
    }
    primaryLanguage {
      id
    }
    languages {
      edges {
        node {
          id
        }
      }
    }
    profile {
      id
    }
    items {
      edges {
        node {
          id
          isAvailableOnline
          onlineMeetingLink
          onlineMeetingPin
          onlineMeetingIntroduction
          locations {
            edges {
              node {
                id
                title
                addressLine1
                addressLine2
                postalCode
                city {
                  id
                  name
                }
                country {
                  id
                  name
                }
                order
              }
            }
          }
          availableSeats
          order
          options {
            edges {
              node {
                id
                duration
                price
                priceCurrency
                order
              }
            }
          }
        }
      }
    }
    media {
      edges {
        node {
          id
          type
          title
          uri
          uriThumbnail
          cloudinaryAssetId
          order
        }
      }
    }
  }
}
    `;

/**
 * __useListingByIdForEditQuery__
 *
 * To run a query within a React component, call `useListingByIdForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingByIdForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingByIdForEditQuery({
 *   variables: {
 *      listingId: // value for 'listingId'
 *   },
 * });
 */
export function useListingByIdForEditQuery(baseOptions: Apollo.QueryHookOptions<G_ListingByIdForEditQuery, G_ListingByIdForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ListingByIdForEditQuery, G_ListingByIdForEditQueryVariables>(ListingByIdForEditDocument, options);
      }
export function useListingByIdForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ListingByIdForEditQuery, G_ListingByIdForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ListingByIdForEditQuery, G_ListingByIdForEditQueryVariables>(ListingByIdForEditDocument, options);
        }
export type ListingByIdForEditQueryHookResult = ReturnType<typeof useListingByIdForEditQuery>;
export type ListingByIdForEditLazyQueryHookResult = ReturnType<typeof useListingByIdForEditLazyQuery>;
export type ListingByIdForEditQueryResult = Apollo.QueryResult<G_ListingByIdForEditQuery, G_ListingByIdForEditQueryVariables>;
export function refetchListingByIdForEditQuery(variables: G_ListingByIdForEditQueryVariables) {
      return { query: ListingByIdForEditDocument, variables: variables }
    }
export const ListingStatusUpdateDocument = gql`
    mutation listingStatusUpdate($listingId: ID!, $profileId: ID!, $status: ListingStatuses) {
  __typename
  listingUpdate(listing: $listingId, profile: $profileId, status: $status) {
    id
    status
  }
}
    `;
export type G_ListingStatusUpdateMutationFn = Apollo.MutationFunction<G_ListingStatusUpdateMutation, G_ListingStatusUpdateMutationVariables>;

/**
 * __useListingStatusUpdateMutation__
 *
 * To run a mutation, you first call `useListingStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListingStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listingStatusUpdateMutation, { data, loading, error }] = useListingStatusUpdateMutation({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      profileId: // value for 'profileId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListingStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<G_ListingStatusUpdateMutation, G_ListingStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ListingStatusUpdateMutation, G_ListingStatusUpdateMutationVariables>(ListingStatusUpdateDocument, options);
      }
export type ListingStatusUpdateMutationHookResult = ReturnType<typeof useListingStatusUpdateMutation>;
export type ListingStatusUpdateMutationResult = Apollo.MutationResult<G_ListingStatusUpdateMutation>;
export type ListingStatusUpdateMutationOptions = Apollo.BaseMutationOptions<G_ListingStatusUpdateMutation, G_ListingStatusUpdateMutationVariables>;
export const ListingRemoveDocument = gql`
    mutation listingRemove($listingId: ID!, $profileId: ID!) {
  listingRemove(listing: $listingId, profile: $profileId) {
    __typename
  }
}
    `;
export type G_ListingRemoveMutationFn = Apollo.MutationFunction<G_ListingRemoveMutation, G_ListingRemoveMutationVariables>;

/**
 * __useListingRemoveMutation__
 *
 * To run a mutation, you first call `useListingRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListingRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listingRemoveMutation, { data, loading, error }] = useListingRemoveMutation({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useListingRemoveMutation(baseOptions?: Apollo.MutationHookOptions<G_ListingRemoveMutation, G_ListingRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ListingRemoveMutation, G_ListingRemoveMutationVariables>(ListingRemoveDocument, options);
      }
export type ListingRemoveMutationHookResult = ReturnType<typeof useListingRemoveMutation>;
export type ListingRemoveMutationResult = Apollo.MutationResult<G_ListingRemoveMutation>;
export type ListingRemoveMutationOptions = Apollo.BaseMutationOptions<G_ListingRemoveMutation, G_ListingRemoveMutationVariables>;
export const ListingAddReviewDocument = gql`
    mutation listingAddReview($listingId: ID!, $profileId: ID!, $bookingId: ID!, $content: String, $order: Int, $rating: Int = 0) {
  listingAddReview(
    listing: $listingId
    profile: $profileId
    booking: $bookingId
    content: $content
    order: $order
    rating: $rating
  ) {
    id
  }
}
    `;
export type G_ListingAddReviewMutationFn = Apollo.MutationFunction<G_ListingAddReviewMutation, G_ListingAddReviewMutationVariables>;

/**
 * __useListingAddReviewMutation__
 *
 * To run a mutation, you first call `useListingAddReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListingAddReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listingAddReviewMutation, { data, loading, error }] = useListingAddReviewMutation({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      profileId: // value for 'profileId'
 *      bookingId: // value for 'bookingId'
 *      content: // value for 'content'
 *      order: // value for 'order'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useListingAddReviewMutation(baseOptions?: Apollo.MutationHookOptions<G_ListingAddReviewMutation, G_ListingAddReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<G_ListingAddReviewMutation, G_ListingAddReviewMutationVariables>(ListingAddReviewDocument, options);
      }
export type ListingAddReviewMutationHookResult = ReturnType<typeof useListingAddReviewMutation>;
export type ListingAddReviewMutationResult = Apollo.MutationResult<G_ListingAddReviewMutation>;
export type ListingAddReviewMutationOptions = Apollo.BaseMutationOptions<G_ListingAddReviewMutation, G_ListingAddReviewMutationVariables>;
export const ListingsByProfileDocument = gql`
    query listingsByProfile($profileId: ID!) {
  listingsAll(profile: $profileId) {
    edges {
      node {
        id
        slug
        title
        description
        type
        status
        hasUpcomingSession
        category {
          id
          slug
          name
          nameDe
          parent {
            id
            slug
            name
          }
        }
        items {
          edges {
            node {
              isAvailableOnline
              availableSeats
              locations {
                edges {
                  node {
                    city {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        media {
          edges {
            node {
              title
              uri
              uriThumbnail
              order
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListingsByProfileQuery__
 *
 * To run a query within a React component, call `useListingsByProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingsByProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingsByProfileQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useListingsByProfileQuery(baseOptions: Apollo.QueryHookOptions<G_ListingsByProfileQuery, G_ListingsByProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<G_ListingsByProfileQuery, G_ListingsByProfileQueryVariables>(ListingsByProfileDocument, options);
      }
export function useListingsByProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<G_ListingsByProfileQuery, G_ListingsByProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<G_ListingsByProfileQuery, G_ListingsByProfileQueryVariables>(ListingsByProfileDocument, options);
        }
export type ListingsByProfileQueryHookResult = ReturnType<typeof useListingsByProfileQuery>;
export type ListingsByProfileLazyQueryHookResult = ReturnType<typeof useListingsByProfileLazyQuery>;
export type ListingsByProfileQueryResult = Apollo.QueryResult<G_ListingsByProfileQuery, G_ListingsByProfileQueryVariables>;
export function refetchListingsByProfileQuery(variables: G_ListingsByProfileQueryVariables) {
      return { query: ListingsByProfileDocument, variables: variables }
    }