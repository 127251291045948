import { lazy } from "react";
import constants from "@app/constants";

/*
  ref: https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
*/

const lazyWithRetry = (componentImport: any) => lazy(async () => {
  const pageHasAlreadyBeenForceRefreshed = window.localStorage && JSON.parse(
    window.localStorage.getItem(
      "page-has-been-force-refreshed",
    ) || "false",
  );

  try {
    const component = await componentImport();

    if (window.localStorage) {
      window.localStorage.setItem(
        "page-has-been-force-refreshed",
        "false",
      );
    }

    return component;
  } catch (error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      // eslint-disable-next-line curly
      if (constants.debug.console) console.info("lazyWithRetry running..");

      // Assuming that the user is not on the latest version of the application.
      // Let's refresh the page immediately.
      if (window.localStorage) {
        window.localStorage.setItem(
          "page-has-been-force-refreshed",
          "true",
        );
      }

      return window.location.reload();
    }

    // The page has already been reloaded
    // Assuming that user is already using the latest version of the application.
    // Let's let the application crash and raise the error.
    throw error;
  }
});

export { lazyWithRetry as default };
