import React from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import type { ThemeColor } from "../types";

type ButtonSize = "sm" | "md" | "lg" | "xl";

interface ButtonProps {
  type: "submit" | "reset" | "button" | "link";
  name?: string;
  value?: string;
  disabled?: boolean;
  submitting?: boolean;
  rounded?: boolean;
  outlined?: boolean;
  dim?: boolean;
  color?: ThemeColor;
  icon?: string;
  size?: ButtonSize;
  to?: string;
  state?: Record<string, unknown>;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function Button(props: ButtonProps) {
  const {
    type,
    name,
    value,
    disabled,
    submitting,
    rounded = false,
    outlined = false,
    dim = false,
    color = "primary",
    icon,
    size = "md",
    to,
    state,
    className,
    onClick,
  } = props;

  const colorClass = `btn-${outlined ? "outline-" : ""}${color}`;
  const sizeClass = size !== "md" ? `btn-${size}` : undefined;
  const linkClass = type === "link" ? "btn-link" : undefined;
  const bType = type === "link" ? "button" : type;

  const history = useHistory();

  function onClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (onClick) {
      onClick(e);
    } else if (to) {
      history.push(to, state);
    }
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={bType}
      name={name}
      disabled={disabled}
      onClick={onClicked}
      className={clsx(
        "btn",
        className,
        linkClass,
        linkClass ? "" : colorClass,
        sizeClass,
        { "btn-round": rounded && !linkClass },
        { "btn-dim": dim && !linkClass },
        { "btn-icon": icon && !value && !linkClass },
        { "btn-icon-text": value && icon && !linkClass },
        { disabled },
      )}
    >
      { submitting && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
      { !submitting && icon && <i className={icon} />}
      { value && <span>{value}</span>}
    </button>
  );
}

export type { ButtonSize };
export { Button as default };
