import React, {
  useEffect, useRef, useState,
} from "react";
import clsx from "clsx";
import ReactDOM from "react-dom";

interface Props {
  id: string;
  title: string;
  children: React.ReactNode;
  className: string;
}

interface PortalProps extends Pick<Props, "id" | "children" | "className"> {
  portalRoot: string;
  expanded: boolean;
}

function FilterDropdownChildren(props: PortalProps) {
  const {
    id, children, className, portalRoot, expanded,
  } = props;

  return ReactDOM.createPortal(
    (
      <ul
        aria-labelledby={id}
        className={clsx(
          "dropdown-menu box-dropdown",
          // "dropdown-menu-end dropdown-menu-lg-start",
          `dropdown-menu-${className}`,
          { show: expanded },
        )}
      >
        {children}
      </ul>
    ),
    document.getElementById(portalRoot)!,
  );
}

function FilterDropdown(props: Props) {
  const {
    id, title, children, className,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const portalRootElementId = "filter-item-dropdown-children";

  useEffect(() => {
    // console.log(ref.current);

    // console.log(ref.current?.scrollLeft, ref.current?.parentElement?.scrollLeft, ref.current?.parentElement?.parentElement?.scrollLeft);
    // console.log(ref.current?.scrollWidth, ref.current?.parentElement?.scrollWidth, ref.current?.parentElement?.parentElement?.scrollWidth);

    if (expanded) {
      const el = document.getElementById(portalRootElementId);
      el!.style.top = `${ref.current!.offsetTop + ref.current!.offsetHeight}px`;
      el!.style.left = `${ref.current!.offsetLeft - ref.current!.parentElement!.scrollLeft}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  // function handleClick(e: React.MouseEvent<HTMLAnchorElement>, item: DropdownItemData) {
  //   setExpanded(false);

  //   if (onItemClick) {
  //     onItemClick(e, item);
  //   }
  // }

  return (
    <>
      {expanded && <div className="overlay" onClick={() => setExpanded(!expanded)} />}

      <div ref={ref} className={clsx("filter-item ", className)}>
        <div className="btn-group dropdown">
          <button
            type="button"
            className={clsx(
              "btn btn-outline-light btn-sm btn-round dropdown-toggle",
              { show: expanded },
            )}
            onClick={() => setExpanded(!expanded)}
            id={id}
            data-toggle="dropdown"
            aria-expanded={expanded ? "true" : "false"}
          >
            <span>{title}</span>
          </button>

          { expanded && (
          <FilterDropdownChildren
            id={id}
            className={className}
            portalRoot={portalRootElementId}
            expanded={expanded}
          >
            {children}
          </FilterDropdownChildren>
          )}
        </div>
      </div>
    </>
  );
}

export { FilterDropdown as default };
