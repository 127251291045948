import React, { useEffect, useState } from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";
import type { G_ListingType } from "@app/gql/graphql-types";

interface Props {
  // items: any[];
  currentRefinement: string[];
  refine: (value: string[]) => void;
  // isFromSearch: boolean;
  //  searchForItems: function,
  //  createURL: function,
}

function RefinementList(props: Props) {
  const { currentRefinement, refine } = props;
  const { t } = useTranslation(["homepage"]);
  const [state, setState] = useState<G_ListingType[]>(currentRefinement as G_ListingType[]);

  useEffect(() => {
    // console.log({ state });
    refine(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const c = JSON.stringify(currentRefinement);
    const s = JSON.stringify(state);
    if (c !== s) {
      setState(currentRefinement as G_ListingType[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefinement]);

  function handleRefine(e: React.ChangeEvent<HTMLInputElement>) {
    setState(e.target.checked ? [e.target.value as G_ListingType] : []);
  }

  return (
    <div className="filter-item">
      <div className="btn-group">
        <input
          autoComplete="off"
          className="btn-check"
          id="filter-appointment"
          onChange={handleRefine}
          type="checkbox"
          value="APPOINTMENT"
          checked={state.includes("APPOINTMENT")}
        />
        <label className="btn btn-outline-light btn-sm btn-round" htmlFor="filter-appointment">
          {t("browse__filters__type_appointment")}
        </label>

        <input
          autoComplete="off"
          className="btn-check"
          id="filter-class"
          onChange={handleRefine}
          type="checkbox"
          value="CLASS"
          checked={state.includes("CLASS")}
        />
        <label className="btn btn-outline-light btn-sm btn-round" htmlFor="filter-class">
          {t("browse__filters__type_class")}
        </label>
      </div>
    </div>
  );
}

const CustomRefinementList = connectRefinementList(RefinementList);

function ListingTypeRefinement() {
  return (
    <CustomRefinementList attribute="type" />
  );
}

export { ListingTypeRefinement };
