import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import imgLoading2x from "./assets/images/loading@2x.gif";
import "./assets/styles/loading.scss";

interface Props {
  active: boolean;
  className?: string;
  forwardRef?: React.RefObject<HTMLDivElement>;
}

function BlockUI(props: Props) {
  const {
    active, className, forwardRef,
  } = props;

  const [elementId, setElementId] = useState<string>();

  useEffect(() => {
    if (!elementId) {
      setElementId(`_${uuidv4()}`.replaceAll("-", ""));
    }
  }, [elementId]);

  function getLoadingContainer() {
    return (
      <div className="cu-loading-container">
        <img src={imgLoading2x} alt="loading..." />
      </div>
    );
  }

  useEffect(() => {
    if (!forwardRef || !forwardRef.current) {
      return;
    }

    if (!elementId) {
      return;
    }

    const { current } = forwardRef;

    if (active) {
      if (!document.getElementById(elementId)) {
        const div = document.createElement("div");
        div.id = elementId;
        div.className = clsx("cu-loading-wrapper", className);
        ReactDOM.render(getLoadingContainer(), div);
        current.appendChild(div);
        current.className = `cu-loading ${current.className}`;
      }
    } else {
      const el = document.getElementById(elementId);
      if (el) {
        current.removeChild(el);
        current.className = current.className.replace("cu-loading ", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, className, elementId, forwardRef]);

  if (forwardRef) {
    // returns dynamic html
    return null;
  }

  if (!active) {
    return null;
  }

  // returns static html
  return (
    <div className={clsx("cu-block", "loading-wrapper", "screen", className)}>
      <img src={imgLoading2x} alt="loading..." />
    </div>
  );
}

export { BlockUI as default };
