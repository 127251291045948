import React from "react";
import { ClearRefinements } from "react-instantsearch-dom";
import FilterCategoriesCarousel from "./filter-categories-carousel";
import { CategoryRefinement } from "./category-refinement";
import { LanguageRefinement } from "./language-refinement";
import { ListingTypeRefinement } from "./listing-type-refinement";
import { IsVirtualRefinement } from "./is-virtual-refinements";
import { CustomStats } from "../custom-stats";
import "../../assets/styles/filters.scss";

interface Props {
  filterBar: boolean;
  carousel: boolean;
  slug?: string;
  parentSlug?: string;
}

function Filters(props: Props) {
  const {
    filterBar, carousel, slug, parentSlug,
  } = props;

  return (
    <>
      <div className="filters">
        {filterBar && (
          <>
            <div className="filter-buttons-wrapper">
              <div className="filter-items">
                {/* <CategoryHierarchicalRefinement /> */}
                <CategoryRefinement slug={slug} parentCategory={parentSlug} />
                <ListingTypeRefinement />
                <IsVirtualRefinement />
                <LanguageRefinement />
                <ClearRefinements />

                {/* <h5>Category</h5>
              <RefinementList attribute="category.name" searchable /> */}

                {/* <span className="filter-item">
                <div className="btn-group">
                  <input type="checkbox" className="btn-check" id="filter-appointment" autoComplete="off" />
                  <label className="btn btn-outline-light btn-sm btn-round" htmlFor="filter-appointment">1 on 1 Appointment</label>
                  <input type="checkbox" className="btn-check" id="filter-class" autoComplete="off" />
                  <label className="btn btn-outline-light btn-sm btn-round" htmlFor="filter-class">Class</label>
                </div>
              </span> */}

                {/* <span className="filter-item">
              <input type="checkbox" className="btn-check" id="filter-appointment" autoComplete="off" />
              <label className="btn btn-outline-light btn-sm btn-round" htmlFor="filter-appointment">1 on 1 Appointment</label>
            </span> */}

                {/* <span className="filter-item">
              <input type="checkbox" className="btn-check" id="filter-class" autoComplete="off" />
              <label className="btn btn-outline-light btn-sm btn-round" htmlFor="filter-class">Class</label>
            </span> */}

                {/* <span className="filter-item">
                <input type="checkbox" className="btn-check" id="filter-virtual" autoComplete="off" />
                <label className="btn btn-outline-light btn-sm btn-round" htmlFor="filter-virtual">{t("browse__filters__virtual")}</label>
              </span> */}

                {/* <span className="filter-item">
              <button type="button" className="btn btn-outline-light btn-sm btn-round dropdown-toggle"><span>{t("browse__filters__type")}</span></button>
            </span> */}

                {/* <span className="filter-item">
              <button type="button" className="btn btn-outline-light btn-sm btn-round dropdown-toggle"><span>{t("browse__filters__date")}</span></button>
            </span> */}

                {/* <span className="filter-item">
              <button type="button" className="btn btn-outline-light  btn-sm btn-round dropdown-toggle"><span>{t("browse__filters__price")}</span></button>
            </span> */}

                {/* <span className="filter-item">
              <button type="button" className="btn btn-outline-light btn-sm btn-round dropdown-toggle"><span>{t("browse__filters__rating")}</span></button>
            </span> */}

              </div>
              <CustomStats />
            </div>
          </>
        )}

        {carousel && (
          <div className="filter-categories">
            <FilterCategoriesCarousel items={[]} />
          </div>
        )}
      </div>
    </>
  );
}

export { Filters as default };
