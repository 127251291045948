import datefns_format from "date-fns/format";
import format_tz from "date-fns-tz/format";
import { enGB, de } from "date-fns/locale";
import { utcToZonedTime } from "date-fns-tz";
import { differenceInMinutes, parse, parseISO } from "date-fns";

const locales = { enGB, de };
const tzCET = "Europe/Berlin";

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
function format(date: number | Date, formatStr = "PP") {
  return datefns_format(date, formatStr, {
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    locale: locales[window.__localeId__], // or global.__localeId__
  });
}

function dateFormat(value: Date | undefined, withTime = false) {
  const fmt = withTime ? "MMM dd, yyyy HH:mm" : "MMM dd, yyyy";
  return value ? datefns_format(value, fmt) : "?";
}

function monthYearFormat(value: Date | undefined) {
  const fmt = "MMM yyyy";
  return value ? datefns_format(value, fmt) : "?";
}

function timeFormat(value: Date | undefined) {
  return value ? datefns_format(value, "HH:mm") : "--:--";
}

function timeFormatRange(startValue: Date | undefined, endValue: Date | undefined, withDate = false, withTimezone = false) {
  if (!startValue || !endValue) {
    return "--:--";
  }

  const startTime = datefns_format(startValue, withDate ? "MMM dd, yyyy HH:mm" : "HH:mm");
  const endTime = datefns_format(endValue, "HH:mm");
  const tz = withTimezone ? format_tz(startTime, "O", { timeZone: tzCET }) : "";
  // const endTime = datefns_format(datefns_addMinutes(value, amount), "HH:mm");

  return withTimezone
    ? `${startTime} - ${endTime} (${tz})`
    : `${startTime} - ${endTime}`;
}

// Month here is 1-indexed (January is 1, February is 2, etc). This is
// because we're using 0 as the day so that it returns the last day
// of the last month, so you have to add 1 to the month number
// so it returns the correct amount of days
function daysInMonth(month: number, year: number): number {
  return new Date(year, month, 0).getDate();
}

function getIntArray(min: number, max: number): number[] {
  const arr: number[] = [];
  for (let i = min; i <= max; i += 1) {
    arr.push(i);
  }

  return arr;
}

function translateDuration(duration: number, t: any) {
  const h = Math.floor(duration / 60);
  const m = duration - (h * 60);
  const sh = h === 0 ? "" : t("g__duration_hour", { count: h });
  const sm = m === 0 ? "" : t("g__duration_minute", { count: m });
  return `${sh} ${sm}`.trim();
}

function parseISOtz(value: string, timezone: string) {
  return utcToZonedTime(parseISO(value), timezone);
}

function parseISOcet(value: string) {
  // return parseISOtz(value, tzCET);
  return parseISO(value);
}

function parseISOtzBerlin(value: string) {
  return parseISOtz(value, tzCET);
}


export function customLocalizedDateFormat(date: number | Date, formatStr = "PP") {
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  const currentLanguage = window.__localeId__ || "de";

  // DM   (day month)           =    de: 10. Feb           -     en: Feb 10
  // DMY  (day month year)      =    de: 10. Feb 2022      -     en: Feb 10, 2022
  // DWM  (day week month)      =    de: Do, 10. Feb       -     en: Thu, Feb 10
  // DWMY (day week month year) =    de: Do, 10. Feb 2022  -     en: Thu, 24 Feb 2022

  let newFormat = "";
  switch (formatStr) {
    case "DM":
      newFormat = (currentLanguage === "de") ? "dd. MMM" : "MMM dd";
      break;

    case "DMY":
      newFormat = (currentLanguage === "de") ? "dd. MMM yyyy" : "MMM dd, yyyy";
      break;

    case "DWM":
      newFormat = (currentLanguage === "de") ? "iiiiii, dd. MMM" : "iii, MMM dd";
      break;

    case "DWMY":
      newFormat = (currentLanguage === "de") ? "iiiiii, dd. MMM yyyy" : "iii, dd MMM yyyy";
      break;

    default:
      newFormat = formatStr;
      break;
  }

  return format(date, newFormat);
}

function trimHours(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

/**
 * Build datetime string without timezone info
 * @param {Date} date date value
 * @param {Date} time time value
 * @returns {String} format -> 2022-05-04T18:00
 */
function naiveDateTimeStr(date: Date, time?: Date): string {
  const Num = Intl.NumberFormat("en-US", {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 0,
  });

  const timeVal = time ?? date;

  // expected result -> 2022-05-04T18:00
  const sd = `${date.getFullYear()}-${Num.format(date.getMonth() + 1)}-${Num.format(date.getDate())}`;
  const st = `${Num.format(timeVal.getHours())}:${Num.format(timeVal.getMinutes())}`;
  console.log(`${sd}T${st}`);
  return `${sd}T${st}`;
}

/**
 * Build new datetime from naiveDateTimeStr function
 * @param {Date} date date value
 * @param {Date} time time value
 * @returns {Date} from naiveDateTimeStr function
 */
function naiveDateTime(date: Date, time?: Date) {
  return new Date(naiveDateTimeStr(date, time));
}

function diffNearestMinutes(end: Date, start: Date, nearestTo: number = 30): number {
  const result = differenceInMinutes(end, start);

  if (result % nearestTo === 0) {
    return result;
  }

  return result > 0
    ? result + (nearestTo - (result % nearestTo))
    : result - (nearestTo + (result % nearestTo));
}

/**
 * https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
 */
const DB_TIMESTAMP_FORMAT = "yyyyMMddHHmm";
const DB_TIME_FORMAT = "HH:mm:ss";

const DB_CREATE_TIMESTAMP_FORMAT = "yyyy-MM-dd"

function parseDbTimestamp(value: string): Date {
  return parse(value, DB_TIMESTAMP_FORMAT, new Date());
}

function parseDbTime(time: string): Date {
  return parse(time, DB_TIME_FORMAT, new Date());
}

function parseDbCreateTimestamp(time: string): Date {
  return parse(time, DB_CREATE_TIMESTAMP_FORMAT, new Date());
}

function toDbTimestamp(datetime: Date): string {
  return datefns_format(datetime, DB_TIMESTAMP_FORMAT);
}

const dateUtils = {
  daysInMonth,
  getIntArray,
  format,
  dateFormat,
  timeFormat,
  monthYearFormat,
  timeFormatRange,
  translateDuration,
  parseISOtz,
  parseISOcet,
  tzCET,
  customLocalizedDateFormat,
  trimHours,
  naiveDateTimeStr,
  naiveDateTime,
  diffNearestMinutes,
  parseDbTimestamp,
  toDbTimestamp,
  parseDbTime,
  parseISOtzBerlin,
  parseDbCreateTimestamp,
};

export {
  dateUtils as default,
  parseISOcet, trimHours, naiveDateTimeStr, naiveDateTime, diffNearestMinutes,
  parseDbTimestamp, toDbTimestamp, parseDbTime, parseISOtzBerlin,
};
