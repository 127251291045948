import React from "react";
import h from "@app/helpers";
import { useTranslation } from "react-i18next";
import { useGetCategoryName } from "@app/hooks/tools";
import { getPrice, isAvailableInPerson, isAvailableOnline } from "../hit-helpers";
import ListingItemCard from "./listing-item-card";

interface Props {
  hit: any;
}

function ListingItemHit(props: any) {
  const { hit } = props;
  const { t } = useTranslation();
  const { getCategoryName } = useGetCategoryName();
  const category = getCategoryName(hit.category);
  // if (hit.title.startsWith("Reiki") || hit.status !== "ACTIVE") {
  //   console.log({ hit });
  // }

  return (
    <ListingItemCard
      key={hit.id}
      id={hit.id}
      slug={hit.slug}
      type={hit.type}
      isAvailableOnline={isAvailableOnline(hit)}
      isAvailableInPerson={isAvailableInPerson(hit)}
      coverPhoto={h.fetch.getThumbnailUrl(hit.coverImageUri, 800, 600, "c_limit", "jpg")}
      text={hit.title}
      avatarUrl={h.fetch.getThumbnailUrl(hit.profile?.profilePictureUri, 96, 96, "c_thumb", "jpg")}
      address={hit.locationAddressShort}
      price={getPrice(hit)}
      coordinates={hit.location}
      firstName={hit.profile?.firstName}
      lastName={hit.profile?.lastName}
      category={category}
    // averageRating = {hit.rating}   // FIXME: Berechnung der Werte im Backend korrigieren, dann wieder setzen
    />
  );
}

export { ListingItemHit as default };
