import React, { useContext, useState } from "react";
import { IAuthContext, AuthContext } from "@app/contexts";
import constants from "@app/constants";
import AvatarUpload from "../modal-avatar-upload/avatar-upload";

import "./avatar.scss";

interface AvatarProps {
  icon?: string;
  uri?: string | null;
  initial?: string; // one char (M, E)
  size: number;
  altText?: string;
  className?: string;
  updateButton?: boolean;
}

function Avatar(props: AvatarProps) {
  const {
    uri, size, altText, className, icon, initial, updateButton,
  } = props;

  const {
    state: { Auth: { profilePictureUri } },
    actions: { setProfilePicture },
  } = useContext<IAuthContext>(AuthContext);

  const style: React.CSSProperties = {
    minWidth: `${size}px`,
    width: `${size}px`,
    height: `${size}px`,
  };

  const firstChar = initial ? initial[0].toUpperCase() : undefined;
  const [openModal, setOpenModal] = useState(false);

  // return (
  //   <figure className="avatar avatar-xl mr-2" data-initial="YZ">
  //     <i className="avatar-presence online" />
  //   </figure>
  // );

  function onCloseModal(refreshRequired: boolean, url?: string) {
    setOpenModal(false);

    if (refreshRequired) {
      setProfilePicture(url);
    }
  }

  return (
    <>
      <figure className={`avatar ${className}`} style={style} data-initial={(uri || icon) ? undefined : firstChar}>
        {icon
          ? <i className={icon} />
          : (uri || !firstChar) && <img src={uri ?? constants.unknownAvatarUri} alt={altText} title={altText} />}

        {updateButton && (<span className="update" onClick={() => setOpenModal(true)}><i className="icon-edit" /></span>)}
      </figure>

      {openModal && <AvatarUpload onClose={onCloseModal} url={profilePictureUri} />}
    </>
  );
}

export { Avatar as default };
