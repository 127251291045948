import React from "react";
import { connectStateResults, Hits, ScrollTo } from "react-instantsearch-dom";
import ListingItemHit from "./listing-item-hit";
import EmptyResultView from "./empty-result-view";

function ContentListView() {
  const SearchResults = connectStateResults(
    ({ searchState, searchResults, children }: any) => (searchResults && searchResults.nbHits !== 0
      ? (children)
      : (<EmptyResultView />)),
  );

  return (
    <div className="results">
      <SearchResults>
        <ScrollTo>
          <Hits hitComponent={ListingItemHit} />
        </ScrollTo>
      </SearchResults>
    </div>
  );
}

export { ContentListView as default };
