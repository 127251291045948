const constants = {
  app: {
    title: process.env.PUBLIC_SITE_TITLE || "Curaya",
  },
  debug: {
    console: process.env.CONSOLE === "ON",
    rhfDevTool: process.env.RHF_DEV_TOOL === "ON",
    reactDevTool: process.env.REACT_DEV_TOOL === "ON",
  },
  unknownAvatarUri: "https://res.cloudinary.com/mycuraya/image/upload/v1614592015/avatar-placeholder-light.svg",
  // mapBoxAccessToken: "pk.eyJ1IjoidGhlbWlydCIsImEiOiJjanZoNWZlbDcwMDR5NDhuNDY3cGNyN3I2In0.7tJYXsqg6fc0HLuusObl7Q",
  defaultLocation: {
    lng: 13.41053,
    lat: 52.52437,
  }, // Berlin
  algolia: {
    appId: "3JV99JGPG0",
    apiKey: "2785538a20a98acf430c9050fc8f11a6",
    indexListings: process.env.ALGOLIA_INDEX_LISTINGS ?? "",
    indexExperts: process.env.ALGOLIA_INDEX_EXPERTS ?? "",
    indexCategories: process.env.ALGOLIA_INDEX_CATEGORIES ?? "",
    // querySuggestions: `${process.env.ALGOLIA_INDEX_LISTINGS}_query_suggestions`,
  },
  googleMaps: {
    apiKey: "AIzaSyB3Hv22fpbhvYC8nNv7SDjOPAi5knQbluA",
    endpoint: "https://maps.googleapis.com/maps/api/js?v=weekly",
  },
  intercom: {
    appId: "poydv515",
  },
  payPal: {
    clientId: process.env.PAYPAL_CLIENT_ID ?? "",
  },
  cloudinary: {
    cloudName: process.env.CLOUDINARY_CLOUD_NAME ?? "",
    resourceUrlPrefix: process.env.CLOUDINARY_PREFIX ?? "",
    uploadUrl: process.env.CLOUDINARY_UPLOAD_URL ?? "",
    uploadPresetKyc: process.env.CLOUDINARY_UPLOAD_PRESET_KYC ?? "",
    uploadPresetPhoto: process.env.CLOUDINARY_UPLOAD_PRESET_PHOTO ?? "",
  },
};

export { constants as default };
