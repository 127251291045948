import React from "react";
import imgLoading from "@app/assets/images/loading.gif";
import imgLoading2x from "@app/assets/images/loading@2x.gif";
import "./assets/styles/loading.scss";

interface Props {
  size?: "1x" | "2x";
  appearance?: "container" | "screen";
}

function Loading(props: Props) {
  const { size = "1x", appearance = "screen" } = props;

  return (
    <div className={`loading-wrapper ${appearance}`}>
      <img src={size === "2x" ? imgLoading2x : imgLoading} alt="loading..." />
    </div>
  );
}

export { Loading as default };
