import { createDataContext } from "@app/contexts/create-data-context";
import type { IContext } from "@app/contexts/types";

import { actions, IActions } from "./actions";
import { reducer } from "./reducer";
import { contextDefaults } from "./type-defaults";
import type { IState } from "./types";

// ---
// Context definition
// ----------------------------------------------------------------------------
interface IAuthContext extends IContext {
  state: IState;
  actions: IActions;
}

const { Provider, Context } = createDataContext(reducer, actions, contextDefaults, "Auth Context");

export { Provider, Context };
export type { IAuthContext };
