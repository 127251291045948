import { Action, isType } from "@app/contexts/types";
import { actionTypes } from "./actions";
import type { IState } from "./types";

// ---
// reducer
// ----------------------------------------------------------------------------
const reducer = (state: IState, action: Action<any>): IState => {
  if (isType(action, actionTypes.loadProfileInfoAction)) {
    return { ...state, profileInfo: action.payload.profileInfo };
  }

  throw new Error(`reducer handling is not available for the type - ${action.type}`);
};

export { reducer };
