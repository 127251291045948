import { Action, isType } from "@app/contexts/types";
import h from "@app/helpers/index";
import constants from "@app/constants";
import { actionTypes } from "./actions";
import type { IState, ListingStepItem } from "./types";
// import { listingStepsDefaults } from "./type-defaults";

// ---
// reducer
// ----------------------------------------------------------------------------
const reducer = (state: IState, action: Action<any>): IState => {
  // eslint-disable-next-line curly
  if (constants.debug.console) console.debug("[reducer]", action.type);

  let newState: IState | undefined;

  // ---
  if (isType(action, actionTypes.initializeListingStepsAction)) {
    newState = {
      ...state,
      listingData: {
        listingId: undefined,
        listingSlug: undefined,
        listingType: undefined,
        status: undefined,
        items: action.payload.items,
      },
    };
  }

  // ---
  if (isType(action, actionTypes.clearListingStepsAction)) {
    newState = {
      ...state,
      listingData: {
        listingId: undefined,
        listingSlug: undefined,
        listingType: undefined,
        status: undefined,
        items: state.listingData.items.map((x): ListingStepItem => {
          return {
            ...x,
            visited: false,
            completed: false,
            hasError: false,
            active: false,
            data: undefined,
          };
        }),
      },
    };
  }

  // ---
  if (isType(action, actionTypes.setListingStepDataAction)) {
    const steps = state.listingData.items
      .map((x): ListingStepItem => ((x.order === action.payload.order)
        ? {
          ...x,
          completed: action.payload.isValid,
          data: action.payload.values,
        }
        : x
      ));

    newState = { ...state, listingData: { ...state.listingData, items: steps } };
  }

  // ---
  if (isType(action, actionTypes.setListingStatusAction)) {
    newState = { ...state, listingData: { ...state.listingData, status: action.payload.value } };
  }

  // ---
  if (isType(action, actionTypes.setListingTypeAction)) {
    newState = { ...state, listingData: { ...state.listingData, listingType: action.payload.value } };
  }

  // ---
  if (isType(action, actionTypes.setListingIdAction)) {
    newState = {
      ...state,
      listingData: {
        ...state.listingData,
        listingId: action.payload.id,
        listingSlug: action.payload.slug,
      },
    };
  }

  if (newState !== undefined) {
    h.storage.set(h.storage.constants.LISTING_CREATE_CTX, newState);
    return newState;
  }

  throw new Error(`reducer handling is not available for the type - ${action.type}`);
};

export { reducer };
