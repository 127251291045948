function isHaveError(errors: Record<string, unknown>) {
  return errors && Object.keys(errors).length > 0;
}

function getErrorMessage(errors: any, name: string, label: string | undefined) {
  if (!isHaveError(errors)) {
    return undefined;
  }

  let message: string | undefined;

  if (name && errors) {
    /*
      field validation errors (standard field)
    */
    if (errors[name]) {
      message = errors[name].message;
    } else {
      /*
        field validation errors (array field)
      */
      const prefix = name.split("[").shift();
      if (prefix && errors[prefix]) {
        const suffix = name.split(".").pop();
        // @ts-ignore
        const index = name.match(/\d+/)[0];
        const errorObject = errors[prefix][index];
        if (errorObject && suffix && errorObject[suffix]) {
          // console.log("<>", errorObject[suffix]);
          message = errorObject[suffix].message;
        }
      }
    }
  }

  return label ? message?.replace(name, label) : message;
}

function isInvalid(errors: Record<string, unknown>, name: string) {
  return getErrorMessage(errors, name, undefined) !== undefined;
}

const methods = {
  isHaveError,
  getErrorMessage,
  isInvalid,
};

export { methods as default };
