import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";

import { toast } from "react-toastify";
import { AuthContext, IAuthContext } from "@app/contexts/index";
import Modal from "@app/components/modal/modal";
import {
  useUpdateAvatarMutation,
} from "@app/gql/graphql-types";

import "./assets/styles.scss";
import PhotoUploader, { UploadInfo } from "@app/pages/service-create/partials/photo-uploader";
import Button from "../button/button";

interface Props {
  url?: string;
  onClose: (refreshRequired: boolean, url?: string, assetId?: string) => void;
}

function AvatarUpload(props: Props) {
  const { url, onClose } = props;
  const { t } = useTranslation(["homepage"]);

  const [avatarPhoto, setAvatarPhoto] = useState<UploadInfo>();
  const {

    state: { Auth },
  } = useContext<IAuthContext>(AuthContext);

  const [
    updateAvatarMutation,
    { data: dataSubmit, loading: submitting, error: errorSubmit },
  ] = useUpdateAvatarMutation();

  function onDismiss() {
    onClose(false);
  }

  useEffect(() => {
    if (url) {
      setAvatarPhoto({
        url: url,
        thumbnailUrl: url,
        assetId: undefined,
        trackId: undefined,
        trackStatus: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (dataSubmit) {
      onClose(true, avatarPhoto?.url, avatarPhoto?.assetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubmit]);

  function onSave() {
    updateAvatarMutation({
      variables: {
        profileId: Auth.profileId,
        profilePictureUri: avatarPhoto?.url ?? null,
        profilePictureAssetId: avatarPhoto?.assetId ?? null,
      },
    });
  }

  return (
    <Modal
      showClose
      className="avatar-upload-modal"
      onClose={onDismiss}
      title={t("comp__carousel__modal_avatar_upload__title")}
      size="sm"
    >
      <PhotoUploader
        maxFiles={1}
        containerClass=" "
        uploadedFiles={avatarPhoto ? [avatarPhoto] : []}
        tags={["profile,avatar"]}
        thumbnailSize={{ // aspect ratio 3/2
          width: 360,
          height: 240,
        }}
        onAdd={(args) => setAvatarPhoto(args)}
        onDelete={() => setAvatarPhoto(undefined)}
      />

      <Button
        type="button"
        color="primary"
        value={t("g__btn_save")}
        onClick={onSave}
        disabled={submitting}
        submitting={submitting}
        className="btn-block"
      />
    </Modal>
  );
}

export { AvatarUpload as default };
