import React, { useState } from "react";
import {
  GoogleMapsLoader,
  GeoSearch,
  CustomMarker,
  Control,
  Marker,
} from "react-instantsearch-dom-maps";
import constants from "@app/constants";
import h from "@app/helpers";
import { getPrice, isAvailableInPerson, isAvailableOnline } from "../hit-helpers";
import MapMarker from "./map-marker";

function ContentMapView() {
  const [activeMarkerId, setActiveMarkerId] = useState<string>();

  function toggleHover(listingId: string, active: boolean) {
    const elements = document.getElementsByClassName(listingId);
    if (elements.length === 0) {
      return;
    }

    if (active === true) {
      elements[0].classList.add("active");
    } else {
      elements[0].classList.remove("active");
    }
  }

  function openMarkerDetail(e: any) {
    e.preventDefault();
    const newMarker = e.target.dataset.target;
    if (activeMarkerId) {
      toggleHover(activeMarkerId, false);

      if (activeMarkerId === newMarker) {
        setActiveMarkerId(undefined);
        return;
      }
    }
    setActiveMarkerId(newMarker);
    toggleHover(newMarker, true);
  }

  return (
    <div className="map-container">
      <GoogleMapsLoader
        apiKey={constants.googleMaps.apiKey}
        endpoint={constants.googleMaps.endpoint}
      >
        {(google: any) => (
          <GeoSearch
            google={google}
            // // initialPosition={constants.defaultLocation}
            // initialZoom={9}
            // enableRefine
            // enableRefineOnMapMove
          // minZoom={2}
          >
            {({ hits }: any) => (
              <div>
                <Control />

                {hits.map((hit: any) => {
                  const price = getPrice(hit);

                  if (!price) {
                    return null;
                  }

                  // return <Marker key={hit.objectID} hit={hit} />;
                  return (
                    <CustomMarker
                      key={hit.objectID}
                      hit={hit}
                      className={`cu-map-marker-wrapper ${hit.id}`}
                      // anchor={{ x: 0, y: 5 }}
                      // TODO: find better way
                      // Move the anchor a little for each random item. Thus, we will prevent the caps from overlapping a little.
                      anchor={{ x: Math.floor(Math.random() * 35) + 1, y: Math.floor(Math.random() * 35) + 1 }}
                    >
                      <MapMarker
                        key={hit.id}
                        id={hit.id}
                        slug={hit.slug}
                        type={hit.type}
                        isAvailableOnline={isAvailableOnline(hit)}
                        isAvailableInPerson={isAvailableInPerson(hit)}
                        coverPhoto={h.fetch.getThumbnailUrl(hit.coverImageUri, 800, 600, "c_limit", "jpg")}
                        text={hit.title}
                        avatarUrl={h.fetch.getThumbnailUrl(hit.profile?.profilePictureUri, 96, 96, "c_thumb", "jpg")}
                        address={hit.locationAddressShort}
                        price={price}
                        coordinates={hit.location}
                        firstName={hit.profile?.firstName}
                        lastName={hit.profile?.lastName}
                        show={activeMarkerId === hit.id}
                      />
                      {/* <a href="#" className="marker-price" onClick={openMarkerDetail} data-target={hit.id}>
                        {`${price} €`}
                      </a> */}
                      {hit.profile?.profilePictureUri
                        ? <img className="marker-avatar" onClick={openMarkerDetail} data-target={hit.id} src={h.fetch.getThumbnailUrl(hit.profile?.profilePictureUri, 96, 96, "c_thumb", "jpg")} alt="" />
                        : <a href="#" className="marker-avatar" onClick={openMarkerDetail} data-target={hit.id}>{hit.profile?.firstName[0].toUpperCase()}</a> }
                    </CustomMarker>
                  );
                })}
              </div>
            )}
          </GeoSearch>
        )}
      </GoogleMapsLoader>
    </div>
  );
}

export { ContentMapView as default };
