import React from "react";
import { ClearRefinements } from "react-instantsearch-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";
import noResultImg from "@app/assets/images/illustrations/meditation.svg";
import birdsAnimation from "@app/assets/animations/birds.json";

interface Props { }

function EmptyResultView(props: Props) {
  const { t } = useTranslation(["homepage"]);

  return (
    <div className="no-result-wrapper">
      <Player
        autoplay
        loop
        src={birdsAnimation}
        style={{ height: "200px" }}
      >
        {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
      </Player>

      <figure>
        <img src={noResultImg} alt="" />
      </figure>
      <h3>{t("browse__no_result_title")}</h3>
      <p>{t("browse__no_result_text")}</p>
      {/* for "{searchState.query}" */}
      <ClearRefinements />
    </div>
  );
}

export { EmptyResultView as default };
