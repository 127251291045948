import type { G_ListingType } from "@app/gql/graphql-types";

function locationAndTypeKey(isVirtual: boolean, isInPerson: boolean, listingType: G_ListingType) {
  const typeDescriptionKeys = [
    { text: "virtual", virtual: true, inPerson: false },
    { text: "inperson", virtual: false, inPerson: true },
    { text: "virtual_or_inperson", virtual: true, inPerson: true },
  ];

  const typeDescriptionKey = typeDescriptionKeys.find(
    (x) => x.virtual === isVirtual && x.inPerson === isInPerson,
  )?.text || "location_not_defined";

  return `${typeDescriptionKey}_${listingType.toLowerCase()}`;
}

const methods = {
  locationAndTypeKey,
};

export { methods as default };
