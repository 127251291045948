import { changeLanguage } from "i18next";
import { format } from "date-fns";
import { enGB, de } from "date-fns/locale";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import h from "@app/helpers/index";

// --- react-datepicker
registerLocale("de", de);
// registerLocale("en", enGB);

// date-fns format
const locales = { enGB, de };

/* ---
  https://date-fns.org/v2.20.2/docs/I18n
*/

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
function formatLocale(date: Date, formatStr = "PP") {
  return format(date, formatStr, {
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    locale: locales[window.__localeId__], // or global.__localeId__
  });
}

function getLanguage() {
  return h.storage.get(h.storage.constants.LANGUAGE, true) || "de";
}

// de / en
function setLanguage(isoCode?: string) {
  const langIsoCode = isoCode || getLanguage();

  // --- i18n
  changeLanguage(langIsoCode);

  // --- react-datepicker
  setDefaultLocale(langIsoCode);

  // --- date-fns
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  window.__localeId__ = langIsoCode;
}

export { formatLocale as default, setLanguage, getLanguage };
