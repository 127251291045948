import React, { useEffect, useState } from "react";
import { connectToggleRefinement } from "react-instantsearch-dom";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";

interface Props {
  currentRefinement: boolean;
  // count: object;
  refine: (value: boolean | undefined) => void;
  setIsChecked: (checked: boolean) => void;
  // createURL: function;
}

function ToggleRefinement(props: Props) {
  const {
    currentRefinement, refine, setIsChecked,
  } = props;
  const { t } = useTranslation(["homepage"]);
  const [state, setState] = useState<boolean>(currentRefinement);

  useEffect(() => {
    refine(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const c = JSON.stringify(currentRefinement);
    const s = JSON.stringify(state);
    if (c !== s) {
      setState(currentRefinement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefinement]);

  function handleRefine(e: React.ChangeEvent<HTMLInputElement>) {
    setState(e.target.checked);
  }

  return (
    <div className="filter-item">
      <input
        type="checkbox"
        className="btn-check"
        id="filter-virtual"
        autoComplete="off"
        onChange={handleRefine}
        value="1"
        checked={state}
      />
      <label className="btn btn-outline-light btn-sm btn-round" htmlFor="filter-virtual">
        {t("browse__filters__virtual")}
      </label>
    </div>
  );
}

const CustomToggleRefinement = connectToggleRefinement(ToggleRefinement);

function IsVirtualRefinement() {
  return (
    <CustomToggleRefinement
      attribute="items.edges.node.isAvailableOnline"
      value
      label=""
    />
  );
}

export { IsVirtualRefinement };
