import React, { useEffect } from "react";
import Modal from "@app/components/modal/modal";
import { useHistory, useLocation } from "react-router-dom";
import constants from "@app/constants";

import "./assets/styles/auth.scss";

function useQueryString() {
  return new URLSearchParams(useLocation().search);
}

function AccountVerifyViaLink() {
  const query = useQueryString();
  const history = useHistory<any>();

  /*
    /auth/confirm?c=789798&e=abc@def.com&u=685b8a39-9852-47fd-8f1a-c582d8945263
    http://localhost:8080/auth/confirm?c=123456&e=mertyerli%2Bm4%40gmail.com&u=c7ad2c79-10c2-4677-a7da-e3cd108f5b78
  */
  const code = query.get("c");
  const email = query.get("e");
  const username = query.get("u");

  useEffect(() => {
    if (constants.debug.console) { console.log("---------- >>>>> AccountVerifyViaLink", code, email, username); }
    history.push("/register/confirm", { username, code, email });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Modal className="auth-modal">
      redirecting...
    </Modal>
  );
}

export { AccountVerifyViaLink as default };
