import { Action, isType } from "@app/contexts/types";
import { actionTypes } from "./actions";
import { authDefaults } from "./type-defaults";
import type { IState } from "./types";

// ---
// reducer
// ----------------------------------------------------------------------------
const reducer = (state: IState, action: Action<any>): IState => {
  // ---
  if (isType(action, actionTypes.loginAction)) {
    return { ...state, Auth: action.payload.Auth, LoggedIn: true };
  }

  // ---
  if (isType(action, actionTypes.logoutAction)) {
    return {
      ...state, Auth: authDefaults, LoggedIn: false, GetStartedInfo: undefined,
    };
  }

  // ---
  if (isType(action, actionTypes.setProfilePictureAction)) {
    return {
      ...state,
      Auth: {
        ...state.Auth,
        profilePictureUri: action.payload.url,
      },
    };
  }

  // ---
  if (isType(action, actionTypes.setUserFullNameAction)) {
    return {
      ...state,
      Auth: {
        ...state.Auth,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      },
    };
  }

  // ---
  if (isType(action, actionTypes.setGetStartedInfoAction)) {
    return { ...state, GetStartedInfo: action.payload.data };
  }

  // ---
  if (isType(action, actionTypes.setIsNewExpertAction)) {
    return { ...state, isNewExpert: true };
  }

  // ---
  if (isType(action, actionTypes.setGetStartedPopupViewedAction)) {
    return { ...state, getStartedPopupViewed: true };
  }

  throw new Error(`reducer handling is not available for the type - ${action.type}`);
};

export { reducer };
