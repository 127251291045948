/* eslint-disable no-underscore-dangle */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./app";
import reportWebVitals from "./report-web-vitals";
import Loading from "./components/loading/loading";

// environment variables
// @ts-ignore
const { SERVE } = process.env;

const disableReactDevTools = (): void => {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === "object") {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === "function" ? noop : null;
    }
  }
};

if (process.env.REACT_DEV_TOOL !== "ON") {
  disableReactDevTools();
}

const rootElement = document.getElementById("root");
const renderer = rootElement!.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render;

renderer(
  <React.StrictMode>
    <React.Suspense fallback={<Loading />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>,
  rootElement,
);

// @ts-ignore
if (SERVE && module.hot) {
  // @ts-ignore
  module.hot.accept();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(if (constants.debug.console) console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
