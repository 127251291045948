function first<T>(obj: any, child = "node"): T | undefined {
  if (Array.isArray(obj)) {
    if (obj.length > 0) {
      if (obj[0]) {
        return obj[0][child] ? obj[0][child] : obj[0];
      }
    }
  }

  return undefined;
}

const methods = {
  first,
};

export { methods as default };
