import React from "react";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";
import { connectStats } from "react-instantsearch-dom";

interface Props {
  nbHits: number;
  processingTimeMS: number;
  nbSortedHits: number;
  areHitsSorted: boolean;
}

function Stats(props: Props) {
  const {
    processingTimeMS, nbHits, nbSortedHits, areHitsSorted,
  } = props;

  const { t } = useTranslation(["homepage"]);

  return (
    <span className="number-of-items">
      {t("browse__filters__count_items", { count: nbHits })}
    </span>
  );

  /*
  return (
    <p>
      {areHitsSorted && nbHits !== nbSortedHits
        ? `${nbSortedHits.toLocaleString()} relevant services sorted out of ${areHitsSorted.toLocaleString()} found in ${processingTimeMS.toLocaleString()}ms`
        : `${nbHits.toLocaleString()} services found in ${processingTimeMS.toLocaleString()}ms`}
    </p>
  );
  */
}

const CustomStats = connectStats(Stats);

export { CustomStats };
