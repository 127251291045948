import array from "./array";
import dateUtils from "./date-utils";
import f from "./fetch";
import frm from "./form";
import gql from "./gql";
import jwt from "./jwt";
import storage from "./local-storage";
import textUtils from "./text-utils";
import translate from "./translate";
import lazyWithRetry from "./lazyWithRetry";
import dom from "./dom";

const methods = {
  array: array,
  dates: dateUtils,
  fetch: f,
  forms: frm,
  gql: gql,
  jwt: jwt,
  storage: storage,
  text: textUtils,
  translate: translate,
  dom: dom,
};

export { methods as default, lazyWithRetry };
