function ellipsis(s: string, n: number): string {
  return (s.length > n) ? `${s.substr(0, n)}...` : s;
}

function slugify(input: string) {
  if (!input || input.length === 0) {
    return "";
    // throw new Error("slugify string doesn't contain any data.");
  }

  const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return input.toString().toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c: string) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

const methods = {
  ellipsis,
  slugify,
};

export { methods as default };
