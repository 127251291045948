/* eslint-disable arrow-body-style */
import { actionCreator, IContextActions } from "@app/contexts/types";
import h from "@app/helpers/index";
import type { Dispatch } from "react";
import type { StepInfo } from "@app/hooks/get-started-checklist";
import contextHelper from "./helpers";
import type { IAuth } from "./types";

// ---
// Actions (declarations)
// ----------------------------------------------------------------------------
const loginAction = actionCreator<{ Auth: IAuth }>("AUTH_LOGIN");
const logoutAction = actionCreator<null>("AUTH_LOGOUT");
// const getAuthenticationUrlAction = actionCreator<{ pathname: string, provider?: string }>("AUTH_URL");
const switchToExpertAction = actionCreator<null>("SWITCH_TO_EXPERT");
const toggleRoleViewAction = actionCreator<null>("TOGGLE_ROLE_VIEW");
const setProfilePictureAction = actionCreator<{ url?: string }>("AVATAR");
const setUserFullNameAction = actionCreator<{ firstName: string, lastName: string }>("SET_USER_FULLNAME");
const setGetStartedInfoAction = actionCreator<{ data: StepInfo }>("SET_GET_STARTED_INFO");
const setIsNewExpertAction = actionCreator<null>("SET_IS_NEW_EXPERT");
const setGetStartedPopupViewedAction = actionCreator<null>("SET_GET_STARTED_POPUP_VIEWED");

const actionTypes = {
  loginAction,
  logoutAction,
  // getAuthenticationUrlAction,
  switchToExpertAction,
  toggleRoleViewAction,
  setProfilePictureAction,
  setUserFullNameAction,
  setGetStartedInfoAction,
  setIsNewExpertAction,
  setGetStartedPopupViewedAction,
};

// ---
// Action methods
// ----------------------------------------------------------------------------
const login = (dispatch: Dispatch<any>) => (authObj: IAuth) => {
  // console.log(">>>>>>>>>>>>>>> login:", authObj);
  contextHelper.fillStorageWith(authObj);
  dispatch(loginAction({ Auth: authObj }));
};

const logout = (dispatch: Dispatch<any>) => () => {
  h.storage.remove(h.storage.constants.AUTH_OBJ);
  h.storage.remove(h.storage.constants.LISTING_CREATE_CTX);
  dispatch(logoutAction(null));
};

const getAuthenticationUrl = () => (pathname: string, provider?: string) => {
  return contextHelper.getAuthenticationUrl(pathname, provider);
};

const switchToExpert = (dispatch: Dispatch<any>) => () => {
  const encryptedAuthObject = h.storage.get(h.storage.constants.AUTH_OBJ);
  if (encryptedAuthObject) {
    const authObj = contextHelper.decryptAuthObject(encryptedAuthObject);
    if (authObj) {
      authObj.role = "EXPERT";
      login(dispatch)(authObj);
    }
  }
};
const toggleRoleView = (dispatch: Dispatch<any>) => () => {
  const encryptedAuthObject = h.storage.get(h.storage.constants.AUTH_OBJ);
  if (encryptedAuthObject) {
    const authObj = contextHelper.decryptAuthObject(encryptedAuthObject);
    if (authObj) {
      authObj.view = authObj.view === "EXPERT" ? "CUSTOMER" : "EXPERT";
      login(dispatch)(authObj);
    }
  }
};

const setProfilePicture = (dispatch: Dispatch<any>) => (url?: string) => {
  dispatch(setProfilePictureAction({ url }));
  const encryptedAuthObject = h.storage.get(h.storage.constants.AUTH_OBJ);
  if (encryptedAuthObject) {
    const authObj = contextHelper.decryptAuthObject(encryptedAuthObject);
    if (authObj) {
      authObj.profilePictureUri = url;
      contextHelper.fillStorageWith(authObj);
    }
  }
};

const setUserFullName = (dispatch: Dispatch<any>) => (firstName: string, lastName: string) => {
  dispatch(setUserFullNameAction({ firstName, lastName }));
  const encryptedAuthObject = h.storage.get(h.storage.constants.AUTH_OBJ);
  if (encryptedAuthObject) {
    const authObj = contextHelper.decryptAuthObject(encryptedAuthObject);
    if (authObj) {
      authObj.firstName = firstName;
      authObj.lastName = lastName;
      contextHelper.fillStorageWith(authObj);
    }
  }
};

const setGetStartedInfo = (dispatch: Dispatch<any>) => (data: StepInfo) => {
  dispatch(setGetStartedInfoAction({ data }));
};

const setIsNewExpert = (dispatch: Dispatch<any>) => () => {
  dispatch(setIsNewExpertAction(null));
};

const setGetStartedPopupViewed = (dispatch: Dispatch<any>) => () => {
  dispatch(setGetStartedPopupViewedAction(null));
};

// ---
// Actions definition
// ----------------------------------------------------------------------------
interface IActions extends IContextActions {
  login(logindata: IAuth): void;
  logout(): void;
  getAuthenticationUrl(pathname: string, provider?: string): string;
  switchToExpert(): void;
  toggleRoleView(): void;
  setProfilePicture(url?: string): void;
  setUserFullName(firstName: string, lastName: string): void;
  setGetStartedInfo(data: StepInfo): void;
  setIsNewExpert(): void;
  setGetStartedPopupViewed(): void;
}

const actions = {
  login,
  logout,
  getAuthenticationUrl,
  switchToExpert,
  toggleRoleView,
  setProfilePicture,
  setUserFullName,
  setGetStartedInfo,
  setIsNewExpert,
  setGetStartedPopupViewed,
};

// ---
// exports
// ----------------------------------------------------------------------------
export type { IActions };
export { actions, actionTypes };
