import React, { Suspense } from "react";
import Loading from "@app/components/loading/loading";
import constants from "@app/constants";

function LazyLoader(props: any) {
  const { component: Component, componentProps, fallback } = props;

  if (process.env.SSR === "1") {
    return () => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component {...(componentProps ?? {})} />
    );
  }

  return () => (
    <Suspense fallback={fallback ?? <Loading size="1x" />}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...(componentProps ?? {})} />
    </Suspense>
  );
}

interface ChildLoaderProps {
  children: any;
  fallback: React.ReactNode;
}

function ChildLoader(props: ChildLoaderProps) {
  const { children, fallback } = props;

  /*
  if (constants.debug.console) {
    console.info("ChildLoader", { ssr: process.env.SSR }, { children, fallback });
  }
  */

  if (process.env.SSR === "1") {
    return children;
  }

  return (
    <Suspense fallback={fallback ?? <Loading size="1x" />}>
      {children}
    </Suspense>
  );
}

const Loader = {
  Lazy: LazyLoader,
  Child: ChildLoader,
};

export { Loader as default };
