import algoliasearch from "algoliasearch";
import constants from "@app/constants";

const searchClient = algoliasearch(
  constants.algolia.appId,
  constants.algolia.apiKey,
);

function customClientProcessResult(query: any, result: any) {
  if (!query.indexName.startsWith("curaya_listings_")) {
    return result;
  }

  // console.log("h", result);
  return {
    ...result,
    hits: result.hits.map((hit: any) => {
      // eslint-disable-next-line no-underscore-dangle
      return { ...hit, _geoloc: hit._geoloc[0] };
    }),
  };
}

const customSearchClient = {
  ...searchClient,
  async search(queries: any, ...args: any) {
    const originalResults = await searchClient.search(queries, ...args);
    // console.log("originalResults", originalResults);

    const newResults = {
      ...originalResults,
      results: [originalResults.results[0]].map((item, key) => customClientProcessResult(queries[key], item)),
    };

    return newResults;
  },
};

export { searchClient, customSearchClient };
