import React, { useEffect, useState } from "react";
import Alert from "@app/components/alert/alert";
// @ts-ignore
import HTML2React from "html2react";
import type { ApolloError, ServerError, ServerParseError } from "@apollo/client";
import type { GraphQLError } from "graphql";
import type { DeepMap, FieldError } from "react-hook-form";
import constants from "@app/constants";

function getGraphQLErrors(e: ReadonlyArray<GraphQLError>): string | undefined {
  let msg = e.map((item) => item.message).join(",");
  if (msg === "Internal server error") {
    // if (constants.debug.console) console.log("ISE", e);
    // @ts-ignore
    return e[0].params.message;
    // @ts-ignore
  } if (e.length > 0 && e[0].codekey === "PAYMENT_REQUEST_PARAMETER_ERROR") {
    // @ts-ignore
    msg = Object.keys(e[0].params.errors).map((key) => e[0].params.errors[key]).join(",");
  }

  return msg === "" ? undefined : msg;
}

function getNetworkError(e: Error | ServerParseError | ServerError | null): string | undefined {
  if (constants.debug.console) { console.error("getNetworkError:", e); }

  let msg: string | undefined;
  msg = (e as ServerError)?.result?.errors?.map((x: any) => x.message)?.join(",", "");
  if (!msg) {
    msg = (e as ServerParseError)?.message;
  }

  if (!msg) {
    msg = (e as Error)?.message;
  }

  if (!msg) {
    msg = e?.message;
  }

  return msg;
}

function getApolloError(e: ApolloError): string | undefined {
  let msg: string | undefined;

  if (!msg) {
    msg = e?.networkError ? getNetworkError(e.networkError) : undefined;
  }

  if (!msg) {
    msg = e?.graphQLErrors ? getGraphQLErrors(e.graphQLErrors) : undefined;
  }

  if (!msg) {
    msg = e?.message;
  }

  return msg;
}

type FormErrorType = DeepMap<Record<string, any>, FieldError>;
interface ValidationSummaryProps {
  error: ApolloError | string | undefined | null | FormErrorType;
  type?: "graphql" | "form";
  separator?: string;
}

function ValidationSummary(props: ValidationSummaryProps) {
  const {
    error,
    type: errorType = "graphql",
    separator: incomingSeparator,
  } = props;

  const separator = incomingSeparator || (errorType === "form") ? "<br />" : "|";

  const [message, setMessage] = useState<string | React.ReactNode | undefined>(undefined);

  useEffect(() => {
    if (error) {
      if (constants.debug.console) { console.log("error: ", JSON.parse(JSON.stringify(error))); }
      // if (constants.debug.console) console.log("error type is: ", typeof error, error);

      if (errorType === "form") {
        const arr: Array<string> = [];
        // @ts-ignore
        Object.keys(error).forEach((k) => arr.push(error[k].message));
        if (arr.length > 0) {
          setMessage(HTML2React(arr.join(separator)));
        } else {
          setMessage(undefined);
        }
      } else {
        // ApolloError nesnesi mi
        const msg = getApolloError(error as ApolloError);
        // tanımlı bir şey yoksa, olduğu gibi set ediyoruz.
        setMessage(msg || error);
      }
    } else {
      setMessage(undefined);
    }
  }, [error, errorType, separator]);

  if (!message) {
    return null;
  }

  return (
    <Alert color="warning">
      {message}
    </Alert>
  );
}

export { ValidationSummary as default, getApolloError };
