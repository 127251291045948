/* eslint-disable arrow-body-style */
import { actionCreator, IContextActions } from "@app/contexts/types";
import h from "@app/helpers/index";
import type { Dispatch } from "react";
import type { G_ListingStatus, G_ListingTypes } from "@app/gql/graphql-types";
import type { IListingData, ListingStepItem } from "./types";
// import contextHelper from "./helpers";

// ---
// Actions (declarations)
// ----------------------------------------------------------------------------
const initializeListingStepsAction = actionCreator<{ items: Array<ListingStepItem> }>("LISTING_STEPS_INIT");
const clearListingStepsAction = actionCreator<{}>("LISTING_STEPS_CLEAR");
const setListingStepDataAction = actionCreator<{ order: number, values: any, isValid: boolean }>("LISTING_STEP_SET_DATA");
const setListingStatusAction = actionCreator<{ value: G_ListingStatus }>("LISTING_STATUS");
const setListingTypeAction = actionCreator<{ value: G_ListingTypes }>("LISTING_TYPE");
const setListingIdAction = actionCreator<{ id: string | undefined, slug: string | undefined }>("LISTING_ID_SLUG");

const actionTypes = {
  initializeListingStepsAction,
  clearListingStepsAction,
  setListingStepDataAction,
  setListingStatusAction,
  setListingTypeAction,
  setListingIdAction,
};

// ---
// Action methods
// ----------------------------------------------------------------------------
const initializeListingSteps = (dispatch: Dispatch<any>) => (items: Array<ListingStepItem>) => {
  dispatch(initializeListingStepsAction({ items }));
  // const steps = items.map((x): ListingStepItem => {
  //   const stepData = h.ss.get(`listing-create-step-${x.order}`);
  //   return {
  //     ...x,
  //     data: stepData,
  //     completed: Boolean(stepData),
  //   };
  // });
  // dispatch(initializeListingStepsAction({ items: steps }));

  // const listingType = h.ss.get("listing-create-type");
  // if (listingType) {
  //   dispatch(setListingTypeAction({ value: listingType }));
  // }
};

const clearListingSteps = (dispatch: Dispatch<any>) => () => {
  // clear storage
  // h.ss.remove("listing-create-type");
  // h.ss.remove("listing-id");
  // h.ss
  //   .keys({ key: "listing-create-step-", method: "startsWith" })
  //   .forEach((k) => h.ss.remove(k));

  dispatch(clearListingStepsAction({}));
};

const setListingStepData = (dispatch: Dispatch<any>) => (order: number, values: any, isValid: boolean) => {
  // h.ss.set(`listing-create-step-${order}`, values);

  dispatch(setListingStepDataAction({ order, values, isValid }));
};

const setListingStatus = (dispatch: Dispatch<any>) => (value: G_ListingStatus) => {
  // h.ss.set("listing-create-status", value);
  dispatch(setListingStatusAction({ value }));
};

const setListingType = (dispatch: Dispatch<any>) => (value: G_ListingTypes) => {
  // h.ss.set("listing-create-type", value);
  dispatch(setListingTypeAction({ value }));
};

const setListingId = (dispatch: Dispatch<any>) => (id: string | undefined, slug: string | undefined) => {
  // h.ss.set("listing-id", value);
  dispatch(setListingIdAction({ id, slug }));
};

const filterActiveSteps = () => (listingData: IListingData): ListingStepItem[] => {
  const isEditMode = listingData.listingId !== undefined;

  const arr = isEditMode ? listingData.items.filter((x) => x.order !== 10 && x.order !== 80) : listingData.items;
  switch (listingData.listingType) {
    case "APPOINTMENT":
      return arr;
    case "CLASS":
      return arr.filter((x) => !x.onlyAppointment);
    default:
      return [];
  }
};

// ---
// Actions definition
// ----------------------------------------------------------------------------
interface IActions extends IContextActions {
  initializeListingSteps(items: Array<ListingStepItem>): void;
  clearListingSteps(): void;
  setListingStepData(order: number, values: any, isValid: boolean): void;
  setListingStatus(value: G_ListingStatus): void;
  setListingType(value: G_ListingTypes): void;
  setListingId(id: string | undefined, slug: string | undefined): void;
  filterActiveSteps(listingData: IListingData): ListingStepItem[];
}

const actions = {
  initializeListingSteps,
  clearListingSteps,
  setListingStepData,
  setListingStatus,
  setListingType,
  setListingId,
  filterActiveSteps,
};

// ---
// exports
// ----------------------------------------------------------------------------
export type { IActions };
export { actions, actionTypes };
