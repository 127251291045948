import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@app/components/button/button";

import "./assets/http-error.scss";

interface Props {
  code: string;
  title: string;
  children: React.ReactNode;
}

function HttpError(props: Props) {
  const { code, title, children } = props;

  const history = useHistory();

  function onBackHandler() {
    history.push("/");
  }

  return (
    <div className="http-error container">
      <div className="code">{code}</div>
      <h4>{title}</h4>
      <div className="content">{children}</div>
      <Button
        type="button"
        color="dark"
        value="Back to Home"
        className="back-button"
        onClick={onBackHandler}
      />
    </div>
  );
}

export { HttpError as default };
