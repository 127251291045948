import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";

import { Link } from "react-router-dom";

import imagePlaceholder from "@app/assets/images/img-placeholder.jpg";
import Avatar from "@app/components/avatar/avatar";
import h from "@app/helpers";
import clsx from "clsx";

interface MapMarkerProps {
  id: string;
  slug: string;
  type: string;
  isAvailableOnline: boolean;
  isAvailableInPerson: boolean;
  coverPhoto: string | null | undefined;
  text: string;
  avatarUrl: string | null | undefined;
  address: string;
  price: number;
  firstName?: string;
  lastName?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  coordinates: {
    lng: number;
    lat: number;
  };
  show?: boolean;
}

function MapMarker(props: MapMarkerProps) {
  const { t } = useTranslation(["homepage"]);

  const {
    id, slug, type, isAvailableOnline, isAvailableInPerson,
    coverPhoto, text, avatarUrl, address, price, firstName,
    lastName, show,
  } = props;

  if (!show) {
    return null;
  }

  return (
    <div id={`_${id}`} className={clsx("marker-detail")}>
      <Link to={`/services/${slug}`}>
        <div className="item">
          <div className="thumbnail aspect-ratio">
            <Avatar uri={h.fetch.getThumbnailUrl(avatarUrl || "", 96, 96, "c_thumb", "jpg")} size={40} altText={`${firstName} ${lastName}`} initial={firstName} />
            <img src={coverPhoto || imagePlaceholder} alt={text} className="cover" />
          </div>
          <div className="infos">
            <span className="type">
              {type === "APPOINTMENT" && (
                <>
                  {isAvailableOnline && isAvailableInPerson && (t("g__txt_virtual_or_inperson_appointment"))}
                  {isAvailableOnline && !isAvailableInPerson && (t("g__txt_virtual_appointment"))}
                  {!isAvailableOnline && isAvailableInPerson && (t("g__txt_inperson_appointment"))}
                </>
              )}
              {type === "CLASS" && (
                <>
                  {isAvailableOnline && isAvailableInPerson && (t("g__txt_virtual_or_inperson_class"))}
                  {isAvailableOnline && !isAvailableInPerson && (t("g__txt_virtual_class"))}
                  {!isAvailableOnline && isAvailableInPerson && (t("g__txt_inperson_class"))}
                </>
              )}
            </span>
            <h5 className="title" title={text}>{text}</h5>
            <footer>
              {price > 0 && (
                <div className="price">
                  <strong>
                    {t("browse__listing_items_text_from")}
                    {" "}
                    {price || 0}
                    {" "}
                    &#8364;
                  </strong>
                  <small>
                    {" "}
                    /
                    {" "}
                    {type === "APPOINTMENT" && (t("g__txt_session"))}
                    {type === "CLASS" && (t("g__txt_person"))}
                  </small>
                </div>
              )}
            </footer>
          </div>
        </div>
      </Link>
    </div>
  );
}

export { MapMarker as default };
export type { MapMarkerProps };
