import React from "react";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";

import HttpError from "./http-error";

function HttpError404() {
  const { t } = useTranslation(["homepage"]);

  return (
    <HttpError code="404" title={t("comp__http_error__404__title")}>
      {t("comp__http_error__404__text")}
    </HttpError>
  );
}

export { HttpError404 as default };
