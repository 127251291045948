function getPrice(hit: any) {
  const priceResult = hit.items.edges.reduce(
    (acc1: number, cur1: any) => {
      if (cur1.node.availableSeats < 1) { return acc1; }

      const low1 = cur1.node.options.edges.reduce(
        (acc2: number, cur2: any) => {
          const low2 = parseFloat(cur2.node.price);

          return (acc2 === undefined || low2 < acc2) ? low2 : acc2;
        },
        undefined,
      );

      return (acc1 === undefined || low1 < acc1) ? low1 : acc1;
    },
    undefined,
  );
  return priceResult;
}

function isAvailableOnline(hit: any) {
  const isAvailableOnlineResult = hit.items.edges.some(
    (x: any) => x.node.isAvailableOnline,
  ) ?? false;
  return isAvailableOnlineResult;
}

function isAvailableInPerson(hit: any) {
  const isAvailableInPersonResult = hit.items.edges.some(
    (x: any) => x.node.locations.edges.length > 0,
  ) ?? false;
  return isAvailableInPersonResult;
}

const hitHelper = {
  getPrice,
  isAvailableOnline,
  isAvailableInPerson,
};

export {
  hitHelper as default,
  getPrice,
  isAvailableOnline,
  isAvailableInPerson,
};
