/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";

import { useDropzone } from "react-dropzone";
import request from "superagent";
import constants from "@app/constants";
import type { UploadInfo } from "../types";
import ListingPhotoThumbnailBox from "./listing-photo-thumbnail-box";

import "./photo-uploader.scss";

/*
type UploadEvent = {
  id: number;
  fileName: string;
  progress?: request.ProgressEvent;
  response?: request.Response;
};
*/

function getThumbnailUrl(url: string, w: number, h: number, crop?: string) {
  /*
    Original url
    > https://res.cloudinary.com/mycurayacom/image/upload/v1611791199/listings/photo/sgttjd8pzvtyurrquciq.jpg

    Resized
    > https://res.cloudinary.com/demo/image/upload/w_70,h_70,c_limit/cashew_chicken.jpg
  */
  const { resourceUrlPrefix } = constants.cloudinary;
  const suffix = url.substring(resourceUrlPrefix.length, url.length);
  return `${resourceUrlPrefix}${w ? `w_${w}` : ""}${h ? `,h_${h}` : ""}${crop ? `,${crop}` : ",c_limit"}/${suffix}`;
}

type ProgressInfo = {
  localUrl: string;
  percent: number | undefined;
};

interface Props {
  maxFiles: number;
  containerClass?: string;
  uploadedFiles: UploadInfo[];
  tags: string[];
  thumbnailSize: {
    width: number;
    height: number;
    crop?: string;
  },
  onDelete?: (index: number) => void;
  onAdd?: (e: UploadInfo) => void;
}

function PhotoUploader(props: Props) {
  const {
    maxFiles, containerClass, uploadedFiles, tags, thumbnailSize, onDelete, onAdd,
  } = props;

  const { t } = useTranslation(["homepage"]);

  // url, localUrl, percentage
  const [localUrls, setLocalUrls] = useState<[string, string, number][]>([]);
  const [progressInfo, setProgressInfo] = useState<ProgressInfo[]>([]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // if (constants.debug.console) console.log("localUrls", JSON.stringify(localUrls, null, 2));
    return () => localUrls.forEach((x) => URL.revokeObjectURL(x[1]));
  }, [localUrls]);

  function isUploading() {
    return !!progressInfo.find((x) => x.percent !== undefined);
  }

  function onProgress(id: number, fileName: string, progress: request.ProgressEvent, localUrl: string) {
    if (constants.debug.console) { console.log("...uploading...", id, fileName, progress); }

    const item = progressInfo.find((x) => x.localUrl === localUrl);
    if (item) {
      item.percent = progress.percent;
    } else {
      progressInfo.push({
        localUrl: localUrl,
        percent: progress.percent,
      });
    }

    setProgressInfo([...progressInfo]);
  }

  function onUploaded(id: number, fileName: string, response: request.Response, localUrl: string) {
    if (constants.debug.console) { console.log("FNSH", id, fileName, response); }

    const activeProgresses = progressInfo.filter((x) => x.localUrl !== localUrl);
    setProgressInfo([...activeProgresses]);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { secure_url, asset_id } = response.body;

    setLocalUrls([
      ...localUrls,
      [secure_url, localUrl, 100],
    ]);

    if (onAdd) {
      const { width, height, crop } = thumbnailSize;
      onAdd({
        trackId: undefined,
        trackStatus: "NEW",
        url: secure_url,
        thumbnailUrl: getThumbnailUrl(secure_url, width, height, crop),
        assetId: asset_id,
        order: id,
      });
    }
  }

  function beginUpload(file: File, index: number) {
    const { uploadUrl, uploadPresetPhoto } = constants.cloudinary;

    // @ts-ignore
    const { path: filePath, name: fileName, preview: localUrl } = file;

    if (constants.debug.console) {
      console.log("file info:", file, {
        filePath, uploadUrl, uploadPresetPhoto, tags,
      });
    }

    request
      .post(uploadUrl)
      .field("upload_preset", uploadPresetPhoto)
      .attach("file", file, fileName)
      .field("tags", tags.join(","))
      .field("context", "alt=My image❘caption=listing image")
      .on("progress", (progress: request.ProgressEvent) => onProgress(index, fileName, progress, localUrl))
      .end((error: Error, response: request.Response) => onUploaded(index, fileName, response, localUrl));
  }

  const [existingPhotos, setExistingPhotos] = useState<UploadInfo[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    },
    maxFiles: maxFiles,
    onDrop: (acceptedFiles: File[]) => {
      // if (constants.debug.console) console.log(acceptedFiles);

      const arr = acceptedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }));

      setFiles([...files, ...arr]);

      acceptedFiles.forEach((file, i: number) => beginUpload(file, i));
    },
  });

  useEffect(() => {
    if (isUploading()) {
      // if (constants.debug.console) console.log(">>>><<>>>< busy setExistingPhotos", progressInfo);
      return;
    }

    // if (constants.debug.console) console.log("uploadedFiles:", uploadedFiles);
    const arr = uploadedFiles.map((x): UploadInfo => {
      const { width, height, crop } = thumbnailSize;
      // const localUrl = localUrls.find((l) => l[0] === x.url);
      return {
        trackId: undefined,
        trackStatus: "NEW",
        url: x.url,
        // thumbnailUrl: localUrl ? localUrl[1] : getThumbnailUrl(x.url, width, height, crop),
        thumbnailUrl: getThumbnailUrl(x.url, width, height, crop),
      };
    });

    setExistingPhotos(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailSize, uploadedFiles, progressInfo]);

  useEffect(() => {
    if (isUploading()) {
      // if (constants.debug.console) console.log(">>>><<>>>< busy setFiles", progressInfo);
      return;
    }

    setFiles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles, progressInfo]);

  function onDeleteHandler(index: number, isNew: boolean) {
    // if (constants.debug.console) console.log(`Photo remove:${index} isNew:${isNew}`);
    if (isNew) {
      const arr = files.filter((x, i) => i !== index);
      setFiles([...arr]);
    } else {
      const arr = existingPhotos.filter((x, i) => i !== index);
      setExistingPhotos([...arr]);
    }

    if (onDelete) {
      onDelete(index + (isNew ? existingPhotos.length : 0));
    }
  }

  function renderThumbnail(id: string, previewUrl: string, index: number, isNew: boolean) {
    // const l = localUrls.find((x) => x[1] === previewUrl);
    const l = progressInfo.find((x) => x.localUrl === previewUrl);

    return (
      <ListingPhotoThumbnailBox
        key={id}
        thumbnailUrl={previewUrl}
        onDelete={() => onDeleteHandler(index, isNew)}
        className={containerClass}
        isNew={isNew}
        // progress={l ? l[2] : undefined}
        progress={l?.percent}
      />
    );
  }

  // @ts-ignore
  const thumbs = files.map((file, i) => renderThumbnail(file.name, file.preview, i, true));

  return (
    <>
      {/* old files */}
      {existingPhotos.map((file, i) => renderThumbnail(file.url, file.thumbnailUrl, i, false))}

      {/* new files */}
      {thumbs}

      {/* add file (placeholder & button) */}
      {existingPhotos.length + files.length < maxFiles && (
        <div className={containerClass}>
          <div {...getRootProps({ className: "media-container-item aspect-ratio dropzone" })}>
            <input {...getInputProps()} />
            <a href="" onClick={(e) => e.preventDefault()}>
              <div className="media-add">
                {isDragActive
                  ? (<span>Drop the files here ...</span>)
                  : (
                    <>
                      <div className="add-button">
                        <i className="icon-plus-circle" />
                        <span>{t("d__service_create__step_photos__btn_add")}</span>
                      </div>
                      <div className="media-add-description">
                        {t("d__service_create__step_photos__btn_add_text")}
                        <br />
                        {t("d__service_create__step_photos__btn_add_allowed")}
                      </div>
                    </>
                  )}
              </div>
            </a>
            {/* {isUploading() && <div className="media-uploading">uploading</div>} */}
          </div>
        </div>
      )}
    </>
  );
}

export type { UploadInfo };
export { PhotoUploader as default };
