import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";
import SwiperCore, {
  Navigation, Pagination, A11y,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "@app/components/carousel/carousel.scss";
import "../../assets/styles/filter-categories-carousel.scss";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

type CarouselItem = {
  id: string;
  value: string;
  title: string;
  img?: string;
  to: string;
  count?: number;
  isRefined?: boolean;
};

const data: CarouselItem[] = [
  {
    id: "cat1",
    value: "meditation",
    title: "homepage:home__explore_category_1",
    img: "https://res.cloudinary.com/mycurayacom/image/upload/v1619788796/categories/medidation.png",
    to: "/browse",
  },
  {
    id: "cat2",
    value: "yoga",
    title: "homepage:home__explore_category_2",
    img: "https://res.cloudinary.com/mycurayacom/image/upload/v1619788798/categories/yoga.png",
    to: "/browse",
  },
  {
    id: "cat3",
    value: "hypnosis",
    title: "homepage:home__explore_category_3",
    img: "https://res.cloudinary.com/mycurayacom/image/upload/v1619788796/categories/hypnosis.png",
    to: "/browse",
  },
  {
    id: "cat4",
    value: "holistic-medicine",
    title: "homepage:home__explore_category_4",
    img: "https://res.cloudinary.com/mycurayacom/image/upload/v1619788796/categories/holistic-medicine.png",
    to: "/browse",
  },
  {
    id: "cat5",
    value: "coaching-therapy",
    title: "homepage:home__explore_category_5",
    img: "https://res.cloudinary.com/mycurayacom/image/upload/v1619788796/categories/nutrition.png",
    to: "/browse",
  },
  {
    id: "cat6",
    value: "nutrition",
    title: "homepage:home__explore_category_6",
    img: "https://res.cloudinary.com/mycurayacom/image/upload/v1619788796/categories/coaching-therapy.png",
    to: "#",
  },
  {
    id: "cat7",
    value: "bodywork",
    title: "homepage:home__explore_category_7",
    img: "https://res.cloudinary.com/mycurayacom/image/upload/v1619788799/categories/bodywork.png",
    to: "/browse",
  },
  {
    id: "cat8",
    value: "energy",
    title: "homepage:home__explore_category_8",
    img: "https://res.cloudinary.com/mycurayacom/image/upload/v1619788797/categories/energy.png",
    to: "/browse",
  },
  {
    id: "cat9",
    value: "readings",
    title: "homepage:home__explore_category_9",
    img: "https://res.cloudinary.com/mycurayacom/image/upload/v1619788798/categories/readings.png",
    to: "/browse",
  },
];

interface FilterCategoryItem {
  label: string;
  value: string;
  count: number;
  isRefined: boolean;
  items: FilterCategoryItem[] | null;
}

interface Props {
  items: FilterCategoryItem[];
  refine?: any;
  createURL?: any;
}

function FilterCategoriesCarousel(props: Props) {
  const { items, refine, createURL } = props;
  const { t } = useTranslation(["homepage"]);

  const [sliderItems, setSliderItems] = useState<CarouselItem[]>([]);

  useEffect(() => {
    // debug amacli
    if (items.length === 0) {
      setSliderItems(data);
      return;
    }

    // const filteredData = data.filter((x) => Boolean(items.find((y) => y.value === x.key)));

    const filteredData: CarouselItem[] = [];

    items.forEach((p) => {
      const originalData = data.find((q) => p.value === q.value);
      if (originalData) {
        filteredData.push({
          ...originalData,
          count: p.count,
          isRefined: p.isRefined,
        });
      }
    });

    setSliderItems(filteredData.sort((p, c) => (p.id > c.id ? 1 : 0)));
  }, [items]);

  return (
    <>
      <div className="categories-carousel">
        <div className="categories-button-next" />
        <div className="categories-button-prev" />
        <Swiper
          slidesPerView="auto"
          // centeredSlides
          // spaceBetween={4}
          freeMode
          // loop
          // cssMode
          navigation={{ nextEl: ".categories-button-next", prevEl: ".categories-button-prev" }}
        // pagination={{ clickable: true, dynamicBullets: true }}
        // breakpoints={{
        //   0: {
        //     slidesPerView: 2.3,
        //     spaceBetween: 8,
        //   },
        //   576: {
        //     slidesPerView: 3,
        //     spaceBetween: 8,
        //   },
        //   768: {
        //     slidesPerView: 4,
        //     spaceBetween: 8,
        //   },
        //   992: {
        //     slidesPerView: 5,
        //     spaceBetween: 8,
        //   },
        //   1080: {
        //     slidesPerView: 6,
        //     spaceBetween: 8,
        //   },
        //   1200: {
        //     slidesPerView: 7,
        //     spaceBetween: 8,
        //   },
        // }}
        >
          {sliderItems.map((x) => (
            <SwiperSlide key={x.id}>
              <div className="item">
                <input
                  type="checkbox"
                  className="btn-check"
                  id={x.id}
                  autoComplete="off"
                  onClick={() => refine && refine(x.value)}
                />

                <label className="category" htmlFor={x.id}>
                  <img src={x.img} alt={t(x.title)} />
                  <span>
                    <strong className="title">{t(x.title)}</strong>
                    {x.count && (
                      <span className="count badge bg-primary rounded-pill">{x.count}</span>
                    )}
                  </span>
                </label>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export { FilterCategoriesCarousel as default };
