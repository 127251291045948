import type { IAuth, IState } from "./types";

// ---
// State defaults
// ----------------------------------------------------------------------------
const authDefaults: IAuth = {
  accessToken: "",
  // refreshToken: "",
  expiresIn: 0,
  tokenType: undefined,
  profileId: "",
  slug: "",
  role: "CUSTOMER",
  view: "CUSTOMER",
  email: "",
  firstName: "",
  lastName: "",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  profilePictureUri: undefined,
  defaultCalendar: undefined,
  serviceFeeRatio: 0.15,
};

const contextDefaults: IState = {
  Auth: authDefaults,
  LoggedIn: false,
  GetStartedInfo: undefined,
  isNewExpert: false,
  getStartedPopupViewed: false,
};

export { contextDefaults, authDefaults };
