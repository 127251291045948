import React, { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
// @ts-ignore
import { ContextDevTool } from "react-context-devtool";

const createDataContext = (reducer: any, actions: any, defaultValue: any, name: string) => {
  const Context = React.createContext<any>(undefined);

  // eslint-disable-next-line react/prop-types
  const Provider: React.FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);
    const boundActions: Record<string, unknown> = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in actions) {
      if (Object.prototype.hasOwnProperty.call(actions, key)) {
        boundActions[key] = actions[key](dispatch);
      }
    }

    // TODO: AUTO MODE'a geçilecek.
    /*
      Biz burada MANUAL MODE kullandık. Ancak 2.0 versiyon ile beraber AUTO MODE
      seçeneği geldi. Bir ara bunu konfigüre edelim.
      https://github.com/deeppatel234/react-context-devtool#readme
    */
    return (
      <Context.Provider value={{ state: state, actions: { ...boundActions } }}>
        <ContextDevTool context={Context} id={uuidv4()} displayName={name} />
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
};

export { createDataContext };
