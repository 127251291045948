/* eslint-disable arrow-body-style */
import { actionCreator, IContextActions } from "@app/contexts/types";
import type { Dispatch } from "react";
import type { IProfileInfo } from "./types";

// ---
// Actions (declarations)
// ----------------------------------------------------------------------------
const loadProfileInfoAction = actionCreator<{ profileInfo: IProfileInfo }>("LOAD_PROFILE_INFO");

const actionTypes = {
  loadProfileInfoAction,
};

// ---
// Action methods
// ----------------------------------------------------------------------------
const loadProfileInfo = (dispatch: Dispatch<any>) => (profileInfo: IProfileInfo) => {
  console.log("loadProfileInfo:", profileInfo);
  dispatch(loadProfileInfoAction({ profileInfo }));
};

// ---
// Actions definition
// ----------------------------------------------------------------------------
interface IActions extends IContextActions {
  loadProfileInfo(profileInfo: IProfileInfo): void;
}

const actions = {
  loadProfileInfo,
};

// ---
// exports
// ----------------------------------------------------------------------------
export type { IActions };
export { actions, actionTypes };
