type Action<TPayload> = {
  type: string;
  payload: TPayload;
};

interface ActionCreator<P> {
  type: string;
  (payload: P): Action<P>;
}

function actionCreator<P>(type: string): ActionCreator<P> {
  return Object.assign((payload: P) => { return { type, payload }; }, { type });
}

function isType<P>(action: Action<any>, creator: ActionCreator<P>): action is Action<P> {
  return action.type === creator.type;
}

interface IContextState { }

interface IContextActions { }

interface IContext {
  state: IContextState;
  actions: IContextActions;
}

export type {
  Action, ActionCreator, IContextState, IContextActions, IContext,
};

export { actionCreator, isType };
