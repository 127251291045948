import React from "react";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";

import { Link } from "react-router-dom";

import imagePlaceholder from "@app/assets/images/img-placeholder.jpg";
import Avatar from "@app/components/avatar/avatar";
import h from "@app/helpers";

interface ListingItemCardProps {
  id: string;
  slug: string;
  type: string;
  isAvailableOnline: boolean;
  isAvailableInPerson: boolean;
  coverPhoto: string | null | undefined;
  text: string;
  avatarUrl: string | null | undefined;
  address: string;
  price: number;
  firstName?: string;
  lastName?: string;
  category?: string;
  averageRating?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  coordinates: {
    lng: number;
    lat: number;
  };
}

function ListingItemCard(props: ListingItemCardProps) {
  const { t } = useTranslation(["homepage"]);

  const {
    id, slug, type,
    coverPhoto, text, avatarUrl, address, price, firstName,
    lastName, category, averageRating,
  } = props;

  function toggleHover(listingId: string, active: boolean) {
    const elements = document.getElementsByClassName(listingId);
    if (elements.length === 0) {
      return;
    }

    if (active) {
      elements[0].classList.add("active");
    } else {
      elements[0].classList.remove("active");
    }
  }

  return (
    <>
      <div id={`_${id}`} className="listing-item">
        <Link
          to={`/services/${slug}`}
          onMouseEnter={() => toggleHover(id, true)}
          onMouseLeave={() => toggleHover(id, false)}
        >
          {/* <i className="favorite" /> */}
          <div className="thumbnail aspect-ratio">
            {/* <i className="favorite" /> */}
            <Avatar
              uri={h.fetch.getThumbnailUrl(avatarUrl || "", 96, 96, "c_thumb", "jpg")}
              size={40}
              altText={`${firstName} ${lastName}`}
              initial={firstName}
            />
            {type === "CLASS" && (
              <span className="type">
                <i className="icon-users" />
                <b>{t("browse__listing_items_text_class")}</b>
              </span>
            )}
            {/* <span className="type-badges">
              {isAvailableOnline && (<i className="icon-video" title="Virtual" />)}
              {isAvailableInPerson && (<i className="icon-map-pin" title={`In-person`} />)}
            </span> */}
            <img src={coverPhoto || imagePlaceholder} alt={text} className="cover" />
          </div>

          <div className="infos">

            <div className="type">

              {/* type === "APPOINTMENT" && (
                <>
                  {isAvailableOnline && isAvailableInPerson && (t("g__txt_virtual_or_inperson_appointment"))}
                  {isAvailableOnline && !isAvailableInPerson && (t("g__txt_virtual_appointment"))}
                  {!isAvailableOnline && isAvailableInPerson && (t("g__txt_inperson_appointment"))}
                </>
              ) */}
              {/* type === "CLASS" && (
                <>
                  {isAvailableOnline && isAvailableInPerson && (t("g__txt_virtual_or_inperson_class"))}
                  {isAvailableOnline && !isAvailableInPerson && (t("g__txt_virtual_class"))}
                  {!isAvailableOnline && isAvailableInPerson && (t("g__txt_inperson_class"))}
                </>
              ) */}

              {category && (<span>{category}</span>)}
            </div>
            <h4 className="title">{text}</h4>
            <div className="author">
              <span className="name">
                {type === "APPOINTMENT" ? t("browse__listing_items_type_text_treatment") : t("browse__listing_items_type_text_hosted")}
                {" "}
                {`${firstName} ${lastName}`}
              </span>

              {address && (
                <>
                  <span className="dot-divider">•</span>
                  <span className="location">{address}</span>
                </>
              )}

            </div>
            {(averageRating && averageRating != 0) && (
              <>
                <div className="rates">
                  <i className="icon-star-fill" />
                  <strong>
                    {averageRating}
                  </strong>
                </div>
              </>
            )}
            <div className="price">
              {price > 0 ? (
                <>
                  <strong>
                    {t("browse__listing_items_text_from")}
                    {" "}
                    {price || 0}
                    {" "}
                    &#8364;
                  </strong>
                  <small>
                    /
                    {" "}
                    {type === "APPOINTMENT" && (t("g__txt_session"))}
                    {type === "CLASS" && (t("g__txt_person"))}
                  </small>
                </>
              ) : (
                <>
                  No upcoming session available
                </>
              )}
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}

export { ListingItemCard as default };
export type { ListingItemCardProps };
