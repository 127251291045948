import type { IAuth } from "@app/contexts/auth/types";
import storage from "@app/helpers/local-storage";

interface IJwt {
  user:string;
  // amr: string;
  // nameid: string;
  // slug: string;
  // email: string;
  // companyId: string;
  // companyName: string;
  // fullName: string;
  // sub: string;
  // jti: string;
  // iat: string;
  // nbf: number;
  exp: number;
  // iss: string;
  // aud: string;
  origIat: number;
}

const parseJwt = (token: string): IJwt => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join(""),
  );

  return JSON.parse(jsonPayload);
};

const tokenIsExpired = (token: string): boolean => {
  const currentTime: number = new Date().getTime() / 1000;
  const jwt: IJwt = parseJwt(token);
  return currentTime > jwt.exp;
};

/*
const getActiveToken = (checkExpiration = false): string | null => {
  const token: string = storage.get(storage.constants.ACCESS_TOKEN);
  if (checkExpiration && token && tokenIsExpired(token)) {
    return null;
  }
  return token;
};
*/

function getToken() {
  const obj = storage.get(storage.constants.AUTH_OBJ);
  if (obj) {
    const authObj: IAuth = JSON.parse(obj);
    if (authObj) {
      return authObj.accessToken;
    }
  }

  return undefined;
}

const jwt = {
  parse: parseJwt,
  isExpired: tokenIsExpired,
  // current: getActiveToken,
  getToken: getToken,
};

export { jwt as default };
