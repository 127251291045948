import React, { useEffect, useState } from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import { useTranslation } from "react-i18next";
import "@app/i18n/config";
import clsx from "clsx";
import h from "@app/helpers";
import { G_LanguageNode, useAllLanguagesQuery } from "@app/gql/graphql-types";
import { getApolloError } from "@app/components/validation-summary/validation-summary";
import FilterDropdown from "../filter-dropdown";

interface Props {
  items: any[];
  currentRefinement: string[];
  refine: (value: string[]) => void;
  searchable?: boolean;
  // isFromSearch: boolean;
  //  searchForItems: function,
  //  createURL: function,
}

type PickedLanguage = Pick<G_LanguageNode, "id" | "name" | "isoCode"> & { count?: number };

function RefinementList(props: Props) {
  const {
    currentRefinement, refine, items, searchable,
  } = props;

  const { t } = useTranslation(["homepage"]);
  const [state, setState] = useState<string[]>(currentRefinement);
  const [title, setTitle] = useState<string>("");

  const [allData, setAllData] = useState<PickedLanguage[]>();
  const [filteredData, setFilteredData] = useState<PickedLanguage[]>();
  const { loading, data, error } = useAllLanguagesQuery();

  function getTitle(values: string[]) {
    if (filteredData && values.length > 0) {
      const d = filteredData.find((x) => x.isoCode === values[0]);
      if (d) {
        const langName = t(`language_${d.name}`);
        return (values.length > 1 ? `${langName} +${values.length - 1}` : langName);
      }
    }

    return t("browse__filters__language");
  }

  function getFilteredData(arr: PickedLanguage[]) {
    const nonEmptyItems: PickedLanguage[] = [];

    arr.forEach((x) => {
      const count = items.find((item: any) => item.label === x.isoCode)?.count ?? 0;
      if (count > 0) {
        // console.log({ x, count });
        nonEmptyItems.push(x);
      }
    });

    return nonEmptyItems;
  }

  useEffect(() => {
    refine(state);
    setTitle(getTitle(state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setTitle(getTitle(state));

    const nonEmptyItems = getFilteredData(allData ?? []);
    setFilteredData(nonEmptyItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, t]);

  useEffect(() => {
    const c = JSON.stringify(currentRefinement);
    const s = JSON.stringify(state);
    if (c !== s) {
      setState(currentRefinement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefinement]);

  useEffect(() => {
    if (data) {
      // console.log({ data, items });

      const arr = h.gql.toTyped<PickedLanguage>(data.languagesAll);
      setAllData(arr);

      const nonEmptyItems = getFilteredData(arr);
      setFilteredData(nonEmptyItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function handleRefine(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setState([...state, e.target.value]);
    } else {
      const filtered = state.filter((x) => x !== e.target.value);
      setState([...filtered]);
    }
  }

  return (
    <FilterDropdown
      id="filterLanguage"
      className={clsx("filter-language", { refined: state.length > 0 })}
      title={title}
    >
      {loading && (<>loading...</>)}

      {error && (<>{getApolloError(error)}</>)}

      {filteredData && (
        <>
          {searchable && (
            <li className="filter-text">
              <input
                className="form-control"
                onChange={(e) => {
                  // console.log(e.target.value);
                  const arr = allData!.filter((x) => x.name.toLowerCase().includes(e.target.value.toLowerCase()));
                  setFilteredData(arr);
                }}
                type="text"
              />
            </li>
          )}

          {filteredData.map((x, i) => {
            const { id, isoCode, name } = x;
            const isChecked = state.includes(isoCode);
            const langName = t(`language_${name}`);
            // const count = items.find((item: any) => item.label === isoCode)?.count;

            return (
              <li key={id} className="dropdown-item">
                <div className="form-check ">
                  <input
                    className="form-check-input form-check-input-lg"
                    checked={isChecked}
                    id={isoCode}
                    name={`languages[${i}]`}
                    placeholder={langName}
                    onChange={handleRefine}
                    type="checkbox"
                    value={isoCode}
                  />
                  <label className="form-check-label" htmlFor={isoCode}>
                    {langName}
                    {/* {count && (<strong>{` (${count})`}</strong>)} */}
                  </label>
                </div>
              </li>
            );
          })}
        </>
      )}
    </FilterDropdown>
  );
}

const CustomRefinementList = connectRefinementList(RefinementList);

function LanguageRefinement() {
  return (
    <CustomRefinementList limit={20} attribute="languages.edges.node.isoCode" searchable={false} />
  );
}

export { LanguageRefinement };
