import type { IListingData, IState } from "./types";

// ---
// State defaults
// ----------------------------------------------------------------------------
const listingStepsDefaults: IListingData = {
  listingId: undefined,
  listingSlug: undefined,
  listingType: undefined,
  status: undefined,
  items: [],
};

const contextDefaults: IState = {
  listingData: listingStepsDefaults,
};

export { contextDefaults, listingStepsDefaults };
